import React, { useState, useEffect } from 'react';
import { FormGroup, ToggleButton } from '@mui/material';
import { Box } from '@mui/system';
import { useTheme } from "@mui/material/styles";

const CheckboxQuestion = ({index, answer, changeSelectedAnswer, updateAndUploadMapping}) => {
    const theme = useTheme();

    const [selected, setSelected] = useState(answer.selected);

    useEffect(() => {
        setSelected(answer.selected);
    }, [answer.selected])

    const onClickCheckbox = async (e) => {
        e.preventDefault();
        changeSelectedAnswer(answer.id);
        await updateAndUploadMapping();
    }

    return (
        <Box aria-label="checkbox-question-wrapper">
            <FormGroup
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexWrap: "wrap",
                }}
            >
                <ToggleButton 
                    key={index}
                    selected={selected}
                    onClick={async (e) => await onClickCheckbox(e)}
                    onTouchEnd={async (e) => await onClickCheckbox(e)}
                    sx={{
                        margin: "0.5rem",
                        backgroundColor: theme.palette.caralystRed.dark,
                        width: '40vw',
                        minWidth: "18rem",
                        maxWidth: "25rem",
                        color: "white",
                        transition: "background-color 0.15s ease-in-out",
                        textTransform: "none",
                        fontSize: "1.2em",
                        '&:hover': {
                            backgroundColor: theme.palette.caralystRed.main,
                            color: "white",
                        },
                        '&.Mui-selected': {
                            backgroundColor: theme.palette.caralystRed.main,
                            color: "white",
                        },
                        '&.Mui-selected:hover': {
                            backgroundColor: theme.palette.caralystRed.main,
                            color: "white",
                        }
                    }}
                >
                    {answer.text}
                </ToggleButton>
            </FormGroup>
        </Box>
    );
}

export default CheckboxQuestion;