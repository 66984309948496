import React from 'react';
import { Box } from '@mui/system';
import NavbarAutoComplete from '../Home/Navbar/NavbarAutoComplete';
import { Typography } from '@mui/material';

const PersonInfoQuestion = ({newLocation, setNewLocation, newPreferedInsurance, setNewPreferedInsurance, newPreferedSpecialty, setNewPreferedSpecialty}) => {

    return (
        <>
            <Box aria-label="person-info-wrapper"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: '2rem',
                }}
            >
                <Typography variant='h5' sx={{ marginBottom: '1rem', textAlign: 'center', padding: "0.5rem"}}>
                    Please provide some final information so we know which physicians fit within your preferences.
                </Typography>
                <NavbarAutoComplete variant='quiz' type='location' location={newLocation} setLocation={setNewLocation} />
                <NavbarAutoComplete variant='quiz' type='specialty' preferedSpecialty={newPreferedSpecialty} setSpecialtyPreference={setNewPreferedSpecialty} />
                <NavbarAutoComplete variant='quiz' type='insurance' preferedInsurance={newPreferedInsurance} setInsurancePreference={setNewPreferedInsurance} />
            </Box>
        </>
    );
}

export default PersonInfoQuestion;