import React from 'react';
import { Box } from '@mui/material';
import LoadingIcon from './LoadingIcon';
import { useSelector } from "react-redux";
import { selectLoggingIn, selectLoggingOut } from "../../redux/store";
const WholeScreenSkeleton = () => {
  
    return (
        <Box sx={{
            width: "100%", 
            height: "100%",
            zIndex: (theme) => theme.zIndex.drawer,
        }}>
            <Box sx={{
                position: "absolute",
                top: "45%",
                left: "50%",
                transform: "translate(-50%, -50%)"
            }}>
                <LoadingIcon />
            </Box>
        </Box>
    )
}

export default WholeScreenSkeleton;