import { SET_LOADING_METADATA, SET_MAP_LOADING, SET_POPUP_SURVEY_OPEN, SET_POPUP_SURVEY_TIMEOUT_SET, SET_SNACKBAR_MESSAGE, SET_SNACKBAR_OPEN } from "../constants/shared.constants";
import { SET_POPUP_SURVEY_STATUS } from "../constants/user.constants";

import ReactGA from 'react-ga4';
import { setCardIndex, setPersonCardOpen } from "./user.actions";
import { org } from "../constants/shared.constants";

export const setPopupSurveyOpen = (open) => ({
    type: SET_POPUP_SURVEY_OPEN,
    payload: open
})

export const setPopupSurveyStatus = (status) => ({
    type: SET_POPUP_SURVEY_STATUS,
    payload: status
})

export const setPopupSurveyTimeoutSet = (timeoutSet) => ({
    type: SET_POPUP_SURVEY_TIMEOUT_SET,
    payload: timeoutSet
})

export const setLoadingMetadata = (loading) => ({
    type: SET_LOADING_METADATA,
    payload: loading
})

export const setMapLoading = (loading) => ({
    type: SET_MAP_LOADING,
    payload: loading
})

export const setSnackbarOpen = (open) => ({
    type: SET_SNACKBAR_OPEN,
    payload: open
});

export const setSnackbarMessage = (message) => ({
    type: SET_SNACKBAR_MESSAGE,
    payload: message
});

/***
     * If the doc doesn't exist, do nothing. Otherwise open the person card.
     * 
     * @param {event} e
     * @param {int} ind - index of the doctor in the filteredDocs array
     */
export const handleOpen = (e, ind, popupSurveyStatus, popupSurveyTimeoutSet, filteredDocs) => async (dispatch) => {
    e.preventDefault();
    if(!filteredDocs[ind]) return;
    
    //Send GA event
    ReactGA.event({
        category: 'User',
        action: 'Clicked on a doctor card',
        label: `Doctor card for ${filteredDocs[ind].first_name} ${filteredDocs[ind].last_name}`,
    });

    //If the popup survey hasn't been shown yet, show it after X seconds
    if(popupSurveyStatus === "not started" && !popupSurveyTimeoutSet && org === "wustl"){
        await dispatch(setPopupSurveyTimeoutSet(true));
        setTimeout(async () => {
            await dispatch(setPopupSurveyOpen(true));
        }, 5000)
    }
    //Update the redux store
    await dispatch(setCardIndex(ind))
    await dispatch(setPersonCardOpen(true))
};

export const openPersonCardFromUrl = () => async (dispatch, getState) => {
    const {
        tempDocId,
        filteredDocs,
        popupSurveyStatus,
        popupSurveyTimeoutSet
    } = getState();

    if(!tempDocId || !filteredDocs) {
        return;
    }
    const curDocIndex = filteredDocs.findIndex(doc => doc.id === tempDocId);

    await dispatch(handleOpen({preventDefault: () => {}}, curDocIndex, popupSurveyStatus, popupSurveyTimeoutSet, filteredDocs));
}