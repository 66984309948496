import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import ReactGA from 'react-ga4';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import ShieldIcon from '@mui/icons-material/Shield';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EmailIcon from '@mui/icons-material/Email';
import ChatIcon from '@mui/icons-material/Chat';
import FolderIcon from '@mui/icons-material/Folder';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Box, Container, Button, Typography, useMediaQuery, useTheme, Dialog, DialogContent, DialogTitle, DialogActions, Chip, IconButton, Slide, Modal } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import IosShareIcon from '@mui/icons-material/IosShare';
import CloseIcon from '@mui/icons-material/Close';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ComputerIcon from '@mui/icons-material/Computer';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import HelpIcon from '@mui/icons-material/Help';

import { selectLoggedIn, selectPreferredInsurance, selectCardIndex, selectLatLong, selectDoctorDistance, selectExistsMatchingProviders, selectFilteredDocs, selectFavoriteDoctorIds, selectTempDocReasonsForMatching } from "../../redux/store";
import { setCardIndex, setPersonCardOpen } from '../../redux/actions/user.actions';
import { findDoctorDistace, getPfp, setDocMarkerFromPersonCard, setTempDocId, setTempDocLoc, setTempDocReasonsForMatching } from '../../redux/actions/doctors.actions';
import { useNavigate } from 'react-router-dom';
import { org } from '../../redux/constants/shared.constants.js';
import { styled } from '@mui/material/styles';
import { setSnackbarMessage, setSnackbarOpen } from '../../redux/actions/shared.actions';

const BulletPoint = ({ size, weight, marginLeftRight, marginTopBottom, onMobile }) => {
    const multiplier = !onMobile ? 1 : 1.35;
    return (
        <Typography
            className="spacer"
            sx={{
                fontSize: `${size}rem`,
                fontWeight: weight,
                margin: `${marginTopBottom * multiplier}rem ${marginLeftRight * multiplier}rem`,
            }}
        >
            &nbsp;
            &#x2022;
            &nbsp;
        </Typography>
    )
}

const PersonCard = ({ personInfo, onAddToFavorites, onRemoveFromFavorites, docLocation }) => {
    const onMobile = !useMediaQuery('(min-width:600px)');
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const showMoreRef = useRef(null);

    const loggedIn = useSelector(selectLoggedIn);
    const patientInsurance = useSelector(selectPreferredInsurance);
    const cardIndex = useSelector(selectCardIndex);
    const latLong = useSelector(selectLatLong)
    const doctorDistance = useSelector(selectDoctorDistance);
    const existsMatchingProviders = useSelector(selectExistsMatchingProviders);
    const filteredDocs = useSelector(selectFilteredDocs);
    const favoriteDoctorIds = useSelector(selectFavoriteDoctorIds);
    const tempDocReasonsForMatching = useSelector(selectTempDocReasonsForMatching);

    const [insuranceDialog, setInsuranceDialog] = useState(false);
    const [phoneClicked, setPhoneClicked] = useState(false);
    const [distance, setDistance] = useState(null);
    const [drivingTime, setDrivingTime] = useState(null);
    const [drivingUrl, setDrivingUrl] = useState(null);
    const [publicTransitTime, setPublicTransitTime] = useState(null);
    const [publicTransitUrl, setPublicTransitUrl] = useState(null);
    const [bicyclingTime, setBicyclingTime] = useState(null);
    const [bicyclingUrl, setBicyclingUrl] = useState(null);
    const [showMoreBio, setShowMoreBio] = useState(false);
    const [copyToClipboardText, setCopyToClipboardText] = useState("Copy to Clipboard");
    const [bioOverflowing, setBioOverflowing] = useState(false);
    const [curDocLocation, setCurDocLocation] = useState(docLocation);
    const [totalDocs, setTotalDocs] = useState(filteredDocs?.length);
    const [sharingModalOpen, setSharingModalOpen] = useState(null);

    // for sharing

    const handleOpenSharing = () => {
        // Copy the link to the doctor profile to clipboard
        setSharingModalOpen(true);
    };

    const onClickShareButton = async (e, version) => {
        e.preventDefault();
        const currentUrl = localStorage.getItem("share_url");
        if (version === "personalize")
            navigator.clipboard.writeText(currentUrl + `&reasonsForMatching=${encodeURIComponent(JSON.stringify(personInfo.strongestTraitMatches.map((trait) => trait.reasons_for_matching)))}`);
        else
            navigator.clipboard.writeText(currentUrl);
        await dispatch(setSnackbarMessage("Copied to clipboard!"));
        await dispatch(setSnackbarOpen(true));
    }

    const handleCloseSharingModal = () => {
        setSharingModalOpen(false);
    };

    const isFavorite = favoriteDoctorIds ? favoriteDoctorIds.includes(personInfo.id) : false;

    const guestUserTopDocPreview = !loggedIn && existsMatchingProviders;

    const modalStyle = onMobile ?{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        height: "",
        flexDirection: 'column',
        justifyContent: '',
        alignItems: 'center',
        width: "100vw",
        minHeight: "90%",
        maxWidth: "100%",
        maxHeight: "85vh",
        minWidth: "95%",
        bgcolor: 'white',
        borderRadius: '0rem',
        boxShadow: 24,
        px: 2,
        pb: 3,
        overflowY: !onMobile ? "" : "scroll",
    } : {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        height: "100%",
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: '',
        width: "70%",
        minHeight: "90%",
        maxWidth: '55rem',
        maxHeight: '90vh',
        minWidth: '50rem',
        bgcolor: 'white',
        borderRadius: !onMobile ? '1rem' : '0rem',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
        overflowY: !onMobile ? "" : "scroll",

    };

    const textStyleOne = !onMobile ? {
        flexDirection: 'row',
        fontWeight: 800,
        fontSize: '1.75em',
        lineHeight: '1em',
        textAlign: 'left',
    } : {
        margin: "0.75rem 0",
        fontWeight: 800,
        fontSize: '1.75em',
        lineHeight: '1.1em',
    }

    const textStyleTwo = {
        fontSize: "1rem",
        maxHeight: showMoreBio ? "" : "12rem",
        minHeight: showMoreBio ? "87%" : "60%",
        overflow: showMoreBio ? "" : "hidden",
        textOverflow: "ellipsis"
    }

    const textStyleThree = !onMobile ? {
        display: "flex",
        flexDirection: "row",
        fontSize: '1.1em',
    } : {
        display: "flex",
        flexDirection: "row",
        fontWeight: 500,
        fontSize: '1em',
        lineHeight: '1.1em',
    }

    const textStyleFour = !onMobile ? {
        flexDirection: 'row',
        fontWeight: 400,
        fontSize: '1.6em',
        fontHeight: '1em',
        textAlign: 'left',
        marginTop: '0.5rem',
        marginBottom: '0.5rem'
    } : {
        marginBottom: "0.5rem",
        fontWeight: 600,
        fontSize: '1.3em',
        lineHeight: '1.1em',
    }

    const reasonsForMatchingText = {
        marginLeft: !onMobile ? '0.75rem' : '0.5rem',
        marginTop: !onMobile ? "0.2rem" : "0.1rem",
        fontSize: !onMobile ? "1.1em" : "1rem",
        lineHeight: !onMobile ? "" : "1.2rem",
    }

    const chipStyles = {
        // margin: "0.1rem 0.4rem",
        marginRight: "10px",
        marginBottom: "12px",
        padding: "8px",
        backgroundColor: "rgba(0,0,0,0.75)",
        color: "white",
        "&:hover": {
            backgroundColor: "rgba(0,0,0,0.6)",
            color: "white",
        }
    }

    //change person cards state when user presses escape, left arrow, or right arrow
    const handleKeyPress = async (e) => {
        e.preventDefault();
        switch (e.key) {
            case 'Escape':
                await closePersonCard(e);
                break;
            case 'ArrowLeft':
                await handleClickBack(e);
                break;
            case 'ArrowRight':
                await handleClickNext(e);
                break;
            default:
                break;
        }
        
    }
    useEffect(() => {
        const keyPressHandler = async (e) => {
            await handleKeyPress(e);
        };
        document.addEventListener('keydown', keyPressHandler);

        return () => {
            document.removeEventListener('keydown', keyPressHandler);
        };
    })

    useEffect(() => {
        const asyncBootstrap = async () => {
            let queryParams;
            if (curDocLocation) {
                queryParams = `?docId=${encodeURIComponent(personInfo.id)}&docLoc=${encodeURIComponent(curDocLocation)}`;
                localStorage.setItem("share_url", window.location.href + queryParams);
            } else {
                queryParams = `?docId=${encodeURIComponent(personInfo.id)}`;
                localStorage.setItem("share_url", window.location.href + queryParams);
            }
            navigate("/home" + queryParams);

            //If the user has inputted a location, get the distance(s)
            if (latLong)
                await getDistance();
            //If the bio has loaded, check if it is overflowing
            if (showMoreRef.current)
                setBioOverflowing(showMoreRef.current.clientHeight < showMoreRef.current.scrollHeight);
        }
        asyncBootstrap();
    }, [cardIndex])

    //close insurance dialog
    const handleOpenInsuranceDialog = (e) => {
        e.preventDefault();
        setInsuranceDialog(true);
    }

    //send GA event when phone number is clicked and show phone number
    const handlePhoneNumberClicked = (e) => {
        e.preventDefault();
        // ReactGA.event({
        //     category: 'Button',
        //     action: 'Click',
        //     label: 'Phone Number'
        // });
        setPhoneClicked(true);
    }

    //close insurance dialog
    const handleCloseInsuranceDialog = (e) => {
        e.preventDefault();
        setInsuranceDialog(false);
    }

    //send GA event that user attempted to make an appointment
    const handleBookAppointment = () => {
        // ReactGA.event({
        //     category: 'Button',
        //     action: 'Click',
        //     label: 'Request Appointment'
        // });
        window.open(personInfo.site);
    }

    //if there are strongestTraitMatches, return the reasons for matching
    const reasonsForMatching = (location) => {
        return (
            <Box aria-label="match-reasons">
                {(personInfo.strongestTraitMatches || tempDocReasonsForMatching) &&
                    formatReasonsForMatching(location)
                }
            </Box>
        )
    }

    /***
     * Goes to next doctor card
     * 
     * @param {event} e
     */
    const handleClickNext = async (e) => {
        e.preventDefault();
        setPhoneClicked(false);
        await removeTempDoc();
        if (totalDocs === 1) return;
        if ((cardIndex + 1) >= totalDocs || guestUserTopDocPreview && cardIndex === 3) {
            setCurDocLocation(filteredDocs[0].Locations?.[0]?.address)
            await dispatch(setCardIndex(0))
        } else {
            setCurDocLocation(filteredDocs[cardIndex + 1].Locations?.[0]?.address)
            await dispatch(setCardIndex(cardIndex + 1))
        }
    }

    /***
     * Goes to previous doctor card
     * 
     * @param {event} e
     */
    const handleClickBack = async (e) => {
        e.preventDefault();
        removeTempDoc();
        setPhoneClicked(false);
        if (totalDocs === 1) return;
        if ((cardIndex - 1) < 0 && guestUserTopDocPreview) {
            const index = Math.min(3, totalDocs - 1)
            setCurDocLocation(filteredDocs[index].location)
            await dispatch(setCardIndex(index))
        } else if ((cardIndex - 1) < 0) {
            setCurDocLocation(filteredDocs[totalDocs - 1].Locations?.[0]?.address)
            await dispatch(setCardIndex(totalDocs - 1))
        } else {
            setCurDocLocation(filteredDocs[cardIndex - 1].Locations?.[0]?.address)
            await dispatch(setCardIndex(cardIndex - 1))
        }
    }

    /***
     * Closes person card
     * 
     * @param {event} e
     */
    const closePersonCard = async (e) => {
        e.preventDefault();
        await removeTempDoc();
        await dispatch(setPersonCardOpen(false));
        localStorage.removeItem("share_url");
        navigate("/home");
    }

    const removeTempDoc = async () => {
        await dispatch(setTempDocId(null));
        await dispatch(setTempDocLoc(null));
        await dispatch(setTempDocReasonsForMatching(null));
    }

    /***
     * Navigate to questionnaire
     */
    const goToQuestionnaire = () => {
        navigate("/onboard/questionnaire/0");
    }

    /***
     * Add doctor to favorites
     * 
     * @param {event} e
     */
    const addToFavorites = async (e) => {
        e.preventDefault();

        const res = await onAddToFavorites(e, personInfo.id);
        if (res) {
            await dispatch(setSnackbarMessage("Added to favorites"));
            await dispatch(setSnackbarOpen(true));
        } else {
            await dispatch(setSnackbarMessage("Something happened on our end, please try again!"));
            await dispatch(setSnackbarOpen(true));
        }
    }

    /***
     * Remove doctor from favorites
     * 
     * @param {event} e
     */
    const removeFromFavorites = async (e) => {
        e.preventDefault();
        const removeRes = await onRemoveFromFavorites(e, personInfo.id);
        if (removeRes) {
            await dispatch(setSnackbarMessage("Removed from favorites!"));
            await dispatch(setSnackbarOpen(true));
        } else {
            await dispatch(setSnackbarMessage("Error removing from favorites!"));
            await dispatch(setSnackbarOpen(true));
        }
    }

    /***
     * Format phone number from db
     * 
     * @param {string} phoneNumber
     * @returns {string} formatted phone number
     */
    const formatPhoneNumber = (phoneNumber) => {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return '(' + match[1] + ')-' + match[2] + '-' + match[3];
        }
        return phoneNumber;
    }

    /***
     * Toggle "show more" for bio
     * 
     * @param {event} e
     */
    const onClickToggleBio = (e) => {
        e.preventDefault();
        setShowMoreBio(!showMoreBio);
    }

    /***
     * For as many insurances the user has selected, return a string of the insurances overlapping with the doctor's insurances
     * 
     * @returns {string} - the string of overlapping insurances
     * @example returns "Aetna, Blue Cross Blue Shield"
     * 
     * TODO: find a way to use overlappingInsurance() instead of looping through the insurances again
     */
    const insuranceOverlapString = () => {
        const insurances = overlappingInsurance();
        let insuranceString = "";
        for (let i = 0; i < insurances.length; i++) {
            const ins = insurances[i];
            if (ins.name === "Any Insurance") continue;
            insuranceString += ins.name;
            if (i !== insurances.length - 1) insuranceString += ", ";
        }
        return insuranceString;
    }

    /***
     * Find overlapping insurances between the user and the doctor
     * 
     * @returns {array} - the array of overlapping insurances
     */
    const overlappingInsurance = () => {
        return personInfo?.Insurances?.filter(ins => patientInsurance?.includes(ins.name));
    }

    /***
     * Copy the current doctor's location to the clipboard
     * 
     * @param {event} e
     */
    const copyToClipboard = async (e) => {
        e.preventDefault();
        navigator.clipboard.writeText(curDocLocation);
        if (!onMobile) {
            setCopyToClipboardText("Copied to clipboard");
            setTimeout(() => {
                setCopyToClipboardText("Copy to clipboard");
            }, 2000);
        } else {
            await dispatch(setSnackbarMessage("Copied to clipboard!"));
            await dispatch(setSnackbarOpen(true));
        }
    }

    /***
     * Open mini doc card on the map
     * 
     * @param {event} e
     */
    const goToDocMarkerFromPersonCard = async (e) => {
        e.preventDefault();
        await dispatch(setDocMarkerFromPersonCard(personInfo.id));
        await closePersonCard(e);
    }

    const loadResponseTime = () => {
        if (personInfo.response_time <= 0.25) {
            return "Within 24 hours";
        } else if (personInfo.response_time <= 0.75) {
            return "Within 3 days";
        } else if (personInfo.response_time <= 1) {
            return "Within 1 week";
        } else {
            return "We need more data for an accurate estimate";
        }
    };

    /***
     * Get the distance between the user and the doctor
     */
    const getDistance = async () => {
        const latitude = personInfo?.Locations?.find(loc => loc.address === curDocLocation)?.latitude;
        const longitude = personInfo?.Locations?.find(loc => loc.address === curDocLocation)?.longitude;

        if (!curDocLocation || !latitude || !longitude) return null;
        const docLatLong = {
            lat: latitude,
            lng: longitude,
        }
        if (doctorDistance && doctorDistance[cardIndex]?.distance) {
            setTravelDistancesAndTimes(doctorDistance[cardIndex], docLatLong);
            return doctorDistance[cardIndex];
        }
        const distancesAndTimes = await dispatch(findDoctorDistace(latLong, docLatLong, cardIndex, false));
        if (distancesAndTimes)
            setTravelDistancesAndTimes(distancesAndTimes, docLatLong);
    }

    /***
     * Set the distance and time between the user and the doctor
     * 
     * @param {object} distancesAndTimes - the distance and time between the user and the doctor
     * @param {object} docLatLong - the latitude and longitude of the doctor
     */
    const setTravelDistancesAndTimes = (distancesAndTimes, docLatLong) => {
        const { distance, drivingTime, publicTransitTime, bicyclingTime } = distancesAndTimes;
        setDistance(distance);

        setDrivingTime(drivingTime);
        setDrivingUrl(`https://www.google.com/maps/dir/?api=1&origin=${latLong.lat},${latLong.lng}&destination=${docLatLong.lat},${docLatLong.lng}&travelmode=driving`);

        setPublicTransitTime(publicTransitTime);
        setPublicTransitUrl(`https://www.google.com/maps/dir/?api=1&origin=${latLong.lat},${latLong.lng}&destination=${docLatLong.lat},${docLatLong.lng}&travelmode=transit`);

        setBicyclingTime(bicyclingTime);
        setBicyclingUrl(`https://www.google.com/maps/dir/?api=1&origin=${latLong.lat},${latLong.lng}&destination=${docLatLong.lat},${docLatLong.lng}&travelmode=bicycling`);
    }

    /***
     * Open the doctor's location in Google Maps
     * 
     * @param {event} e
     * @param {string} mode - the mode of transportation
     * @example mode = "car"
     */
    const openMapsUrl = (e, mode) => {
        e.preventDefault();
        switch (mode) {
            case "car":
                window.open(drivingUrl);
                break;
            case "bus":
                window.open(publicTransitUrl);
                break;
            case "bicycle":
                window.open(bicyclingUrl);
                break;
            default:
                break;
        }
    }

    /***
     * Add the doctor to the user's favorites when the user clicks the favorite icon
     * 
     * @returns {component} - the favorite icon filled in if the doctor is a favorite, otherwise the favorite icon outlined
     */
    const heartIcon = () => {
        return isFavorite ?
            <Tooltip title="Remove Favorite">
                <FavoriteIcon sx={{ color: theme.palette.caralystRed.main }}
                    onClick={async (e) => await removeFromFavorites(e)}
                />
            </Tooltip>
            :
            <Tooltip title="Add Favorite">
                <FavoriteBorderIcon sx={{ color: theme.palette.caralystRed.main }}
                    onClick={async (e) => await addToFavorites(e)}
                />
            </Tooltip>
    }

    /***
     * Get the reasons for matching between the user and the doctor
     * 
     * @returns {component} - the reasons for matching between the user and the doctor
     */
    const formatReasonsForMatching = (location) => {
        const traitNames = personInfo?.strongestTraitMatches?.map((trait) => trait.reasons_for_matching);
        if (location === "bottom" && traitNames === tempDocReasonsForMatching) return null;
        const reasonsFromAnotherUser = tempDocReasonsForMatching && tempDocReasonsForMatching !== "undefined";
        const reasonsToMatch = reasonsFromAnotherUser ? JSON.parse(tempDocReasonsForMatching) : traitNames;
        const title = reasonsFromAnotherUser ? "Reasons why another user matched with this doctor" : "Reasons why you matched with this doctor";
        return reasonsToMatch.length > 0 &&
            <Box>
                <Typography
                    className="person-matching-header"
                    color='#414141'
                    sx={!onMobile ? {
                        flexDirection: 'row',
                        fontWeight: 800,
                        fontSize: '25px',
                        fontFamily: 'Inter',
                        lineHeight: '1em',
                        textAlign: 'left',
                        whiteSpace: "nowrap",
                        width: "100%",
                        alignItems: "center",
                        display: "flex",
                    } : {
                        margin: "0.75rem 0",
                        fontWeight: 800,
                        fontSize: '1.75em',
                        lineHeight: '1.1em',
                        whiteSpace: "wrap",
                    }}
                >
                        {reasonsFromAnotherUser && location === "top" ?
                            <Typography
                                className="person-matching-header"
                                color='#414141'
                                sx={{ ...textStyleOne, fontSize: "1em" }}
                            >
                                A different user matched on {reasonsToMatch.length} {" "}
                                <Box component={"span"} sx={{
                                    whiteSpace: "nowrap",
                                }}>
                                    dimensions
                                    <CustomWidthTooltip title={title}>
                                        <IconButton style={{ color: '#414141' }}>
                                            <HelpIcon />
                                        </IconButton>
                                    </CustomWidthTooltip>
                                </Box>
                            </Typography>
                            :
                            <Typography
                                className="person-matching-header"
                                color='#414141'
                                sx={{ ...textStyleOne, fontSize: "1em" }}
                            >
                                You matched on {reasonsToMatch.length} {" "}
                                <Box component={"span"} sx={{
                                    whiteSpace: "nowrap",
                                }}>
                                    dimensions
                                    <CustomWidthTooltip title={title}>
                                        <IconButton style={{ color: '#414141' }}>
                                            <HelpIcon />
                                        </IconButton>
                                    </CustomWidthTooltip>
                                </Box>
                            </Typography>
                        }
                        

                </Typography>
                <Box className="person-matching-container" >
                    {reasonsToMatch.map((reason, index) => {
                        if (reason) return (
                            <Container
                                className="match-reason-container"
                                key={`${index}-matchreason`}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    marginLeft: !onMobile ? "-1.25rem" : "-0.5rem",
                                    marginBottom: "0.5rem",
                                }}
                            >
                                <Box sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    maxWidth: "1.25em",
                                    maxHeight: "1.25em",
                                    boxShadow: "0 3px 5px rgba(0, 0, 0, 0.4)", // Adjust the values as per your preference
                                    borderRadius: "50%",
                                    backgroundColor: "transparent",
                                }}>
                                    <i className="fas fa-check-circle fa-2x" style={{
                                        color: theme.palette.caralystGreen.main,
                                        fontSize: !onMobile ? "1.6em" : "1.5em",

                                    }} />
                                </Box>
                                <Box sx={{
                                    marginLeft: "0.5rem",
                                }}>
                                    <Typography
                                        className="match-reason"
                                        sx={reasonsForMatchingText}
                                    >
                                        {reason}
                                    </Typography>
                                </Box>
                            </Container>
                        )
                        return null;
                    })}
                </Box>
            </Box>
    }

    /***
     * Get the doctor's bio and basic information
     * 
     * @returns {component} - the doctor's bio and basic information
     */
    const docBio = () => {
        return (
            <Box aria-label="bio"
                sx={{
                    marginTop: "1rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    maxWidth: "100%",
                    overflowY: "scroll",
                    maxHeight: !onMobile ? "50%" : "none",
                }}>
                {personInfo.bio !== null ?
                    <Box sx={{
                        marginBottom: "15px",
                    }}>
                        <Typography
                            sx={
                                !onMobile ? {
                                    flexDirection: 'row',
                                    fontWeight: 800,
                                    color: "#414141",
                                    fontSize: '25px',
                                    lineHeight: '1em',
                                    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                                    textAlign: 'left',
                                    marginBottom: '12px'
                                } : {
                                    margin: "0.75rem 0",
                                    fontWeight: 800,
                                    fontSize: '1.75em',
                                    lineHeight: '1.1em',
                                }
                            }
                        >
                            {personInfo.first_name}'s Story:
                        </Typography>
                        <Box>
                            <Box sx={{ position: "relative" }}>
                                <Typography
                                    ref={showMoreRef}
                                    sx={textStyleTwo}
                                >
                                    {personInfo.bio}
                                </Typography>
                                {bioOverflowing && !showMoreBio &&
                                    <Box aria-label="new-box"
                                        sx={{
                                            position: "absolute",
                                            bottom: 0,
                                            left: 0,
                                            width: "100%",
                                            height: "2.5rem",
                                            zIndex: 1,
                                            background: `linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 1)) `,
                                            overlap: "auto",
                                        }} />
                                }
                            </Box>
                            {bioOverflowing &&
                                <Typography
                                    onClick={onClickToggleBio}
                                    sx={{
                                        position: "relative",
                                        cursor: "pointer",
                                        fontSize: "1rem",
                                        fontStyle: 'normal',
                                        color: theme.palette.caralystRed.main,
                                    }}>
                                    {showMoreBio ? "Hide" : <strong>Read More</strong>}
                                </Typography>}
                        </Box>
                    </Box>
                    : null
                }
                {personInfo?.education ?
                    <Box
                        sx={{
                            marginBottom: "1rem",
                        }}
                    >
                        <Typography
                            sx={
                                !onMobile ? {
                                    flexDirection: 'row',
                                    fontWeight: 800,
                                    fontSize: '25px',
                                    lineHeight: '1em',
                                    textAlign: 'left',
                                } : {
                                    margin: "0.75rem 0",
                                    fontWeight: 800,
                                    fontSize: '1.75em',
                                    lineHeight: '1.1em',
                                }
                            }
                        >
                            Education
                        </Typography>
                        <Typography sx={textStyleThree}>
                            {personInfo.education}
                        </Typography>
                    </Box>
                    : null
                }
                {personInfo?.residency ?
                    <Box
                        sx={{
                            marginBottom: "1rem",
                        }}
                    >
                        <Typography
                            sx={textStyleOne}
                        >
                            Residency
                        </Typography>
                        <Typography sx={textStyleThree}>
                            {personInfo.residency}
                        </Typography>
                        
                    </Box>
                    :
                    null
                }
            </Box>
        );
    }

    /***
     * Get the doctor's information
     * 
     * @returns {component} - the doctor's information
     */
    const docInfoWrapper = () => {
        return (
            <Box
                className="doc-info-wrapper"
                sx={!onMobile ? {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    minWidth: "15rem",
                    maxWidth: "100%",
                    maxHeight: "100%",
                    overflowY: "auto",
                    overflowX: "hidden !important",
                } : {
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: "100%"
                }}>
                <Box sx={!onMobile ? {
                    maxWidth: "100%"
                } : {
                    maxWidth: "97%",
                    overflowWrap: "break-word",
                    maxHeight: "100%",
                    margin: "0.5rem 0",
                    overflowY: "auto",
                }}>
                    <Box
                        component="span"
                        sx={{
                            display:"flex",
                            flexDirection: "column",
                            marginBottom: "12px",
                            width: "100%",
                            flexWrap: "nowrap",
                            overflowX: "auto",
                            whiteSpace: "nowrap",
                        }}
                    >
                        {personInfo.first_name && personInfo.last_name &&
                            <Typography
                                display="inline"
                                color={theme.palette.caralystNavy.main}
                                sx={{...textStyleOne, overflowX: ""}}
                            >
                                <Box
                                    component="span"
                                    sx={{
                                        fontFamily: 'Inter', // Setting font family to Inter
                                        fontWeight: '800', // Making the first part of the text bold
                                        fontSize: '25px', // Setting font size for the first part to 20 pixels
                                        color: '#414141', // Setting color for the first part to #414141
                                }}>
                                    {(personInfo.first_name + " " + personInfo.last_name + 
                                        (!personInfo.user_qualification ? "" : (", " + personInfo.user_qualification))
                                    )}
                                </Box>
                            </Typography>
                        }
                        {personInfo.pronouns &&
                            <Box
                                component={"span"}
                                sx={{
                                    fontFamily: 'Inter',
                                    fontWeight: '400',
                                    fontSize: '15px',
                                    color: '#3A3A3A',
                                    marginTop: onMobile && "-0.8rem",
                            }}>
                                {"(" + personInfo.pronouns + ")"}
                            </Box>
                        }
                    </Box>
                    {personInfo.user_specialty &&
                        <Typography
                            display="flex"
                            sx={{ marginBottom: "12px" }}
                        >
                            <Box
                                component="span"
                                sx={{
                                    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                                    fontWeight: 'regular', // Making the first part of the text bold
                                    fontSize: '20px', // Setting font size for the first part to 20 pixels
                                    color: '#414141', // Setting color for the first part to #414141
                                }}
                            >
                                {personInfo?.user_specialty}
                            </Box>
                        </Typography>
                    }
                    {docLocation &&
                        <Box
                            className="location"
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                maxWidth: "100%",

                            }}>
                            <Box aria-label="distance-and-location" sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                marginBottom: "12px",
                                overflowX: "auto",
                                overflowY: "hidden",
                                width: "100%",
                            }}>
                                <Tooltip title="Show on map" placement="left">
                                    <LocationOnIcon
                                        onClick={async (e) => goToDocMarkerFromPersonCard(e)}
                                        sx={{
                                            ...quickFactsIconStyle,
                                            cursor: 'pointer',
                                        }} />
                                </Tooltip>
                                &nbsp;
                                <Typography aria-label="distance"
                                    sx={{ ...textStyleThree, whiteSpace: "nowrap", fontWeight: "800" }}
                                >
                                    {Object.keys(latLong).length === 2 && distance &&
                                        <>
                                            {distance}
                                            <BulletPoint size={1.95} weigth={800} marginLeftRight={-0.6} marginTopBottom={-0.6} onMobile={onMobile} />
                                        </>
                                    }
                                </Typography>
                                <Typography aria-label="location"
                                    sx={{
                                        fontSize: !onMobile ? "1.1em" : "1em",
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "row",
                                        overflowX: "auto",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    {curDocLocation.split(",")[0]}
                                    &nbsp;
                                    <Tooltip
                                        title={copyToClipboardText}
                                        placement="right"
                                    >
                                        <ContentCopyIcon
                                            onClick={copyToClipboard}
                                            sx={{
                                                fontSize: '0.8em',
                                                marginTop: "0.3rem",
                                                cursor: 'pointer',
                                            }} />
                                    </Tooltip>
                                </Typography>
                            </Box>
                            <Box aria-label="travel-options"
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    maxWidth: "100%",
                                    overflowX: "auto",
                                }}>
                                {drivingTime &&
                                    <Tooltip title="Directions by Car">
                                        <Chip
                                            size="small"
                                            avatar={
                                                <DirectionsCarIcon
                                                    sx={{ color: "white !important" }}
                                                />
                                            }
                                            label={drivingTime}
                                            sx={chipStyles}
                                            onClick={e => openMapsUrl(e, "car")}
                                        />
                                    </Tooltip>
                                }
                                {publicTransitTime &&
                                    <Tooltip title="Directions by Bus">
                                        <Chip
                                            size="small"
                                            avatar={
                                                <DirectionsBusIcon
                                                    style={{ color: "white" }}
                                                />
                                            }
                                            label={publicTransitTime}
                                            sx={chipStyles}
                                            onClick={e => openMapsUrl(e, "bus")}
                                        />
                                    </Tooltip>
                                }
                                {bicyclingTime &&
                                    <Tooltip title="Directions by Bicycle">
                                        <Chip
                                            size="small"
                                            avatar={
                                                <DirectionsBikeIcon
                                                    style={{ color: "white" }}
                                                />
                                            }
                                            label={bicyclingTime}
                                            sx={chipStyles}
                                            onClick={e => openMapsUrl(e, "bicycle")}
                                        />
                                    </Tooltip>
                                }
                            </Box>
                        </Box>
                    }
                    {(personInfo?.response_time != null) &&
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            marginBottom: "10px",
                            whiteSpace: "nowrap",
                            overflowX: "auto",
                            overflowY: "hidden",
                        }}>
                            <AccessTimeFilledIcon sx={
                                quickFactsIconStyle
                            } />
                            &nbsp;
                            <Typography
                                className='response-time-title'
                                sx={textStyleThree}
                            >
                                Average response time
                            </Typography>
                            <BulletPoint size={1.95} weigth={800} marginLeftRight={-0.6} marginTopBottom={-0.6} onMobile={onMobile} />
                            <Typography sx={{ ...textStyleThree, fontWeight: "bold" }}>
                                {loadResponseTime()}
                            </Typography>
                        </Box>
                    }
                    {org === "general" && personInfo?.Insurances?.length > 0 ?
                        <Box className="insurance">
                            {(patientInsurance?.includes("Any Insurance") && patientInsurance?.length === 1) || (overlappingInsurance()).length === 0 ?
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "flex-start",
                                        cursor: 'pointer',
                                        marginBottom: "12px"
                                    }}
                                    onClick={handleOpenInsuranceDialog}
                                >
                                    <ShieldIcon
                                        sx={{
                                            ...quickFactsIconStyle,
                                            color: theme.palette.burntOrange.main,
                                        }}
                                    />
                                    &nbsp;
                                    <Typography
                                        className='accepted-insurance'
                                        sx={{ ...textStyleThree }}
                                    >
                                        See if they're in your network
                                    </Typography>
                                </Box>
                                :
                                //If the patient has selected specific insurance
                                <Box sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginBottom: "0.5rem"
                                }}>
                                    <ShieldIcon
                                        sx={{
                                            fontSize: !onMobile ? '1.5em' : '1.35em',
                                            color: "green"
                                        }}
                                    />
                                    &nbsp;
                                    <Box sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        flexWrap: "wrap",
                                    }}>
                                        <Typography
                                            aria-label='accepted-insurance'
                                            sx={{ ...textStyleThree, whiteSpace: !onMobile ? "" : "nowrap" }}
                                        >
                                            In network
                                            <BulletPoint size={1.95} weigth={800} marginLeftRight={-0.6} marginTopBottom={-0.6} onMobile={onMobile} />
                                            {insuranceOverlapString()}
                                        </Typography>
                                    </Box>
                                </Box>
                            }
                        </Box>
                        :
                        org === "wustl" &&
                            <Box sx={{
                                display: "flex",
                                flexDirection: "row",
                                marginBottom: "0.5rem"
                            }}>
                                <ShieldIcon
                                    sx={{
                                        fontSize: !onMobile ? '1.5em' : '1.35em',
                                        color: "green"
                                    }}
                                />
                                &nbsp;
                                <Box sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                }}>
                                    <Typography
                                        aria-label='accepted-insurance'
                                        sx={{ ...textStyleThree, whiteSpace: !onMobile ? "" : "nowrap" }}
                                    >
                                        In network
                                        <BulletPoint size={1.95} weigth={800} marginLeftRight={-0.6} marginTopBottom={-0.6} onMobile={onMobile} />
                                        WashU Student Health
                                    </Typography>
                                </Box>
                            </Box>
                    }
                    {(personInfo?.communication_by_phone || personInfo?.communication_by_email || personInfo?.communication_by_text || personInfo?.communication_by_mychart) &&
                        <Box
                            className="communication-methods"
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                // marginBottom: "12px",
                                // margin: "0.2rem 0",
                            }}>
                            <Box sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "flex-start",
                                marginBottom: "12px",
                            }}>
                                <RecordVoiceOverIcon sx={
                                    quickFactsIconStyle
                                } />
                                &nbsp;
                                <Typography
                                    sx={{ ...textStyleThree }}
                                >
                                    Communicates via
                                </Typography>
                            </Box>
                            <Box sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "flex-start",
                                justifyContent: "space-between",
                                maxWidth: "100%",
                                overflowX: "auto",
                            }}>
                                {personInfo?.communication_by_phone &&
                                    <Tooltip title="Contact by Phone">
                                        <Chip
                                            size="small"
                                            avatar={
                                                <LocalPhoneIcon
                                                    sx={{ color: "white !important", cursor: 'pointer', fontSize: "10px" }}
                                                />
                                            }
                                            sx={chipStyles}
                                            label="Phone"
                                        />
                                    </Tooltip>
                                }
                                {personInfo?.communication_by_email &&
                                    <Tooltip title="Contact by Email">
                                        <Chip
                                            size="small"
                                            avatar={
                                                <EmailIcon
                                                    style={{ color: "white", cursor: 'pointer' }}
                                                />
                                            }
                                            sx={chipStyles}
                                            label="Email"
                                        />
                                    </Tooltip>
                                }
                                {personInfo?.communication_by_text &&
                                    <Tooltip title="Contact by Text">
                                        <Chip
                                            size="small"
                                            avatar={
                                                <ChatIcon
                                                    style={{ color: "white", cursor: 'pointer' }}
                                                />
                                            }
                                            sx={chipStyles}
                                            label="Text"
                                        />
                                    </Tooltip>
                                }
                                {personInfo?.communication_by_mychart &&
                                    <Tooltip title="Contact by Mychart">
                                        <Chip
                                            size="small"
                                            avatar={
                                                <FolderIcon
                                                    style={{ color: "white", cursor: 'pointer' }}
                                                />
                                            }
                                            sx={chipStyles}
                                            label="Mychart"
                                        />
                                    </Tooltip>
                                }
                            </Box>
                        </Box>
                    }
                    {personInfo?.phone &&
                        <Box
                            onClick={handlePhoneNumberClicked}
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                marginBottom: "12px",
                            }}>
                            <LocalPhoneIcon
                                sx={
                                    quickFactsIconStyle
                                } />
                            &nbsp;
                            <Typography sx={textStyleThree}>
                                {phoneClicked ?
                                    formatPhoneNumber(personInfo.phone) :
                                    "View Phone Number"
                                }
                            </Typography>
                        </Box>
                    }
                    {personInfo?.modality &&
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginBottom: "12px",
                            overflowX: "auto",
                        }}>
                            <ComputerIcon sx={
                                quickFactsIconStyle
                            } />
                            &nbsp;
                            <Typography sx={{
                                overflowX: "auto",
                                flexWrap: "nowrap",
                                width: "100%",
                                fontSize: !onMobile ? "1.1em" : "1em",
                                whiteSpace: "nowrap"
                            }}>
                                {personInfo.modality}
                            </Typography>
                        </Box>
                    }
                    <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginBottom: "15px",
                        overflowX: "auto",
                    }}>
                        <CheckCircleIcon sx={{
                            ...quickFactsIconStyle,
                            color: "green"
                        }} />
                        &nbsp;
                        <Typography sx={{
                            overflowX: "auto",
                            flexWrap: "nowrap",
                            width: "100%",
                            fontSize: !onMobile ? "1.1em" : "1em",
                            whiteSpace: "nowrap",
                            fontWeight: "bold"
                        }}>
                            Accepting new patients
                        </Typography>
                    </Box>
                </Box>
                {/* <Box className="spacer" sx={{ height: "0.5rem" }} /> */}
                <Box>
                    <Tooltip title={personInfo.site === null ? "Please call this provider to schedule an appointment." : "Request appointment through this provider\'s site"}>
                        <Button
                            className="appointment-button"
                            variant="contained"
                            disabled={personInfo.site === null}
                            sx={personInfo.site === null ? { 
                                backgroundColor: '#fff', 
                                minHeight: '3rem',  
                                marginBottom: "15px"
                            } : {
                                backgroundColor: theme.palette.caralystRed.main,
                                minHeight: '3rem',
                                "&:hover": {
                                    backgroundColor: theme.palette.caralystRed.main,
                                    brightness: 0.8,
                                },
                                boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
                                marginBottom: "15px"
                            }}
                            onClick={() => { handleBookAppointment() }}>
                            <CalendarMonthIcon
                                className="request-appointment-icon"
                                sx={{
                                    color: "white",
                                    marginRight: "0.5rem",
                                    maxWidth: "4rem"
                                }}
                            />
                            <Typography
                                fontSize="16px"
                                fontFamily={"Inter"}
                                lineHeight={1.1}
                                textTransform="none"
                                fontWeight={800}
                                color="white"
                                textAlign="left"
                            >
                                Request Appointment
                            </Typography>
                        </Button>
                    </Tooltip>
                </Box>
                <Box aria-label="match-and-reviews"
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        minWidth: "55%",
                        maxWidth: "100%",

                    }}>
                    {tempDocReasonsForMatching && reasonsForMatching("top")}
                    {!existsMatchingProviders ?
                        <Typography
                            component={"div"}
                            sx={{
                                fontSize: '1.1em',
                            }}>
                            Take our three minute compatibility survey
                            {" "}
                            <Box
                                onClick={goToQuestionnaire}
                                sx={{
                                    display: "inline",
                                    textDecoration: 'underline',
                                    fontWeight: 'bold',
                                    cursor: 'pointer',
                                }}>
                                here
                            </Box>
                            {tempDocReasonsForMatching ?
                                ` to see where you'll match with ${personInfo.first_name}!` :
                                ` to see why ${personInfo.first_name} is a perfect match for you!`
                            }
                        </Typography>
                        :
                        reasonsForMatching("bottom")
                    }
                    <Box aria-label="reviews">

                    </Box>
                </Box>
            </Box>
        );
    }

    const iconButtonStyle = {
        backgroundColor: '#D9D9D9', // Set your desired background color
        borderRadius: '50%', // Optional: Makes the button circular
        padding: onMobile ? '5px' : '8px'
    };

    const quickFactsIconStyle = {
        fontSize: !onMobile ? '24px !important' : '20px !important',
    }

    const CustomWidthTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 500,
            fontSize: '16px'
        },
    });

    return (
        <>
            <Box sx={{ ...modalStyle }} className="person-card-wrapper">
                <Box className="left-half"
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        marginTop: !onMobile ? "20px" : "15px",
                        marginRight: !onMobile ? "20px" : "",
                        width: !onMobile ? "45%" : "100%",
                    }}
                >
                    <Box className="pfp-wrapper"
                        sx={!onMobile ? {
                            width: "40vh",
                            height: !onMobile ? "400px" : "300px",
                            maxWidth: "100%",
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "center",
                            border: "1px solid #E0E0E0",
                            borderRadius: "0.5rem",
                            overflowY: "hidden",
                        } : {
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "center",
                            height: "280px",
                            width: "100%",
                            marginBottom: "0.5rem",

                            border: "1px solid #E0E0E0",
                            borderRadius: "0.5rem",
                            overflowY: "hidden",
                            overflowX: "hidden"
                        }}
                        key={personInfo.id}
                    >
                        {dispatch(getPfp(personInfo, "person-card"))}
                    </Box>
                    {!onMobile && docBio()}
                    {!!onMobile && docInfoWrapper()}
                </Box>
                <Box aria-label="right-half"
                    sx={!onMobile ? {
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "20px",
                        // marginBottom: "2rem",
                        width: "50%",
                        overflowY: "auto"
                    } : {
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                    }}
                >
                    {!onMobile && docInfoWrapper()}
                    {!!onMobile && docBio()}
                </Box>
                <Box aria-label="footer"
                    sx={{
                        position: !onMobile ? "fixed" : "sticky",
                        bottom: !onMobile ? "0.5rem" : "-1rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        marginTop: !onMobile ? "" : "1rem",
                    }}
                >
                    <Button
                        variant='outlined'
                        sx={{
                            borderRadius: "2rem",
                            minWidth: "100px",
                            transition: "background-color 0.15s ease-in-out",
                            marginRight: "0.5rem",
                            backgroundColor: 'white',
                            borderColor: "#626262",
                            "&:hover": {
                                backgroundColor: "#626262",
                                borderColor: "#626262",
                                "& .MuiTypography-root": {
                                    color: "white",
                                },
                            }
                        }}
                        onClick={handleClickBack}
                    >
                        <Typography
                            sx={{
                                fontSize: '1em',
                                fontWeight: '500',
                                fontStyle: 'normal',
                                color: "#626262",
                            }}
                        >
                            Back
                        </Typography>
                    </Button>
                    <Button
                        variant='outlined'
                        sx={{
                            borderRadius: "2rem",
                            minWidth: "100px",
                            transition: "background-color 0.15s ease-in-out",
                            marginLeft: "0.5rem",
                            backgroundColor: 'white',
                            borderColor: "#626262",
                            "&:hover": {
                                backgroundColor: "#626262",
                                borderColor: "#626262",
                                "& .MuiTypography-root": {
                                    color: "white",
                                },
                            }
                        }}
                        onClick={async (e) => await handleClickNext(e)}
                    >
                        <Typography
                            sx={{
                                fontSize: '1em',
                                fontWeight: '500',
                                fontStyle: 'normal',
                                color: "#626262",
                                "&:hover": {
                                    color: "white"

                                }
                            }}
                        >
                            Next
                        </Typography>
                    </Button>
                    {loggedIn &&
                        <IconButton
                            sx={{
                                position: "absolute",
                                right: onMobile ? "-1.5rem" : "0.5rem",
                            }}
                        >
                            {heartIcon()}
                        </IconButton>
                    }
                </Box>
                <Box aria-label="x-out-button"
                >
                    <IconButton
                        style={iconButtonStyle}
                        sx={{
                            position: "absolute",
                            top: onMobile ? "0.3rem" : "0.5rem",
                            right: onMobile ? "0.3rem" : "0.5rem",
                            rotate: "90deg",
                            width: "25px",
                            height: "25px"
                        }}
                        onClick={async (e) => await closePersonCard(e)}
                    >
                        <CloseIcon
                            sx={{ fontSize: 16 }}
                        />
                    </IconButton>
                    <IconButton
                        style={iconButtonStyle}
                        sx={{
                            position: "absolute",
                            top: onMobile ? "0.3rem" : "0.5rem",
                            right: onMobile ? "0.3rem" : "0.5rem",
                            marginTop: onMobile ? "35px" : "35px",
                            width: "25px",
                            height: "25px"
                        }}
                        onClick={handleOpenSharing}
                    >
                        <IosShareIcon
                            sx={{ fontSize: 16 }}
                        />
                    </IconButton>
                </Box>
            </Box>
            <Dialog
                open={insuranceDialog}
                onClose={handleCloseInsuranceDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="xl"
            >
                <DialogContent>
                    <DialogTitle id="alert-dialog-description">
                        Accepted Insurances:
                    </DialogTitle>
                    {personInfo.Insurances.map((insurance, index) => {
                        return (
                            <Chip
                                key={index}
                                label={insurance.name}
                                variant="outlined"
                                backgroundColor={theme.palette.caralystGreen.main}
                            />
                        )
                    })
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseInsuranceDialog} autoFocus>
                        Ok!
                    </Button>
                </DialogActions>
            </Dialog>
            <Modal
                open={sharingModalOpen}
                onClose={handleCloseSharingModal}
                sx={{
                    '& .MuiBox-root': { border: "none" },
                    '& .MuiBackdrop-root': { display: "none !important" },
                }}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "80vw",
                        maxWidth: "400px",
                        maxHeight: "400px",
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "0.5rem",
                        overflowY: "scroll",
                        overflowX: "hidden",
                    }}
                >
                    <Tooltip title="Share this physician's profile">
                        <Button
                            variant="contained"
                            onClick={async e => await onClickShareButton(e, "general")}
                            sx={{
                                fontSize: "1rem",
                                fontWeight: "bold",
                                marginBottom: "1rem",
                                textTransform: "none",
                                backgroundColor: theme.palette.caralystGreen.main,
                                "&:hover": {
                                    backgroundColor: theme.palette.caralystGreen.main,
                                    opacity: 0.8,
                                }
                            }}>
                            Share Profile
                        </Button>
                    </Tooltip>
                    {personInfo.strongestTraitMatches && personInfo.strongestTraitMatches.length > 0 &&
                        <Tooltip title="Share this physician's profile and your match reasons">
                            <Button
                                variant="contained"
                                onClick={async e => await onClickShareButton(e, "personalize")}
                                sx={{
                                    fontSize: "1rem",
                                    fontWeight: "bold",
                                    marginBottom: "1rem",
                                    textTransform: "none",
                                    backgroundColor: theme.palette.caralystGreen.main,
                                    "&:hover": {
                                        backgroundColor: theme.palette.caralystGreen.main,
                                        opacity: 0.8,
                                    }
                                }}>
                                Share Personalized Profile
                            </Button>
                        </Tooltip>
                    }
                    <Button
                        variant="outlined"
                        onClick={handleCloseSharingModal}
                        sx={{
                            borderColor: theme.palette.caralystNavy.main,
                            color: theme.palette.caralystNavy.main,

                        }}>
                        Close
                    </Button>
                </Box>
            </Modal>
        </>

    )
}

export default PersonCard;