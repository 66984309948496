import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { LinearProgress, Box, Typography, Button, useMediaQuery, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import {
    loadQuestionnaire,
    uploadTraitStrengthMapping
} from "../../redux/actions/onboarding.actions"

import {
    selectLeftRightBounds,
    selectDoctorsLoadingStatus,
    selectLocation,
    selectLoggedIn,
    selectOrg,
    selectPatientId,
    selectPreferredInsurance,
    selectPreferredSpecialty,
    selectPreviousAnswers,
    selectQuestionnaire,
    selectQuestionsLoadingError,
    selectTopBottomBounds,
    selectTempDocId,
    selectModalityPreference,
} from "../../redux/store";
import Question from './Question';
import { useTheme } from "@mui/material/styles";
import LoadingIcon from '../Misc/LoadingIcon';
import { loadClinics, loadDoctors } from '../../redux/actions/doctors.actions';
import PersonInfoQuestion from './PersonInfoQuestion';
import { updateUserLatLong, setPreferredInsurance, setPreferredSpecialty, setUserLocation } from '../../redux/actions/user.actions';
import { org } from '../../redux/constants/shared.constants';

const QuestionnaireSection = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const theme = useTheme();
    const onMobile = !useMediaQuery('(min-width:600px)');

    const questionnaire = useSelector(selectQuestionnaire);
    const questionnaireLoading = useSelector(selectDoctorsLoadingStatus)
    const questionsLoadingError = useSelector(selectQuestionsLoadingError);
    const previousAnswers = useSelector(selectPreviousAnswers);
    const loggedIn = useSelector(selectLoggedIn);
    const patientId = useSelector(selectPatientId);
    const leftRightBounds = useSelector(selectLeftRightBounds);
    const topBottomBounds = useSelector(selectTopBottomBounds);
    const specialtyPreference = useSelector(selectPreferredSpecialty);
    const insurancePreference = useSelector(selectPreferredInsurance);
    const userLocation = useSelector(selectLocation);
    const tempDocId = useSelector(selectTempDocId);
    const preferredModality = useSelector(selectModalityPreference);

    const [newLocation, setNewLocation] = useState(userLocation);
    const [newPreferedSpecialty, setNewPreferedSpecialty] = useState(specialtyPreference);
    const [newPreferedInsurance, setNewPreferedInsurance] = useState(insurancePreference);
    const [questionIndex, setQuestionIndex] = useState(parseInt(params.index));

    const [errorMessageOpen, setErrorMessageOpen] = useState(false);
    const [onPersonInfo, setOnPersonInfo] = useState(parseInt(params.index) === 0 && org !== "wustl");

    useEffect(() => {
        const asyncInitalizeQuestionnaire = async () => {
            await dispatch(loadQuestionnaire(patientId));
        }
        asyncInitalizeQuestionnaire();

        checkError();
    }, [dispatch, questionsLoadingError]);

    const next = async (e) => {
        e.preventDefault();
        ReactGA.event({
            category: 'Button',
            action: 'Click',
            label: `Question ${parseInt(questionIndex) + 1} Next`,
        });

        if(parseInt(questionIndex) === 0) {
            setOnPersonInfo(false);
            setQuestionIndex(parseInt(questionIndex) + 1);
            await submitSearch(e);
            navigate(`/onboard/questionnaire/${parseInt(questionIndex) + 1}`);
        } else if(parseInt(questionIndex) + 1 < questionnaire?.length) {
            setQuestionIndex(parseInt(questionIndex) + 1);
            navigate(`/onboard/questionnaire/${parseInt(questionIndex) + 1}`);
        } else {
            await dispatch(loadDoctors(patientId, leftRightBounds, topBottomBounds, specialtyPreference, insurancePreference, onMobile, tempDocId, preferredModality));
            navigate('/home');
            await dispatch(loadClinics(leftRightBounds, topBottomBounds));
        }
    }

    const back = async (e) => {
        e.preventDefault();
        ReactGA.event({
            category: 'Button',
            action: 'Click',
            label: `Question ${parseInt(questionIndex) + 1} Previous`,
        });

        if(parseInt(questionIndex) === 1) {
            if(org !== "wustl")
                setOnPersonInfo(true);
            setQuestionIndex(parseInt(questionIndex) - 1);
            navigate(`/onboard/questionnaire/${parseInt(questionIndex) - 1}`);
        } else if(parseInt(questionIndex) - 1 >= 0) {
            setQuestionIndex(parseInt(questionIndex) - 1);
            navigate(`/onboard/questionnaire/${parseInt(questionIndex) - 1}`);
        } else {
            setOnPersonInfo(false);
            await submitSearch(e);
            await dispatch(loadDoctors(patientId, leftRightBounds, topBottomBounds, specialtyPreference, insurancePreference, onMobile, tempDocId, preferredModality));
            navigate('/home');
            await dispatch(loadClinics(leftRightBounds, topBottomBounds));
        }
    }

    const submitSearch = async (e) => {
        //If the user has changed a value, it should be updated
        if(newLocation !== userLocation) {
            await dispatch(setUserLocation(newLocation));
            await dispatch(updateUserLatLong(e, newLocation));
        }

        if(newPreferedSpecialty !== specialtyPreference) await dispatch(setPreferredSpecialty(newPreferedSpecialty, org, loggedIn, patientId));
        if(newPreferedInsurance !== insurancePreference) await dispatch(setPreferredInsurance(newPreferedInsurance, org, loggedIn, patientId));
    }

    const updateAndUploadMapping = async () => {
        //Create new strengths array to add to db
        const traitStrengths = []
        // Iterate through each question
        for(let questionInd in questionnaire[questionIndex].SurveyQuestions) {
            // Get the traits from each question
            const answers = questionnaire[questionIndex].SurveyQuestions[questionInd].AnswerChoices;

            //Reset all answers to null
            answers.forEach(answer => {
                answer.MatchingTraits.forEach(trait => {
                    traitStrengths.push({
                        traitId: trait.id,
                        strength: null,
                        status: "VIEWED",
                    })
                })
            })
            // Find if one of the answers.selected is either true or some numerical value
            const existsSelectedAnswer = answers.filter(answer => answer.selected);
            // If there is no selected answer, but we are submitting, it was a viewed question
            if(existsSelectedAnswer?.length !== 0) {
                // we use the traits from the selected answer to push to db
                //Set value for previous answers in db
                // For multiselect answers need to iterate over each selected answer
                existsSelectedAnswer.forEach(answer => {
                    const traits = answer.MatchingTraits;
                    // Iterate through each trait
                    const strength = answer.type === "SLIDER" ? 
                        previousAnswers[answer.id] 
                        :
                        answer.value;
                    traits.forEach(trait => {
                        traitStrengths.push({
                            traitId: trait.id,
                            strength: strength,
                            status: "CLICKED",
                        })
                    })
                })
            } 
        }
        
        await dispatch(uploadTraitStrengthMapping(traitStrengths, previousAnswers, patientId));
    }

    const checkError = () => {
        if(questionsLoadingError === true) setErrorMessageOpen(true);
    }

    const onCloseDialog = () => {
        setErrorMessageOpen(false);
        navigate('/home');
    }

    const progressBarValue = questionnaire ? (parseInt(questionIndex)) / questionnaire.length * 100 : 0;
    
    return (
        <>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                paddingTop: !onMobile ? "4rem" : "4rem",
                overflow: "hidden",
            }}>
                <Box sx={{
                    position: "fixed",
                    top: "4rem",
                    zIndex: theme.zIndex.appBar,
                }}>
                    <LinearProgress 
                        variant="determinate"
                        value={progressBarValue} 
                        sx={{
                            height: "0.75rem",
                            minWidth: "100vw",
                            backgroundColor: theme.palette.caralystNavy.light,
                            "& .MuiLinearProgress-bar": {
                                backgroundColor: theme.palette.caralystNavy.main,
                            }
                        }}
                        
                    />
                    <Typography
                        sx={{
                            position: "absolute",
                            left: progressBarValue === 100 ? (progressBarValue - 5)  + "%" :
                                    progressBarValue === 0 ? (progressBarValue + 0.5)  + "%" :
                                        (progressBarValue - 0.5) + "%",
                            top: "1rem",
                        }}
                    >
                        {Math.round(progressBarValue)}%
                    </Typography>
                </Box>
                {onPersonInfo ? 
                    <PersonInfoQuestion newLocation={newLocation} setNewLocation={setNewLocation} newPreferedInsurance={newPreferedInsurance} setNewPreferedInsurance={setNewPreferedInsurance} newPreferedSpecialty={newPreferedSpecialty} setNewPreferedSpecialty={setNewPreferedSpecialty} />
                    :
                    <Box aria-label='question-wrapper'
                        sx={{
                            marginTop: "1rem",
                            overflow: "scroll",
                            marginBottom: "5rem",
                            width: "95vw",
                            maxWidth: "80rem",
                            scrollbarWidth: "none",
                        }}
                    >
                        {(questionnaireLoading) ?
                            <Box sx={{marginTop: "10rem"}}>
                                <LoadingIcon textInfo="Loading Quiz..."/>
                            </Box>
                            :
                            questionnaire?.length && questionnaire.length > 0 && questionnaire[questionIndex].SurveyQuestions.map((question, qInd) => {
                                return (
                                    <Question
                                        index={questionIndex}
                                        question={question}
                                        qInd={qInd}
                                        updateAndUploadMapping={updateAndUploadMapping}
                                    />
                                )
                            })
                        }
                    </Box>
                }
                <Box sx={{
                    width: "100%",
                    position: "fixed",
                    bottom: "1rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}>
                    <Button 
                        sx={{
                            backgroundColor: theme.palette.caralystNavy.main,
                            color: "white",
                            transition: "all 0.1s ease-in-out",
                            margin: "0.5rem",
                            width: "8rem",
                            '&:hover': {
                                backgroundColor: theme.palette.caralystNavy.main,
                                filter: "brightness(150%)"
                            }
                        }}
                        onClick={async (e) => await back(e)}
                    >
                        Previous
                    </Button>
                    <Button 
                        sx={{
                            backgroundColor: theme.palette.caralystNavy.main,
                            color: "white",
                            transition: "all 0.1s ease-in-out",
                            margin: "0.5rem",
                            width: "8rem",
                            '&:hover': {
                                backgroundColor: theme.palette.caralystNavy.main,
                                filter: "brightness(150%)"
                            }
                        }}
                        onClick={async (e) => await next(e)}
                    >
                        Next
                    </Button>
                </Box>
                <Box sx={{
                    backgroundColor: theme.palette.caralystNavy.light,
                    position: "fixed",
                    top: "100%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    minWidth: "120rem",
                    width: "120%",
                    // minWidth: "80rem",
                    height: "100%",
                    marginBottom: "5rem",
                    borderRadius: "50% 50%",
                    opacity: "0.4",
                    zIndex: "-1",
                }}/>
            </Box>
             <Dialog
                open={errorMessageOpen}
                onClose={onCloseDialog}
             >
                <DialogTitle>
                    <Typography variant='h4' fontWeight={500}>
                        Sorry!
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant='h6' fontWeight={400}>
                        We had an issue loading the questionnaire. Please try again. If this issue persists, please contact us at eng@caralyst.io!
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCloseDialog} autoFocus>
                        Ok!
                    </Button>
                </DialogActions>
             </Dialog>
        </>
    )
}

export default QuestionnaireSection;