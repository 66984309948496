import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import {
    SET_LOCATION,
    SET_INDEX,
    LOGOUT,
    SET_PREFERRED_SPECIALTY,
    SET_PREFERRED_INSURANCE,
    SET_LOADING,
    SET_LOGGED_IN,
    SET_ORG,
    SET_LAT_LONG,
    SET_REPORTING_A_BUG,
    SET_INSURANCE_OPTIONS,
    SET_SPECIALTY_OPTIONS,
    SET_PERSON_CARD_OPEN,
    SET_TERMS_AND_CONDITIONS_OPEN,
    SET_PATIENT_ID,
    SET_LOGGING_OUT,
    SET_MAP_BOUNDS,
    SET_SKELETON_SCREEN_OPEN,
    SET_POPUP_SURVEY_STATUS,
    SET_LOGGING_IN,
    SET_MAP_CENTER,
    SET_MODALITY_PREFERENCE,
    SET_DISTANCE_PREFERENCE,
} from './constants/user.constants';

import {
    QUESTIONNAIRE_LOAD_REQUEST,
    QUESTIONNAIRE_LOAD_SUCCESS,
    QUESTIONNAIRE_LOAD_FAILURE,
} from './constants/onboarding.constants.js';

import {
    DOCTORS_LOAD_REQUEST,
    DOCTORS_LOAD_SUCCESS,
    DOCTORS_LOAD_FAILURE,
    FAVORITE_DOCTORS_LOAD_REQUEST,
    FAVORITE_DOCTORS_LOAD_SUCCESS,
    FAVORITE_DOCTORS_LOAD_FAILURE,
    PREVIOUS_DOC_REVIEW,
    SET_DOCTOR_DISTANCE,
    RESET_DOCTOR_DISTANCE,
    SET_FILTERED_DOCS,
    SET_DOC_MARKER_FROM_PERSON_CARD,
    SET_EXISTS_MATCHING_PROVIDERS,
    SET_CLINICS,
    SET_TEMP_DOC_ID,
    SET_TEMP_DOC_LOC,
    SET_TEMP_DOC_REASONS_FOR_MATCHING,
    SET_LOADING_DOCS,
} from './constants/doctors.constants.js';

import { 
    SET_POPUP_SURVEY_OPEN,
    SET_LOADING_METADATA,
    SET_MAP_LOADING,
    SET_SNACKBAR_OPEN,
    SET_SNACKBAR_MESSAGE,
} from './constants/shared.constants';

const initialState = {
    patientId: null,
    location: null,
    latLong: null,
    mapCenter: null,
    cardIndex: 0,
    preferredSpecialty: null,
    preferredInsurance: null,
    doctorsLoading: false,
    loadingMetadata: false,
    doctorsLoadingError: false,
    questionnaire: null,
    questionsLoading: false,
    questionsLoadingError: false,
    favoriteDoctorIds: null,
    favoriteDoctorsLoading: false,
    favoriteDoctorsLoadingError: false,
    doctors: null,
    clinics: null,
    filteredDocs: null,
    previousAnswers: null,
    insuranceOptions: null,
    insuranceOptionsLoading: false,
    insuranceOptionsLoadingError: false,
    mapLoading: false,
    specialtyOptions: null,
    loggedIn: false,
    org: null,
    reportingABug: false,
    popupSurveyOpen: false,
    popupSurveyStatus: false,
    popupSurveyTimeoutSet: false,
    previousDocReview: null,
    previousDocReviewSize: null,
    previousTraitRatings: null,
    doctorDistance: null,
    personCardOpen: false,
    termsAndConditionsOpen: false,
    loggingOut: false,
    loggingIn: false,
    loadingAppState: false,
    leftRightBounds: null,
    topBottomBounds: null,
    skeletonScreenOpen: false,
    onFirstTutorial: false,
    onSecondTutorial: false,
    tempDocId: null,
    tempDocReasonsForMatching: null,
    tempDocLocation: null,
    snackbarOpen: false,
    snackbarMessage: null,
    modalityPreference: null,
    distancePreference: null,
};


// SELECTORS
export const selectPatientId = state => state.patientId;
export const selectLocation = state => state.location;
export const selectLatLong = state => state.latLong;
export const selectMapCenter = state => state.mapCenter;
export const selectCardIndex = state => state.cardIndex;

export const selectPreferredSpecialty = state => state.preferredSpecialty;
export const selectPreferredInsurance = state => state.preferredInsurance;

export const selectDoctorsLoadingStatus = state => state.doctorsLoading;
export const selectLoadingMetatdata = state => state.loadingMetadata;

export const selectQuestionnaire = (state) => state.questionnaire;
export const selectQuestionsLoading = state => state.questionsLoading;
export const selectQuestionsLoadingError = state => state.questionsLoadingError;

export const selectFavoriteDoctorIds = state => state.favoriteDoctorIds;
export const selectFavoriteDoctorsLoading = state => state.favoriteDoctorsLoading;
export const selectFavoriteDoctorsLoadingError = state => state.favoriteDoctorsLoadingError;

export const selectDoctors = state => state.doctors;
export const selectClinics = state => state.clinics;
export const selectFilteredDocs = state => state.filteredDocs;

export const selectPreviousAnswers = state => state.previousAnswers;

export const selectInsuranceOptions = (state) => state.insuranceOptions;
export const selectInsuranceOptionsLoading = (state) => state.insuranceOptionsLoading;
export const selectInsuranceOptionsLoadingError = (state) => state.insuranceOptionsLoadingError;

export const selectMapLoading = (state) => state.mapLoading;

export const selectSpecialtyOptions = (state) => state.specialtyOptions;

export const selectLoggedIn = state => state.loggedIn;

export const selectOrg = state => state.org;

export const selectReportingABug = state => state.reportingABug;
export const selectPopupSurveyOpen = state => state.popupSurveyOpen;

export const selectPreviousDocReview = state => state.previousDocReview;
export const selectPreviousDocReviewSize = state => state.previousDocReviewSize;
export const selectPreviousTraitRatings = state => state.previousTraitRatings;

export const selectDoctorDistance = state => state.doctorDistance;

export const selectPersonCardOpen = state => state.personCardOpen;
export const selectDocMarkerFromPersonCard = state => state.docMarkerFromPersonCard;
export const selectExistsMatchingProviders = state => state.existsMatchingProviders;

export const selectTermsAndConditionsOpen = state => state.termsAndConditionsOpen;
export const selectLoggingOut = state => state.loggingOut;
export const selectLoggingIn = state => state.loggingIn;

export const selectLeftRightBounds = state => state.leftRightBounds;
export const selectTopBottomBounds = state => state.topBottomBounds;
export const selectDoctorsLoadingError = state => state.doctorsLoadingError;
export const selectSkeletonScreenOpen = state => state.skeletonScreenOpen;

export const selectPopupSurveyStatus = state => state.popupSurveyStatus;
export const selectPopupSurveyTimeoutSet = state => state.popupSurveyTimeoutSet;

export const selectOnFirstTutorial = state => state.onFirstTutorial;
export const selectOnSecondTutorial = state => state.onSecondTutorial;

export const selectTempDocId = state => state.tempDocId;
export const selectTempDocReasonsForMatching = state => state.tempDocReasonsForMatching;
export const selectTempDocLocation = state => state.tempDocLocation;

export const selectSnackbarOpen = state => state.snackbarOpen;
export const selectSnackbarMessage = state => state.snackbarMessage;

export const selectModalityPreference = state => state.modalityPreference;
export const selectDistancePreference = state => state.distancePreference;

export const loadState = () => {
    try {
        // get stringified version of state from localStorage
        const serializedState = localStorage.getItem('state');
        if (serializedState === null) {
            return undefined;
        }
        // return the JSON version
        const jsonState = JSON.parse(serializedState);
        return jsonState;
    } catch (err) {
        return undefined;
    }
}

export const saveState = (state) => {
    try {
        // stringify the JSON state
        const serializedState = JSON.stringify(state);
        // save string to localStorage
        localStorage.setItem('state', serializedState);
    } catch (err) {
        console.log(err);
    }
}

export const loadInitialState = () => {
    try {
        localStorage.setItem('state', initialState);
    } catch (err) {
        console.log(err);
    }
}

export const isInitialState = () => {
    return JSON.stringify(initialState) === localStorage.getItem('state');
}

/*** WORKFLOW ***/
// 1. Add constant to FILENAME.constants.js
// 2. Create method in FILENAME.actions.js which calls dispatch on the constant
// 3. Create the case in the reducers below

// REDUCERS
const userInfoReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_PATIENT_ID:
            return {
                ...state,
                patientId: action.payload
            };

        case SET_INDEX:
            return {
                ...state,
                cardIndex: action.payload
            };

        case SET_LOCATION:
            return {
                ...state,
                location: action.payload,
                doctorDistance: {},
            };
        case SET_LAT_LONG:
            return {
                ...state,
                latLong: action.payload
            };

        case SET_MAP_CENTER:
            return {
                ...state,
                mapCenter: action.payload
            };

        case SET_MAP_LOADING:
            return {
                ...state,
                mapLoading: action.payload
            };

        case SET_INSURANCE_OPTIONS:
            return {
                ...state,
                insuranceOptions: action.payload
            };
            
        case SET_SPECIALTY_OPTIONS:
            return {
                ...state,
                specialtyOptions: action.payload
            };
        
        case RESET_DOCTOR_DISTANCE: 
            return {
                ...state,
                doctorDistance: null
            };

        case SET_PERSON_CARD_OPEN:
            return {
                ...state,
                personCardOpen: action.payload
            };
    
        case QUESTIONNAIRE_LOAD_REQUEST:
            return { ...state, questionsLoading: true, questionsLoadingError: false };

        case QUESTIONNAIRE_LOAD_SUCCESS:
            const { questionnaire, previousAnswers } = action.payload;
            return {
                ...state,
                questionsLoading: false,
                questionnaire,
                previousAnswers,
                questionsLoadingError: false,
            };

        case QUESTIONNAIRE_LOAD_FAILURE:
            return { ...state, 
                questionsLoading: false,
                questionsLoadingError: true,
                questionnaire: [],
            };
        
        case SET_LOADING_DOCS:
            return {
                ...state,
                doctorsLoading: action.payload
            }

        case DOCTORS_LOAD_REQUEST:
            return { ...state, doctorsLoadingError: false, doctorsLoading: true, doctors: [] };

        case DOCTORS_LOAD_SUCCESS:
            const { doctors } = action.payload;
            return { ...state, doctorsLoading: false, filteredDocs: doctors, doctors};

        case DOCTORS_LOAD_FAILURE:
            return { ...state, doctorsLoading: false, doctorsLoadingError: true };

        case FAVORITE_DOCTORS_LOAD_REQUEST:
            return { ...state, favoriteDoctorIds: [] };

        case FAVORITE_DOCTORS_LOAD_SUCCESS:
            const { favoriteDoctorIds } = action.payload;
            return { ...state, favoriteDoctorIds: favoriteDoctorIds, doctorsLoading: false };

        case FAVORITE_DOCTORS_LOAD_FAILURE:
            return { ...state, favoriteDoctors: [] };

        case SET_PREFERRED_SPECIALTY:
            return {
                ...state,
                preferredSpecialty: action.payload
            }
        case SET_PREFERRED_INSURANCE:
            return {
                ...state,
                preferredInsurance: action.payload
            }

        case SET_LOADING:
            return {
                ...state,
                doctorsLoading: action.payload
            }

        case SET_LOADING_METADATA:  
            return {
                ...state,
                loadingMetadata: action.payload
            }
        
        case SET_LOGGED_IN:
            return {
                ...state,
                loggedIn: action.payload,
            }

        case SET_ORG:
            return {
                ...state,
                org: action.payload,
            }

        case SET_FILTERED_DOCS:
            return {
                ...state,
                filteredDocs: action.payload,
            }

        case SET_CLINICS:
            return {
                ...state,
                clinics: action.payload,
            }

        case SET_REPORTING_A_BUG:
            return {
                ...state,
                reportingABug: action.payload,
            }

        case SET_POPUP_SURVEY_OPEN:
            return {
                ...state,
                popupSurveyOpen: action.payload,
            }

        case SET_POPUP_SURVEY_STATUS:
            return {
                ...state,
                popupSurveyStatus: action.payload,
            }

        case PREVIOUS_DOC_REVIEW:
            return {
                ...state,
                previousDocReview: action.payload.previousDocReviewData,
                previousDocReviewSize: action.payload.previousDocReviewDataCount,
                previousTraitRatings: action.payload.previousTraitRatings,
            }

        case SET_DOCTOR_DISTANCE:
            const { cardIndex, distance, drivingDistance, drivingTime, publicTransitDistance, publicTransitTime, bicyclingDistance, bicyclingTime } = action.payload;
            return {
                ...state,
                doctorDistance: {
                    ...state.doctorDistance,
                    [cardIndex]: {
                        distance,
                        drivingDistance,
                        drivingTime,
                        publicTransitDistance,
                        publicTransitTime,
                        bicyclingDistance,
                        bicyclingTime,
                    },
                }
            }

        case SET_DOC_MARKER_FROM_PERSON_CARD: 
            return {
                ...state,
                docMarkerFromPersonCard: action.payload,
            }

        case SET_EXISTS_MATCHING_PROVIDERS:
            return {
                ...state,
                existsMatchingProviders: action.payload,
            }

        case SET_TERMS_AND_CONDITIONS_OPEN:
            return {
                ...state,
                termsAndConditionsOpen: action.payload,
            }

        case SET_LOGGING_OUT:
            return {
                ...state,
                loggingOut: action.payload,
            }

        case SET_SKELETON_SCREEN_OPEN:
            return {
                skeletonScreenOpen: action.payload,
            }

        case SET_MAP_BOUNDS:
            return {
                ...state,
                leftRightBounds: action.payload.leftRightBounds,
                topBottomBounds: action.payload.topBottomBounds,    
            }

        case SET_LOGGING_IN:
            return {
                ...state,
                loggingIn: action.payload,
            }

        case SET_TEMP_DOC_ID:
            return {
                ...state,
                tempDocId: action.payload,
            }
        
        case SET_TEMP_DOC_LOC:
            return {
                ...state,
                tempDocLocation: action.payload,
            }

        case SET_TEMP_DOC_REASONS_FOR_MATCHING:
            return {
                ...state,
                tempDocReasonsForMatching: action.payload,
            }

        case SET_SNACKBAR_OPEN:
            return {
                ...state,
                snackbarOpen: action.payload,
            }

        case SET_SNACKBAR_MESSAGE:
            return {
                ...state,
                snackbarMessage: action.payload,
            }

        case SET_MODALITY_PREFERENCE:
            return {
                ...state,
                modalityPreference: action.payload,
            }

        case SET_DISTANCE_PREFERENCE:
            return {
                ...state,
                distancePreference: action.payload,
            }

        case LOGOUT:
            return {
                ...initialState,
                loadingMetadata: false,
                loggedIn: false,
                loggingOut: true,
            };

        default:
            return state;
    }
};

const persistedState = loadState() || initialState;
// const persistedState = loadInitialState(); 

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let store = createStore(
    userInfoReducer,
    persistedState,
    composeEnhancers(
        applyMiddleware(thunk),
    )
);

export default store;