import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { setLoggedIn } from "../../redux/actions/user.actions";
import { selectLoggedIn } from "../../redux/store";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme } from "@mui/material";
import { COGNITO_MAP } from "../../redux/constants/shared.constants";

const axios = require("axios");

const ProtectedRoute = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const location = useLocation();

  const loggedIn = useSelector(selectLoggedIn);
  
  const [dialogOpen, setDialogOpen] = useState(false);

  /**
   * Redirects the user to the Cognito login page
   */
  const bootstrapAsync = async () => {
    try {
      const url = await axios.get(COGNITO_MAP["auth_url"])
      window.location.href = url.data;
    } catch (e){
      console.error(e)
    }
  }

  const logIn = (e) => {
    e.preventDefault();
    bootstrapAsync();
  }

  const goBack = (e) => {
    e.preventDefault();
    setDialogOpen(false);
    localStorage.removeItem("review_link");
    localStorage.removeItem("survey_link");
    return window.history.back();
  }

  const handleDialogOpen = () => {
    setDialogOpen(true);
  }

  const handleDialogClose = () => {
    setDialogOpen(false);
  }

  useEffect(() => {
    const asyncInitProtectedRoute = async () => {
      if(!loggedIn && localStorage.getItem('refresh_token')) {
        await dispatch(setLoggedIn(true))
      } else if(!loggedIn) {
        if(location.pathname.includes("/review-card/"))
          localStorage.setItem("review_link", location.pathname)
        if(location.pathname.includes("/popup-survey"))
          localStorage.setItem("survey_link", location.pathname)
        handleDialogOpen();
      }
    }
    asyncInitProtectedRoute();
  }, [loggedIn, dispatch])

  return (
    <>
      {loggedIn ? 
        <Outlet /> 
        : 
        <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
        >
          <DialogTitle sx={{
            fontSize: "1.5rem"
          }}>
              Thank you for using Caralyst Health to find a physician!
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{
              fontSize: "1rem"
            }}>
              In order to access this page, you must be logged in. Please log in or return to the home page.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button 
              onClick={e => goBack(e)} 
              variant="contained"
              color="caralystGreen"
              sx={{
                color: "white",
                "&:hover": {
                  backgroundColor: theme.palette.caralystGreen.main,
                  filter: "brightness(90%)"
                }
            }}>
              Return to Home
            </Button>
            <Button 
              onClick={e => logIn(e)}
              autoFocus
              variant="contained"
              color="caralystGreen"
              sx={{
                color: "white",
                "&:hover": {
                  backgroundColor: theme.palette.caralystGreen.main,
                  filter: "brightness(90%)"
                }
            }}>
              Log In
            </Button>
          </DialogActions>
        </Dialog>
      }
      
    </>
  );
}

export default ProtectedRoute;