export const DOCTORS_LOAD_REQUEST = "LOAD_REQUEST";
export const DOCTORS_LOAD_SUCCESS = "LOAD_SUCCESS";
export const DOCTORS_LOAD_FAILURE = "LOAD_FAILURE";

export const FAVORITE_DOCTORS_LOAD_REQUEST = "FAVORITE_LOAD_REQUEST";
export const FAVORITE_DOCTORS_LOAD_SUCCESS = "FAVORITE_LOAD_SUCCESS";
export const FAVORITE_DOCTORS_LOAD_FAILURE = "FAVORITE_LOAD_FAILURE";

export const FAVORITE_DOCTOR_IDS = "FAVORITE_DOCTOR_IDS";
export const PREVIOUS_DOC_REVIEW = "PREVIOUS_DOC_REVIEW";
export const SET_DOCTOR_DISTANCE = "SET_DOCTOR_DISTANCE";
export const RESET_DOCTOR_DISTANCE = "RESET_DOCTOR_DISTANCE";
export const SET_FILTERED_DOCS = "SET_FILTERED_DOCS";
export const SET_CLINICS = "SET_CLINICS";
export const SET_DOC_MARKER_FROM_PERSON_CARD = "SET_DOC_MARKER_FROM_PERSON_CARD";
export const SET_EXISTS_MATCHING_PROVIDERS = "SET_EXISTS_MATCHING_PROVIDERS";
export const SET_LOADING_DOCS = "SET_LOADING_DOCS";

export const SET_TEMP_DOC_ID = "SET_TEMP_DOC_ID";
export const SET_TEMP_DOC_LOC = "SET_TEMP_DOC_LOC";
export const SET_TEMP_DOC_REASONS_FOR_MATCHING = "SET_TEMP_DOC_REASONS_FOR_MATCHING";
