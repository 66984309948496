import React, { useEffect, useState } from 'react';
import { 
    Divider, 
    Button,
    Box,
    useMediaQuery
} from '@mui/material';
import NavbarAutoComplete from './NavbarAutoComplete';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from "@mui/material/styles";
import { updateUserLatLong, setPreferredInsurance, setPreferredSpecialty, setUserLocation } from '../../../redux/actions/user.actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoggedIn, selectPreferredInsurance, selectPreferredSpecialty, selectLocation, selectPatientId, selectLatLong, selectLeftRightBounds, selectTopBottomBounds, selectTempDocId, selectModalityPreference } from '../../../redux/store';
import { org } from '../../../redux/constants/shared.constants.js';
import { setSnackbarMessage, setSnackbarOpen } from '../../../redux/actions/shared.actions';
import { loadDoctors } from '../../../redux/actions/doctors.actions';

const SearchBar = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const onMobile = !useMediaQuery('(min-width:600px)');

    const loggedIn = useSelector(selectLoggedIn);
    const insurancePreference = useSelector(selectPreferredInsurance);
    const specialtyPreference = useSelector(selectPreferredSpecialty);
    const userLocation = useSelector(selectLocation);
    const patientId = useSelector(selectPatientId);
    const latLong = useSelector(selectLatLong);
    const leftRightBounds = useSelector(selectLeftRightBounds);
    const topBottomBounds = useSelector(selectTopBottomBounds);
    const tempDocId = useSelector(selectTempDocId);
    const modalityPreference = useSelector(selectModalityPreference);

    const [location, setLocation] = useState(userLocation);
    const [preferedSpecialty, setSpecialtyPreference] = useState(specialtyPreference);
    const [preferedInsurance, setInsurancePreference] = useState(insurancePreference);

    useEffect(() => {
        setSpecialtyPreference(specialtyPreference);
        setInsurancePreference(insurancePreference);
        setLocation(userLocation);
    }, [dispatch, userLocation, insurancePreference, specialtyPreference, latLong]);
    
    /***
     * Update the patient preferences when the user clicks the search button
     * 
     * @param {Event} e
     */
    const submitSearch = async (e) => {
        e.preventDefault();
        await dispatch(setSnackbarMessage("Updating your preferences..."));
        await dispatch(setSnackbarOpen(true));
        //If the user has changed a value, it should be updated

        if(location !== userLocation) {
            await dispatch(setUserLocation(location));
            await dispatch(updateUserLatLong(e, location, patientId));
        }

        if(preferedSpecialty !== specialtyPreference) await dispatch(setPreferredSpecialty(preferedSpecialty, org, loggedIn, patientId));
        if(preferedInsurance !== insurancePreference) await dispatch(setPreferredInsurance(preferedInsurance, org, loggedIn, patientId));

        await dispatch(loadDoctors(patientId, leftRightBounds, topBottomBounds, preferedSpecialty, preferedInsurance, onMobile, tempDocId, modalityPreference))
        await dispatch(setSnackbarMessage("Preferences updated!"));
        await dispatch(setSnackbarOpen(true));
    }

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                position: "relative",
                border: "0.5px solid lightgrey",
                borderRadius: "1.5rem",
                minWidth: "50vw",
                maxHeight: "3rem",
                backgroundColor: "#f5f6fa",
                margin: "0.5rem",
            }}>
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    position: "relative",
                }}>
                    <NavbarAutoComplete type="location" location={userLocation} setLocation={setLocation}/>
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <NavbarAutoComplete type="specialty" preferedSpecialty={preferedSpecialty} setSpecialtyPreference={setSpecialtyPreference}/>
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <NavbarAutoComplete type="insurance" variant="disabled" preferedInsurance={preferedInsurance} setInsurancePreference={setInsurancePreference}/>
                    
                </Box>
                <Button 
                    className="navbar-dropdown-submit"
                    onClick={async (e) => await submitSearch(e)}
                    sx={{
                        backgroundColor: theme.palette.caralystRed.main,
                        borderRadius: "1.25rem",
                        width: "3.5rem",
                        height: "3rem",
                        transition: "all 0.1s ease-in-out",
                        '&:hover': {
                            backgroundColor: theme.palette.caralystRed.dark,
                        }
                }}>
                    <SearchIcon sx={{
                        color: "white",
                        fontSize: "2rem",
                    }}/>
                </Button>
            </Box>
    )
}

export default SearchBar;