import React, { useEffect, useState } from 'react';
import SliderQuestion from './SliderQuestion';
import CheckboxQuestion from './CheckboxQuestion';
import RadioButtonQuestion from './RadioButtonQuestion';
import ExpandingMultiselectQuestion from './ExpandingMultiselectQuestion';
import { Box, Typography, useMediaQuery, ToggleButton } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectPreviousAnswers } from '../../redux/store';
import { useTheme } from "@mui/material/styles";
import Collapse from '@mui/material/Collapse';

const Question = ({ index, question, qInd, updateAndUploadMapping }) => {
    const onMobile = !useMediaQuery('(min-width:600px)');
    const answers = question.AnswerChoices;
    const theme = useTheme();

    const [activeExapandButtons, setActiveExpandButtons] = useState([]);

    const toggleExpandButton = (e, category) => {
        e.preventDefault();
        if(activeExapandButtons.includes(category)) {
            const newActiveExpandButtons = activeExapandButtons.filter((curCategory) => curCategory !== category);
            setActiveExpandButtons(newActiveExpandButtons);
        } else {
            setActiveExpandButtons([...activeExapandButtons, category]);
        }
    }

    const renderExpandingMultiSelect = () => {
        const categories = question.question_render.categories;
        return (
            <Box sx={{
                display: "grid",
                gridTemplateColumns: onMobile ? 'repeat(1, 1fr)' : 'repeat(2, 1fr)', /* Two columns */
                gap: '0.5rem', /* Gap between grid items */
                marginTop: "1rem",
                maxWidth: "52rem",
            }}>
                {categories.map((category) => {
                    const curAnswers = answers.filter((answer) => answer?.render?.category === category);
                    const selected = curAnswers.find((answer) => answer.selected === true) || activeExapandButtons.includes(category);

                    return (
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}>
                            <ToggleButton 
                                sx={{
                                    margin: "0.5rem",
                                    backgroundColor: theme.palette.caralystRed.dark,
                                    width: '40vw',
                                    minWidth: "18rem",
                                    maxWidth: "25rem",
                                    color: "white",
                                    transition: "background-color 0.15s ease-in-out",
                                    textTransform: "none",
                                    fontSize: "1.2em",
                                    '&:hover': {
                                        backgroundColor: theme.palette.caralystRed.main,
                                        color: "white",
                                    },
                                    '&.Mui-selected': {
                                        backgroundColor: theme.palette.caralystRed.main,
                                        color: "white",
                                    },
                                    '&.Mui-selected:hover': {
                                        backgroundColor: theme.palette.caralystRed.main,
                                        color: "white",
                                    }
                                }}
                                key={category}
                                selected={selected}
                                onClick={e => toggleExpandButton(e, category)}
                            >
                                {category}
                            </ToggleButton>
                            <Collapse in={selected} {...(selected ? { timeout: 250 } : {})}>
                                <Box sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    flexWrap: "wrap",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "auto",
                                }}>
                                        {curAnswers?.map((answer) => {
                                            return (
                                                makeTraitCards(answer)
                                            );
                                        })}
                                </Box>
                            </Collapse>
                        </Box>
                    )})
                }
            </Box>
        );
    }

    // Update the question on index change
    const [updateTrigger, setUpdateTrigger] = useState(false); // State for triggering update
    const previousAnswers = useSelector(selectPreviousAnswers);

    const changeSelectedAnswer = (answerId, value) => {
        const answer = question.AnswerChoices.find((answer) => answer.id === answerId);
        if(!answer) return;
        setInitialPreviousAnswerState();
        switch(answer.type) {
            case("SLIDER"):
                answer.selected = value;
                previousAnswers[answerId] = value; 
                break;
            case("RADIO"):
                const currentSelected = question.AnswerChoices.find((answer) => answer.selected === true);

                if(!currentSelected || currentSelected?.id !== answerId) {
                    answer.selected = true;
                    previousAnswers[answerId] = true;
                } 
                if(currentSelected) {
                    currentSelected.selected = false;
                    previousAnswers[currentSelected.id] = false;
                }

                question.AnswerChoices?.forEach((answer) => {
                    if(answer.id !== answerId) {
                        previousAnswers[answer.id] = false;
                        answer.selected = false;
                    }
                });
                break;
            case("MULTISELECT"):
                const currentSelectedAnswers = question.AnswerChoices.filter((curAnswer) => curAnswer.selected === true);
                const isAlreadySelected = currentSelectedAnswers.find((curAnswer) => curAnswer.id === answerId);
                if(isAlreadySelected) {
                    answer.selected = false;
                    previousAnswers[answerId] = false;
                } else {
                    answer.selected = true;
                    previousAnswers[answerId] = true;
                }
                break;
            case("EXPANDING_MULTISELECT"):
                const expandedCurrentSelectedAnswers = question.AnswerChoices.filter((curAnswer) => curAnswer.selected === true);
                const expandedIsAlreadySelected = expandedCurrentSelectedAnswers.find((curAnswer) => curAnswer.id === answerId);
                if(expandedIsAlreadySelected) {
                    answer.selected = false;
                    previousAnswers[answerId] = false;
                } else {
                    answer.selected = true;
                    previousAnswers[answerId] = true;
                }
                break;
            default:
                break;
        }

        setUpdateTrigger(!updateTrigger);
    }  

    useEffect(() => {}, [index, changeSelectedAnswer])

    const setInitialPreviousAnswerState = () => {
        const existsSelectedAnswer = answers.find((answer) => answer.selected === true);
        if(!existsSelectedAnswer) 
            question.AnswerChoices.forEach((answer) => {
                answer.selected = false;
                previousAnswers[answer.id] = false;
            });
    }

    const makeTraitCards = (answer) => {
        //Render based on answer type
        switch(answer.type) {
            case("EXPANDING_SLIDER"):
            return (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <SliderQuestion 
                            index={index} 
                            qInd={qInd}
                            answer={answer}
                            format={answer.type} 
                            changeSelectedAnswer={changeSelectedAnswer}
                            updateAndUploadMapping={updateAndUploadMapping}
                        />
                </Box> 
                )
            case("SLIDER"):
                return (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <SliderQuestion 
                            index={index} 
                            answer={answer}
                            format={answer.type} 
                            changeSelectedAnswer={changeSelectedAnswer}
                            updateAndUploadMapping={updateAndUploadMapping}
                        />
                    </Box> 
                )
            case ("MULTISELECT"):
                return (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <CheckboxQuestion 
                            index={index} 
                            answer={answer}
                            changeSelectedAnswer={changeSelectedAnswer}
                            updateAndUploadMapping={updateAndUploadMapping}
                        />
                    </Box>
                )
            case ("EXPANDING_MULTISELECT"):
                return (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <ExpandingMultiselectQuestion 
                            index={index} 
                            answer={answer}
                            changeSelectedAnswer={changeSelectedAnswer}
                            updateAndUploadMapping={updateAndUploadMapping}
                        />
                    </Box>
                )
            default:
                return (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <RadioButtonQuestion 
                            index={index} 
                            answer={answer}
                            changeSelectedAnswer={changeSelectedAnswer}
                            updateAndUploadMapping={updateAndUploadMapping}
                        />
                    </Box>
                )
               
        }
    }

    return (
        question &&
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
            }}>
                <Box>
                    <Typography
                        sx={{
                            padding: "2rem 2rem 0 2rem",
                            fontSize: !onMobile ? "1.8em" : "1.75em",
                            fontWeight: "bold",
                            textAlign: "center",
                            width: "100%",
                        }}
                    >
                        {question.text}
                    </Typography>
                </Box>
                {question?.question_render?.categories ?
                    renderExpandingMultiSelect()
                    :    
                    <Box
                        sx={answers?.length >= 8 ? {
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            flexWrap: "wrap",
                            height: "100%",
                            padding: "2rem",
                        } : {
                            padding: "2rem"
                        }}
                    >
                        {answers.map(answer => {
                            return (
                                makeTraitCards(answer)
                            );
                        })}
                    </Box>
                }
                
            </Box>
    )
}

export default Question;