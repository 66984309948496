import axios from 'axios';
import { COGNITO_MAP } from '../redux/constants/shared.constants';
const axiosApiInstance = axios.create();

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
    async config => {
        const accessToken = localStorage.getItem('access_token')
        if(config.url.includes('https://maps.googleapis.com'))
            return config;
        config.headers['X-Authorization-Token'] = accessToken
        return config;
    },
    error => {
        console.log(error)
        Promise.reject(error)
    });

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use((response) => {
    return response
}, async function (error) {
    console.log(error.response)
    const originalRequest = error?.config;
    //Token expired
    if ((error?.response?.status === 403 || error?.response?.status === 401) && !originalRequest?._retry) {
        console.log('==========================')
        console.log('Dealing with expired token...')
        console.log('==========================')
        originalRequest._retry = true;
        const access_token = await refreshAccessToken();
        //if access_token is null, redirect to cognito logour url, then to our client
        axios.defaults.headers.common['X-Authorization-Token'] = access_token;
        return axiosApiInstance(originalRequest);
    }

    return Promise.reject(error);
});

const refreshAccessToken = async () => {
    const refreshToken = localStorage.getItem('refresh_token')
    try {
        const tokens = await axios.post(COGNITO_MAP["refresh"], {
            refresh_token: refreshToken
        })
        const access_token = tokens.data.access_token
        localStorage.setItem('access_token', access_token)
        console.log('==========================')
        console.log('Refreshed access token')
        console.log('==========================')
        return access_token
    } catch(e) {
        //if refresh token is expired, redirect to cognito logout url, then to our client
        console.log(e);
        handleLogoutUser();
        return null
    }
}

const handleLogoutUser = () => {
    if(localStorage.getItem("expired_tokens") === null) {
        localStorage.setItem("expired_tokens", JSON.stringify(true));
        window.location.href = "/";
    }
}

export default axiosApiInstance