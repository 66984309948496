import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from "react-router-dom";

import { useDispatch, useSelector } from 'react-redux';
import { org } from './redux/constants/shared.constants';
import { getCurrentUserState, getInsuranceOptions, getLastSeen, getSpecialtyOptions, initBounds, setLoggingIn, setLoggingOut, setOrg, updateLastSeen, updateUserLatLong } from './redux/actions/user.actions';
import store, { saveState, selectFilteredDocs, selectLatLong, selectLeftRightBounds, selectLoadingMetatdata, selectLocation, selectLoggedIn, selectLoggingIn, selectLoggingOut, selectModalityPreference, selectPatientId, selectPopupSurveyOpen, selectPopupSurveyStatus, selectPreferredInsurance, selectPreferredSpecialty, selectSnackbarMessage, selectSnackbarOpen, selectTopBottomBounds } from './redux/store.js'

import throttle from 'lodash/throttle';

import ReactGA from 'react-ga4';

import PopupSurvey from './components/Home/PopupSurvey';
import ReportABug from './components/Home/ReportABug';
import HomePage from './components/Home/HomePage';
import QuestionnaireSection from './components/Questionnaire/QuestionnaireSection';
import Navbar from './components/Home/Navbar/Navbar';
import ReviewCard from './components/Review/ReviewCard';
import ProtectedRoute from './components/Auth/ProtectedRoute';
import ErrorDialog from './components/Auth/ErrorDialog';

import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, useMediaQuery, Snackbar } from '@mui/material';
import { setLoadingMetadata, setSnackbarOpen } from './redux/actions/shared.actions';
import WholeScreenSkeleton from './components/Misc/WholeScreenSkeleton';
import MobileHome from './components/Home/MobileHome';
import { setLoadingDocs, setTempDocId, setTempDocLoc, setTempDocReasonsForMatching } from './redux/actions/doctors.actions';

const AppContainer = () => {
    const dispatch = useDispatch();
    const patientId = useSelector(selectPatientId);
    const popupSurveyOpen = useSelector(selectPopupSurveyOpen);
    const popupSurveyStatus = useSelector(selectPopupSurveyStatus);
    const loggedIn = useSelector(selectLoggedIn);
    const loggingIn = useSelector(selectLoggingIn);
    const loadingMetadata = useSelector(selectLoadingMetatdata);
    const loggingOut = useSelector(selectLoggingOut);
    const onMobile = !useMediaQuery('(min-width:600px)');
    const leftRightBounds = useSelector(selectLeftRightBounds);
    const topBottomBounds = useSelector(selectTopBottomBounds);
    const latLong = useSelector(selectLatLong);
    const userLocation = useSelector(selectLocation);
    const snackbarOpen = useSelector(selectSnackbarOpen);
    const snackbarMessage = useSelector(selectSnackbarMessage);
    
    const loadDocParamsFromUrl = async () => {
        //set store values from query params if exist
        const urlParams = new URLSearchParams(window.location.search);
        const docId = urlParams.get('docId');
        const docLoc = urlParams.get('docLoc');
        const reasonsForMatching = urlParams.get('reasonsForMatching');

        if(docId) {
            await dispatch(setTempDocId(docId));
            return docId;
        }
        if(docLoc) {
            await dispatch(setTempDocLoc(docLoc));
        }
        if(reasonsForMatching && 
            reasonsForMatching !== "undefined" && 
            isValidJson(reasonsForMatching)
        ) {
            await dispatch(setTempDocReasonsForMatching(reasonsForMatching));
        }
    }

    const isValidJson = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    /***
     * Sets the metadata for the user that should happen each time the page loads/reloads
     */
    useEffect(() => {
        const setMetadata = async () => {
            await dispatch(setLoadingMetadata(true));

            await loadDocParamsFromUrl();
            
            const id = await dispatch(getCurrentUserState(org, patientId, popupSurveyStatus));

            await dispatch(getSpecialtyOptions(org));
            await dispatch(getInsuranceOptions(org));
            await dispatch(setLoggingOut(false));
            await dispatch(setLoggingIn(false));
            await dispatch(setLoadingDocs(false));

            if(id) {
                await dispatch(getLastSeen(id, loggedIn));
                await dispatch(updateLastSeen(id));
                await dispatch(initBounds(latLong, userLocation, id, leftRightBounds, topBottomBounds));
            }
            
            await store.subscribe(
                throttle(() => {
                  saveState(store.getState());
                }, 1000)
            );
    
            ReactGA.send({ hitType: "pageview", page: window.location, title: "Home Page View" });

            await dispatch(setLoadingMetadata(false));
        }
        setMetadata()
    }, []);

    useEffect(() => {}, [dispatch, loggingOut, loggingIn, snackbarOpen, snackbarMessage]);
    const measurementId = "G-90K8S7490Z"; 
    ReactGA.initialize(measurementId);

    return (
        <>
            {loadingMetadata || loggingOut || loggingIn ?
                <WholeScreenSkeleton />
                :
                <div className="l-main-container">
                    <Navbar />
                    <Routes>
                        <Route element={<ErrorDialog />}>
                        <Route path="/" element={<Navigate to="/home" />} />
                        <Route path="/home" element={onMobile ? <MobileHome /> : <HomePage />} />
                        <Route path="/home/*" element={onMobile ? <MobileHome /> : <HomePage />} />
                        <Route path="/onboard/questionnaire/:index" element={<QuestionnaireSection />} />

                        <Route element={<ProtectedRoute />}>
                            <Route path="/review-card/:docId" element={<ReviewCard />} />
                            <Route path="/popup-survey" element={<PopupSurvey />} />
                        </Route>
                        <Route path="*" element={<Navigate to="/home" />} />
                        </Route>
                        
                    </Routes>
                </div>
            }
            <Modal open={popupSurveyOpen}>
                <PopupSurvey />
            </Modal>
            <ReportABug />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={snackbarOpen}
                autoHideDuration={onMobile ? 2000 : 1500}
                message={snackbarMessage}
                onClose={async () => await dispatch(setSnackbarOpen(false))}
            />
        </>
    );
};

export default AppContainer;