import k from './keys';

const chinese = {
  [k.INTRO_HEADER]: `叭只両兮市圬吾伏刪止入反入老吞少仰吳匡甩耳刪丐而旭力尹亙即宇伙卯判汁別`,
  [k.INTRO_RIGHT_LINE1]: `去先印呈兆丁令大仿吋扛伉凹禾弛母羽今手仕未穴叼升舛竹兀存勿市`,
  [k.INTRO_RIGHT_LINE2]: `克叮次但戍劣竹申曱刁佝兄于奴母疋小仇分吒白曳回吭伉圯兑划皿判`,
  [k.INTRO_RIGHT_LINE3]: `圭叼外吁佝佃欠尤在企名佑肋疋伐付卜刀吭巨月伙千冷氏乙圯勿玄印`,
  [k.COMPANY_NAME]: `忙斤自丘劣幼屹妃乏扛也伺此圯兮石汝`,
  [k.LOGIN]: `水出用呃臣`,
  [k.SUPPORT]: `切冷女匈兮乎犬`,
  [k.ABOUT]: `丫五亥士佝`,
  [k.ONBOARD_GREETING_TEXT_PRECEDING_NAME]: `匆心占仃叫歹本甘井吵寸兕`,
  [k.ONE_RIGHT_ARROW]: `木夙任`,
  [k.ONBOARD_IDENTITY_CHOOSE_PROMPTS]: `打奴包卞朮仇吧戌功另伉刪北丹并努吆牟克爪幼向羊合加佇刁缶廿人仆夷仄已火田存失水旬叫卜力冊圳米州卯乙夙`,
  [k.ONBOARD_NEXT_BUTTON]: `伽君匹低`,
  [k.RIGHT_ARROW_ONLY]: `乏`,
  [k.REGISTRATION_LABEL_FIRST_NAME]: `米亡有汐犬吵`,
  [k.REGISTRATION_LABEL_LAST_NAME]: `民安穴字扣`,
  [k.REGISTRATION_LABEL_USERNAME]: `皮妃如叩汝且牝犯缶`,
  [k.REGISTRATION_LABEL_EMAIL]: `工乍伊今子耒`,
  [k.REGISTRATION_LABEL_PASSWORD]: `布充打肌功州卯乙圳`,
  [k.REGISTRATION_BUTTON]: `宅宇仕先朮佃去式`
};

export default chinese
