import k from './keys';

const english = {
  [k.INTRO_HEADER]: "Begin a new chapter in your care experience.",
  [k.INTRO_RIGHT_LINE1]: "Are you looking for a new primary care doctor?",
  [k.INTRO_RIGHT_LINE2]: "The right physician makes all the difference.",
  [k.INTRO_RIGHT_LINE3]: "Welcome to Caralyst.",
  [k.COMPANY_NAME]: "Caralyst",
  [k.NAVBAR_LOGIN]: "Login",
  [k.NAVBAR_SUPPORT]: "Support",
  [k.NAVBAR_ABOUT]: "About",
  [k.ONBOARD_GREETING_TEXT_PRECEDING_NAME]: "Hello,",
  [k.ONE_RIGHT_ARROW]: "1 →",
  [k.ONBOARD_IDENTITY_CHOOSE_PROMPTS]: "Please pick any qualities you want to see in your doctor.",
  [k.ONBOARD_NEXT_BUTTON]: "Next",
  [k.RIGHT_ARROW_ONLY]: "→",
  [k.REGISTRATION_LABEL_FIRST_NAME]: "First:",
  [k.REGISTRATION_LABEL_LAST_NAME]: "Last:",
  [k.REGISTRATION_LABEL_USERNAME]: "Username:",
  [k.REGISTRATION_LABEL_EMAIL]: "Email:",
  [k.REGISTRATION_LABEL_PASSWORD]: "Password:",
  [k.REGISTRATION_BUTTON]: "Register",
  [k.LOGIN_BUTTON]: "Login",
};

export default english
