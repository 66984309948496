import React from 'react';

import { Box, Button, useMediaQuery } from '@mui/material';
import { selectLoggedIn } from '../../redux/store';
import { useSelector } from 'react-redux';

const SkeletonScreen = () => {
    const onMobile = !useMediaQuery('(min-width:600px)')
    const cards = [0,0,0,0,0,0]
    const mobileCards = [0,0,0,0,0,0]
    const loggedIn = useSelector(selectLoggedIn);

    return (
        <>
            {!onMobile ?
                <Box className="home-main-container"
                    sx={{
                        width: "515px",
                        height: "calc(100% - 4rem)",
                        position: "absolute",
                        marginTop: "4rem",
                        zIndex: (theme) => theme.zIndex.drawer ,
                        backgroundColor: "#F6F8FB",

                        color: "#efdab9 !important",
                        display: "flex",
                        flexDirection: "column",
                        fontWeight: "300 !important",
                    }}
                >
                        <Box 
                            sx={{
                                paddingTop: "4rem",
                            }}
                        >
                            <Box aria-label='fake-custom-drawer' sx={{
                                width: "100%",
                                borderRight: "1px solid rgba(0,0,0,0.1)",
                                height: "calc(100vh - 4rem)",
                                marginTop: "-4rem",
                                overflow: "hidden"
                            }}>   
                                <Box aria-label='fake-custom-drawer-header'>
                                    {loggedIn && <Button variant='outlined' sx={{
                                        border: "1px solid rgba(0,0,0,0.25)",
                                        minHeight: "2.3rem",
                                        marginTop: "0.5rem",
                                        marginBottom: "0.5rem",
                                        marginLeft: "0.5rem",
                                        marginRight: "1rem",
                                        pointerEvents: "none",
                                    }}>
                                    </Button>}
                                    <Button variant='outlined' sx={{
                                        border: "1px solid rgba(0,0,0,0.25)",
                                        minHeight: "2.25rem",
                                        marginTop: !loggedIn ? "0.5rem" : "0rem",
                                        marginBottom: !loggedIn ? "0.5rem" : "",
                                        marginRight: "0.5rem",
                                        width: "8.4rem",
                                        pointerEvents: "none",
                                    }}></Button>
                                    <Button variant='outlined' sx={{
                                        border: "1px solid rgba(0,0,0,0.25)",
                                        minHeight: "2.25rem",
                                        marginTop: !loggedIn ? "0.5rem" : "0rem",
                                        marginBottom: !loggedIn ? "0.5rem" : "",
                                        marginRight: "0.45rem",
                                        width: "5.6rem",
                                        pointerEvents: "none",
                                    }}></Button>
                                    <Button variant='outlined' sx={{
                                        border: "1px solid rgba(0,0,0,0.25)",
                                        minHeight: "2.25rem",
                                        marginTop: !loggedIn ? "0.5rem" : "0rem",
                                        marginBottom: !loggedIn ? "0.5rem" : "",
                                        width: "5.6rem",
                                        pointerEvents: "none",
                                    }}></Button>
                                </Box>
                                <Box aria-label='fake-mini-person-grid' sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                }}>
                                    {cards.map((_, index) => {
                                        return (
                                            <Box 
                                                key={index}
                                                sx={{
                                                    border: "1px solid rgba(0,0,0,0.25)",
                                                    height: "22rem",
                                                    width: "15rem",
                                                    marginLeft: "0.5rem",
                                                    marginRight: "0.5rem",
                                                    marginTop: "0.5rem",
                                                    borderRadius: "0.25rem"
                                            }}>
                                                <Box aria-label='fake-pfp' sx={{
                                                    height: "12rem",
                                                    width: "14rem",
                                                    border: "1px solid rgba(0,0,0,0.25)",
                                                    marginTop: "0.6rem",
                                                    marginLeft: "0.5rem",
                                                    borderRadius: "0.5rem",
                                                    backgroundColor: "rgba(0,0,0,0.1)",
                                                    "@keyframes logo-fade": {
                                                        "0%": {
                                                            opacity: "1"
                                                        },
                                                        "50%": {
                                                            opacity: "0.5"
                                                        },
                                                        "100%": {
                                                            opacity: "1"
                                                        },
                                                    },
                                                    animation: "logo-fade 1.6s infinite",
                                                }}/>
                                                <Box aria-label='fake-text' sx={{
                                                    height: "1.1rem",
                                                    width: "12rem",
                                                    marginTop: "0.5rem",
                                                    marginLeft: "0.5rem",
                                                    borderRadius: "0.25rem",
                                                    border: "1px solid rgba(0,0,0,0.25)",
                                                    backgroundColor: "rgba(0,0,0,0.1)",
                                                    "@keyframes logo-fade": {
                                                        "0%": {
                                                            opacity: "1"
                                                        },
                                                        "50%": {
                                                            opacity: "0.4"
                                                        },
                                                        "100%": {
                                                            opacity: "1"
                                                        },
                                                    },
                                                    animation: "logo-fade 1.7s infinite",
                                                }}/>
                                                <Box aria-label='fake-text' sx={{
                                                    height: "1.1rem",
                                                    width: "6rem",
                                                    marginTop: "0.3rem",
                                                    marginLeft: "0.5rem",
                                                    borderRadius: "0.25rem",
                                                    border: "1px solid rgba(0,0,0,0.25)",
                                                    backgroundColor: "rgba(0,0,0,0.1)",
                                                    "@keyframes logo-fade": {
                                                        "0%": {
                                                            opacity: "1"
                                                        },
                                                        "50%": {
                                                            opacity: "0.4"
                                                        },
                                                        "100%": {
                                                            opacity: "1"
                                                        },
                                                    },
                                                    animation: "logo-fade 1.8s infinite",
                                                }}/>
                                                <Box aria-label='fake-text' sx={{
                                                    height: "1.1rem",
                                                    width: "8rem",
                                                    marginTop: "0.3rem",
                                                    marginLeft: "0.5rem",
                                                    borderRadius: "0.25rem",
                                                    border: "1px solid rgba(0,0,0,0.25)",
                                                    backgroundColor: "rgba(0,0,0,0.1)",
                                                    "@keyframes logo-fade": {
                                                        "0%": {
                                                            opacity: "1"
                                                        },
                                                        "50%": {
                                                            opacity: "0.4"
                                                        },
                                                        "100%": {
                                                            opacity: "1"
                                                        },
                                                    },
                                                    animation: "logo-fade 1.9s infinite",
                                                }}/>
                                                <Box aria-label='fake-text' sx={{
                                                    height: "1.1rem",
                                                    width: "12rem",
                                                    marginTop: "0.3rem",
                                                    marginLeft: "0.5rem",
                                                    borderRadius: "0.25rem",
                                                    border: "1px solid rgba(0,0,0,0.25)",
                                                    backgroundColor: "rgba(0,0,0,0.1)",
                                                    "@keyframes logo-fade": {
                                                        "0%": {
                                                            opacity: "1"
                                                        },
                                                        "50%": {
                                                            opacity: "0.4"
                                                        },
                                                        "100%": {
                                                            opacity: "1"
                                                        },
                                                    },
                                                    animation: "logo-fade 2s infinite",
                                                }}/>
                                                <Box aria-label='fake-text' sx={{
                                                    height: "1.1rem",
                                                    width: "11rem",
                                                    marginTop: "0.3rem",
                                                    marginLeft: "0.5rem",
                                                    borderRadius: "0.25rem",
                                                    border: "1px solid rgba(0,0,0,0.25)",
                                                    backgroundColor: "rgba(0,0,0,0.1)",
                                                    "@keyframes logo-fade": {
                                                        "0%": {
                                                            opacity: "1"
                                                        },
                                                        "50%": {
                                                            opacity: "0.4"
                                                        },
                                                        "100%": {
                                                            opacity: "1"
                                                        },
                                                    },
                                                    animation: "logo-fade 2.1s infinite",
                                                }}/>
                                                <Box aria-label='fake-text' sx={{
                                                    height: "1.1rem",
                                                    width: "11.5rem",
                                                    marginTop: "0.3rem",
                                                    marginLeft: "0.5rem",
                                                    borderRadius: "0.25rem",
                                                    border: "1px solid rgba(0,0,0,0.25)",
                                                    backgroundColor: "rgba(0,0,0,0.1)",
                                                    "@keyframes logo-fade": {
                                                        "0%": {
                                                            opacity: "1"
                                                        },
                                                        "50%": {
                                                            opacity: "0.4"
                                                        },
                                                        "100%": {
                                                            opacity: "1"
                                                        },
                                                    },
                                                    animation: "logo-fade 2.2s infinite",
                                                }}/>
                                            </Box>
                                        )
                                    })}
                                </Box>
                            </Box>
                        </Box>
                </Box>
                :
                <Box aria-label='fake-mobile-home' sx={{
                    position: "absolute",
                    left: "0",
                    top: "5.5rem",
                    zIndex: (theme) => theme.zIndex.drawer,
                    maxWidth: "100%",
                    overflow: "hidden",
                    backgroundColor: "#F6F8FB",
                }}>
                    <Box aria-label='fake-custom-drawer' sx={{
                        width: "99vw",
                        height: "calc(100vh - 8.75rem)",
                        marginTop: "0.25rem",
                        marginLeft: "0.75rem",
                        overflow: "hidden"
                    }}>   
                        <Box aria-label='fake-mini-person-grid' sx={{
                            display: "flex",
                            flexDirection: "column",
                        }}>
                            {mobileCards.map((_, index) => {
                                return (
                                    <Box 
                                        key={index}
                                        sx={{
                                            width: "96%",
                                            height: "9rem",
                                            marginTop: "0.25rem",
                                            border: "1px solid rgba(0,0,0,0.25)",
                                            borderRadius: "0.25rem",
                                            display: "flex",
                                            flexDirection: "row",
                                    }}>
                                        <Box aria-label='fake-pfp' sx={{
                                            height: "7.5rem",
                                            width: "6.5rem",
                                            border: "1px solid rgba(0,0,0,0.25)",
                                            marginTop: "0.5rem",
                                            marginLeft: "0.5rem",
                                            borderRadius: "0.5rem",
                                            backgroundColor: "rgba(0,0,0,0.1)",
                                            "@keyframes logo-fade": {
                                                "0%": {
                                                    opacity: "1"
                                                },
                                                "50%": {
                                                    opacity: "0.5"
                                                },
                                                "100%": {
                                                    opacity: "1"
                                                },
                                            },
                                            animation: "logo-fade 1.6s infinite",
                                        }}/>
                                        <Box aria-label='fake-text'>
                                            <Box aria-label='fake-text' sx={{
                                                height: "1.1rem",
                                                width: "12rem",
                                                marginTop: "0.5rem",
                                                marginLeft: "0.5rem",
                                                borderRadius: "0.25rem",
                                                border: "1px solid rgba(0,0,0,0.25)",
                                                backgroundColor: "rgba(0,0,0,0.1)",
                                                "@keyframes logo-fade": {
                                                    "0%": {
                                                        opacity: "1"
                                                    },
                                                    "50%": {
                                                        opacity: "0.4"
                                                    },
                                                    "100%": {
                                                        opacity: "1"
                                                    },
                                                },
                                                animation: "logo-fade 1.7s infinite",
                                            }}/>
                                            <Box aria-label='fake-text' sx={{
                                                height: "1.1rem",
                                                width: "6rem",
                                                marginTop: "0.3rem",
                                                marginLeft: "0.5rem",
                                                borderRadius: "0.25rem",
                                                border: "1px solid rgba(0,0,0,0.25)",
                                                backgroundColor: "rgba(0,0,0,0.1)",
                                                "@keyframes logo-fade": {
                                                    "0%": {
                                                        opacity: "1"
                                                    },
                                                    "50%": {
                                                        opacity: "0.4"
                                                    },
                                                    "100%": {
                                                        opacity: "1"
                                                    },
                                                },
                                                animation: "logo-fade 1.8s infinite",
                                            }}/>
                                            <Box aria-label='fake-text' sx={{
                                                height: "1.1rem",
                                                width: "8rem",
                                                marginTop: "0.3rem",
                                                marginLeft: "0.5rem",
                                                borderRadius: "0.25rem",
                                                border: "1px solid rgba(0,0,0,0.25)",
                                                backgroundColor: "rgba(0,0,0,0.1)",
                                                "@keyframes logo-fade": {
                                                    "0%": {
                                                        opacity: "1"
                                                    },
                                                    "50%": {
                                                        opacity: "0.4"
                                                    },
                                                    "100%": {
                                                        opacity: "1"
                                                    },
                                                },
                                                animation: "logo-fade 1.9s infinite",
                                            }}/>
                                            <Box aria-label='fake-text' sx={{
                                                height: "1.1rem",
                                                width: "12rem",
                                                marginTop: "0.3rem",
                                                marginLeft: "0.5rem",
                                                borderRadius: "0.25rem",
                                                border: "1px solid rgba(0,0,0,0.25)",
                                                backgroundColor: "rgba(0,0,0,0.1)",
                                                "@keyframes logo-fade": {
                                                    "0%": {
                                                        opacity: "1"
                                                    },
                                                    "50%": {
                                                        opacity: "0.4"
                                                    },
                                                    "100%": {
                                                        opacity: "1"
                                                    },
                                                },
                                                animation: "logo-fade 2s infinite",
                                            }}/>
                                            <Box aria-label='fake-text' sx={{
                                                height: "1.1rem",
                                                width: "11rem",
                                                marginTop: "0.3rem",
                                                marginLeft: "0.5rem",
                                                borderRadius: "0.25rem",
                                                border: "1px solid rgba(0,0,0,0.25)",
                                                backgroundColor: "rgba(0,0,0,0.1)",
                                                "@keyframes logo-fade": {
                                                    "0%": {
                                                        opacity: "1"
                                                    },
                                                    "50%": {
                                                        opacity: "0.4"
                                                    },
                                                    "100%": {
                                                        opacity: "1"
                                                    },
                                                },
                                                animation: "logo-fade 2.1s infinite",
                                            }}/>
                                        </Box>
                                    </Box>
                                )
                            })}
                        </Box>
                    </Box>
                </Box>
        }
        </>

    );
}


export default SkeletonScreen;
