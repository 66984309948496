import unauthAxiosApiInstance from '../../utils/unauthAxiosConfig';
import {
    QUESTIONNAIRE_LOAD_REQUEST,
    QUESTIONNAIRE_LOAD_SUCCESS,
    QUESTIONNAIRE_LOAD_FAILURE,
    SET_QUESTIONS_LOADING,
} from '../constants/onboarding.constants';
import { ROOT } from '../constants/shared.constants'
import { org } from '../constants/shared.constants';

export const setQuestionnaireLoading = (loading) => ({
    type: SET_QUESTIONS_LOADING,
    payload: loading
});

/**
 * Load the questionnaire for this specific organization and patient
 * @param {String} org - The organization of the patient
 * @param {String} patientId - The patient's id
 * @returns 
 */
export const loadQuestionnaire = (patientId) => async (dispatch) => {
    await dispatch({
        type: QUESTIONNAIRE_LOAD_REQUEST,
    });
    let questionnaire;
    let previousAnswers;
    try {
        const res = await unauthAxiosApiInstance.get(ROOT + `/onboard-patient?patientId=${patientId}&org=${org}`);
        questionnaire = res.data.questionnaire;
        previousAnswers = res.data.questionnaire_progress;
    } catch (e) {
        console.log("Failed to load questions")
        console.log(e);
    }
    
    if (questionnaire && questionnaire.length > 0) {
        await dispatch(setQuestionsFromDB(questionnaire, previousAnswers));
    } else {
        await dispatch({
            type: QUESTIONNAIRE_LOAD_FAILURE,
            payload: {
                error: "Failed to load questions.",
            }
        });
        await dispatch(setQuestionnaireLoading(false))
    }
    return previousAnswers;
};

/**
 * Set the questionnaire in redux from the data received from the DB
 * @param {Object} questionnaire - The questionnaire pages, questions, traits, and answers
 * @param {Object} previousAnswers - The previous answers to the questionnaire
 * TODO: sort on server side
 * @returns 
 */
export const setQuestionsFromDB = (questionnaire, previousAnswers) => async (dispatch) => {
    if (!previousAnswers || previousAnswers.questionnaire_progress === null) previousAnswers = {};
    else previousAnswers = previousAnswers.questionnaire_progress;
    // We need to differentiate based on page and question to determine which radio button/checkbox/slider is clicked respectively
    questionnaire.sort((a, b) => a.index - b.index);
    questionnaire.forEach((page) => {
        // Create a new page in question traits dict
        page.SurveyQuestions.sort((a, b) => a.index - b.index);
        page.SurveyQuestions.forEach((question) => {
            // Default state, add to questionTraitsDict if there are existing trait ratings in the db
            question.AnswerChoices.sort((a, b) => a.index - b.index);
            question.AnswerChoices.forEach((answer) => {
                answer.selected = previousAnswers[answer.id];
            });
        });
    });

    await dispatch({
        type: QUESTIONNAIRE_LOAD_SUCCESS,
        payload: {
            questionnaire: questionnaire,
            previousAnswers: previousAnswers,
        },
    });
    await dispatch(setQuestionnaireLoading(false));
};

/**
 * Save the trait strengths mapping for the patient
 * @param {Array} traitStrengths - The trait strengths to upload to the DB
 * @param {Object} previousAnswers - The previous answers to the questionnaire
 * @param {String} org - The organization of the patient
 * @param {String} patientId - The patient's id
 * @returns 
 */
export const uploadTraitStrengthMapping = (traitStrengths, previousAnswers, patientId) => async (dispatch) => {
    try {
        await unauthAxiosApiInstance.post(ROOT + `/trait-strengths?patientId=${patientId}`, { traits: traitStrengths, previousAnswers: previousAnswers })
    } catch (e) {
        console.log("Failed to save trait strengths for patient")
        console.log(e);
    }
}

export const getTraitType = (traitIds) => async (dispatch) => {
    try {
        const res = await unauthAxiosApiInstance.get(ROOT + `/trait-type?traitIds=${traitIds}&org=${org}`);
        console.log(res)
        return res.data.traitType;
    } catch (e) {
        console.log("Failed to get trait type")
        console.log(e);
    }
}