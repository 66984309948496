import React, { useEffect, useState, useRef } from "react";
import { Box, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { 
    selectDistancePreference, 
    selectDocMarkerFromPersonCard, 
    selectExistsMatchingProviders, 
    selectFavoriteDoctorIds, 
    selectFilteredDocs, 
    selectLatLong, 
    selectLeftRightBounds, 
    selectLoggedIn, 
    selectModalityPreference, 
    selectPatientId, 
    selectPreferredInsurance, 
    selectPreferredSpecialty, 
    selectTempDocId,
    selectTopBottomBounds, 
} from "../../redux/store";
import { loadClinics, loadDoctors, setDocMarkerFromPersonCard } from "../../redux/actions/doctors.actions";
import MiniPersonCard from "./MiniPersonCard";
import { InfoWindow, Marker } from "@react-google-maps/api";
import UserMarker from '../../assets/blue-green-marker-navy-outline.svg';
import DocMarker from '../../assets/physician.svg';
import ClinicMarker from '../../assets/clinic.svg';
import HospitalMarker from '../../assets/hospital.svg';
import { setDistancePreference, setMapBounds, setMapCenter } from "../../redux/actions/user.actions";
import { setMapLoading } from "../../redux/actions/shared.actions";
import '../../css/Marker.css';

const Maps = () => {
    const dispatch = useDispatch();
    const ref = useRef();
    const onMobile = !useMediaQuery('(min-width:600px)');

    const filteredDocs = useSelector(selectFilteredDocs);
    // const clinics = useSelector(selectClinics);
    const existsMatchingProviders = useSelector(selectExistsMatchingProviders);
    const loggedIn = useSelector(selectLoggedIn);
    const patientId = useSelector(selectPatientId);
    const specialtyPreference = useSelector(selectPreferredSpecialty);
    const insurancePreference = useSelector(selectPreferredInsurance);
    const tempDocId = useSelector(selectTempDocId);
    const distancePreference = useSelector(selectDistancePreference);
    const latLong = useSelector(selectLatLong);
    const preferredModality = useSelector(selectModalityPreference);
    const leftRightBounds = useSelector(selectLeftRightBounds);
    const topBottomBounds = useSelector(selectTopBottomBounds);

    const [markers, setMarkers] = useState([]);
    const [map, setMap] = useState(null);
    const [openMarker, setOpenMarker] = useState([]);
    const [hoverMarker, setHoverMarker] = useState(null);

    const drawerWidth = 515;

    //If the user has inputted a location, use that for center, otherwise use STL
    const center = latLong && Object.keys(latLong).length == 2 ? { lat: latLong.lat, lng: latLong.lng } : { lat: 38.6270, lng: -90.1994 }

    const normalMapOptions = {
        mapId: "4ff7ad01e6d0f3cf",
        center,
        zoom: 11,
        minZoom: 10.5,
        disableDefaultUI: false,
    };

    const mobileMapOptions = {
        mapId: "4ff7ad01e6d0f3cf",
        center,
        zoom: 11,
        minZoom: 10.5,
        disableDefaultUI: false,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
    }

    const mapOptions = onMobile ? mobileMapOptions : normalMapOptions;
 
    const onMapMove = async (map) => {
        await dispatch(setMapLoading(true));
        const bounds = map?.getBounds();
        if(!bounds) {
            await dispatch(setMapLoading(false));
            return;
        }
        // Get the coordinates of the southwest and northeast corners of the bounds
        const sw = bounds.getSouthWest();
        const ne = bounds.getNorthEast();
        
        // Calculate the width and height in lat/lng coordinates
        const width = ne.lng() - sw.lng();
        const height = ne.lat() - sw.lat();

        const center = map.getCenter();

        await dispatch(setMapCenter({lat: center.lat(), lng: center.lng()}));

        const leftRightBounds = [center.lng() - width / 2, center.lng() + width / 2];
        const topBottomBounds = [center.lat() - height / 2, center.lat() + height / 2];

        await dispatch(setMapBounds(leftRightBounds, topBottomBounds));

        setOpenMarker([]);
        setHoverMarker(null);

        if(patientId) {
            await dispatch(loadDoctors(patientId, leftRightBounds, topBottomBounds, specialtyPreference, insurancePreference, onMobile, tempDocId, preferredModality));
            await dispatch(loadClinics(leftRightBounds, topBottomBounds));
        }
        await dispatch(setMapLoading(false));
    }
    function calculateBounds() {
        const distancePreferenceDict = {
            "Within 5 Miles": 5,
            "Within 10 Miles": 10,
            "Within 25 Miles": 15,
        }

        const radiusInMiles = distancePreferenceDict[distancePreference];
        const earthRadius = 3959; // Radius of the Earth in miles

        // Convert distance to radians
        const radiusInRadians = radiusInMiles / earthRadius;

        const lat = latLong.lat * (Math.PI / 180);
        const lon = latLong.lng * (Math.PI / 180);
      
        const latMin = lat - radiusInRadians;
        const latMax = lat + radiusInRadians;
      
        // Latitude bounds
        const minLat = Math.max(latMin * (180 / Math.PI), -90);
        const maxLat = Math.min(latMax * (180 / Math.PI), 90);
      
        // Longitude bounds
        const lonMin = lon - radiusInRadians;
        const lonMax = lon + radiusInRadians;
      
        const minLng = (minLat === -90 || maxLat === 90) ? -180 : (lonMin * (180 / Math.PI) + 540) % 360 - 180;
        const maxLng = (minLat === -90 || maxLat === 90) ? 180 : (lonMax * (180 / Math.PI) + 540) % 360 - 180;
      
        return {
          minLat: minLat,
          maxLat: maxLat,
          minLng: minLng,
          maxLng: maxLng,
        };
    }

    /**
     * Initialize the map and add event listeners on map load
     * Update map when preferences are updated
     */
    useEffect(() => {
        let zoomTimeout;
        let isCursorInViewport = false;
        let newMap;
        const asyncInitMap = async () => {
            newMap = new window.google.maps.Map(ref.current, mapOptions);
            setMap(newMap);

            const handleMouseMove = () => {
                isCursorInViewport = true;
            };
        
            const handleMouseLeave = () => {
                isCursorInViewport = false;
            };
        
            const handleBoundsChanged = () => {
                if (!onMobile && !isCursorInViewport) return;

                clearTimeout(zoomTimeout);
                zoomTimeout = setTimeout(async () => {
                    await dispatch(setDistancePreference(null));
                    await onMapMove(newMap);
                }, 600);
            };
            //Make sure 'bounds' in onMapMove is not null
            window.google.maps.event.addListenerOnce(newMap, "idle", async () => {
                window.google.maps.event.trigger(newMap, "center_changed");
            });

            window.google.maps.event.addListener(newMap, "center_changed", handleBoundsChanged);
            window.google.maps.event.addListener(newMap, "mousemove", handleMouseMove);
            window.google.maps.event.addListener(newMap, "mouseout", handleMouseLeave);
        }
        asyncInitMap();
        
        return () => {
          window.google.maps.event.clearInstanceListeners(newMap);
          setMap(null);
        };
        
    }, [dispatch, patientId, specialtyPreference, insurancePreference, latLong]);

    useEffect(() => {
        const asyncBootstrapMarkersAndPersonCard = async () => {
            const markers = [];
            if(filteredDocs){
                filteredDocs.forEach((doctor, index) => {
                    //If showing preview docs or logged in
                    if(((!loggedIn && existsMatchingProviders && index < 4) || !existsMatchingProviders || loggedIn)) {
                        const docLoc = doctor.Locations;
                        for(let location in doctor.Locations) {
                            //if the location is outisde left/right bounds, don't add it
                            if(docLoc[location].longitude < leftRightBounds[0] || docLoc[location].longitude > leftRightBounds[1]) 
                                continue;
                            //if the location is outisde top/bottom bounds, don't add it
                            if(docLoc[location].latitude < topBottomBounds[0] || docLoc[location].latitude > topBottomBounds[1]) 
                                continue;

                            markers.push({
                                type: "doc", // add type for doctors to distinguish between different types of markers
                                docInfo: doctor,
                                id: doctor.id,
                                firstname: doctor.first_name + " " + doctor.last_name,
                                locationName: docLoc[location].address,
                                lat: docLoc[location].latitude,
                                lng: docLoc[location].longitude,
                            });
                        }
                    }
                });
            }
            // if(clinics) {
            //     console.log(clinics)
            //     await clinics.forEach((clinic) => {
            //         const clinicLoc = clinic.Location;
            //         markers.push({
            //             type: "clinic",
            //             id: clinic.id,
            //             locationName: clinicLoc.address,
            //             lat: clinicLoc.latitude,
            //             lng: clinicLoc.longitude,
            //         });
            //     });
            // }
            if(latLong)
                markers.push({
                    type: "user",
                    lat: latLong.lat,
                    lng: latLong.lng,
                });
            setMarkers(markers)
        }
        asyncBootstrapMarkersAndPersonCard();
    },[dispatch, filteredDocs, specialtyPreference, insurancePreference, latLong]);

    useEffect(() => {
        const asyncBootstrapUpdateDistancePref = async () => {
            if(!distancePreference || !map) 
                return;
            const bounds = calculateBounds();
            //validate bounds
            if(!(bounds && bounds.minLat && bounds.maxLat && bounds.minLng && bounds.maxLng))
                return;
            const mapBounds = new window.google.maps.LatLngBounds(
                new window.google.maps.LatLng(bounds.minLat, bounds.minLng),
                new window.google.maps.LatLng(bounds.maxLat, bounds.maxLng)
            );
            map.fitBounds(mapBounds);
            await onMapMove(map);
        }
        asyncBootstrapUpdateDistancePref();
    }, [distancePreference, map]);

    useEffect(() => {
        const asyncBootstrapUpdateModalityPref = async () => {
            await onMapMove(map);
        }
        asyncBootstrapUpdateModalityPref();
    }, [preferredModality]);

    return (
        <Box sx={{
            height: onMobile ? "calc(100vh - 8.5rem)" : "",
            marginTop: onMobile ? "5rem" : ""
        }}>
            <Box ref={ref} 
                sx={{
                    minHeight: onMobile ? "calc(100vh-4rem)" : "calc(100vh - 4rem)",
                    height: "100%",
                    width: onMobile ? "100vw" : `calc(100% - ${drawerWidth}px)`,
                    marginLeft: onMobile ? "" : `${drawerWidth}px`,
            }}/>
            {map && 
                <Markers 
                    map={map} 
                    markers={markers} 
                    filteredDocs={filteredDocs} 
                    openMarker={openMarker} 
                    setOpenMarker={setOpenMarker} 
                    hoverMarker={hoverMarker} 
                    setHoverMarker={setHoverMarker} 
            />}
        </Box>
    )
}

const Markers = ({map, markers, filteredDocs, openMarker, hoverMarker, setOpenMarker, setHoverMarker}) => {
    const dispatch = useDispatch();
    const favoriteDoctorIds = useSelector(selectFavoriteDoctorIds);
    const docMarkerFromPersonCard = useSelector(selectDocMarkerFromPersonCard);

    useEffect(() => {
        const bootstrapAsyncMarker = async () => {
            if(docMarkerFromPersonCard === null) return;

            const index = markers.findIndex((marker) => marker.id === docMarkerFromPersonCard)
            await handleOpen(index);
        }
        bootstrapAsyncMarker();
    }, [docMarkerFromPersonCard])

    const handleOpen = async (index) => {
        if(openMarker.includes(index)) return;

        setOpenMarker([...openMarker, index])
        await dispatch(setDocMarkerFromPersonCard(null));
    }

    const handleClose = (index) => {
        setOpenMarker(openMarker.filter((marker) => marker !== index))
    }

    const handleHover = (index) => {
        if(hoverMarker === index) return;
        setHoverMarker(index)
    }

    const handleUnhover = () => {
        setHoverMarker(null)
    }


    return (
        <>
            {markers?.map((marker, index) => {
                let curInd;
                let personInfo;
                if(marker.docInfo && filteredDocs) {
                    curInd = filteredDocs.indexOf(marker.docInfo)
                    personInfo = filteredDocs[curInd]
                }
                return (
                    marker.lat && marker.lng &&
                        <Marker
                            key={index}
                            position={{ lat: marker.lat, lng: marker.lng}}
                            map={map}
                            icon={{
                                // render icons depending on the marker type
                                url: marker.type === "user" ? UserMarker : marker.type === "hospital" ? HospitalMarker : marker.type === "doc" ? DocMarker : marker.type === "clinic" ? ClinicMarker : null,
                                scaledSize: new window.google.maps.Size(35, 35),
                            }}
                            onClick={async () => await handleOpen(index)}
                            onMouseOver={() => handleHover(index)}
                            onMouseOut={handleUnhover}
                        >
                            {(openMarker.includes(index) || hoverMarker === index) && marker.type !== "user" &&
                                <InfoWindow 
                                    onCloseClick={(e) => handleClose(index)} 
                                    position={{lat: marker.lat, lng: marker.lng}} 
                                    options={{disableAutoPan: true}}
                                >
                                    {marker.type !== "user" &&
                                        <Box sx={{
                                            marginLeft: "-0.5rem",
                                            marginTop: "-0.5rem",
                                        }}>
                                            <MiniPersonCard
                                                personInfo={personInfo} 
                                                index={curInd} 
                                                maps={true}
                                                favoriteDoctorIds={favoriteDoctorIds}
                                                currentLocation={marker.locationName}
                                            />
                                        </Box>
                                    }
                                </InfoWindow>
                            }
                        </Marker>
                )
            })}
        </>
    )
}

export default Maps;