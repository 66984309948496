import axios from 'axios';
import { COGNITO_MAP } from '../redux/constants/shared.constants';
const unauthAxiosApiInstance = axios.create();

// Response interceptor for API calls
unauthAxiosApiInstance.interceptors.response.use((response) => {
    return response;
}, async function (error) {
    console.log(error.response);
    const originalRequest = error?.config;

    // Additional check for the request path
    if (originalRequest.url.includes('/check-cognito-id') &&
        (error?.response?.status === 403 || error?.response?.status === 401) &&
        !originalRequest._retry) {
        
        console.log('==========================');
        console.log('Retrying check-cognito-id request...');
        console.log('==========================');
        
        originalRequest._retry = true;
        const newAccessToken = await refreshAccessToken();

        // Ensure newAccessToken was received
        if (newAccessToken) {
            // Assuming the body is in JSON format and accessToken is a property
            // This needs to be adjusted based on your actual request format
            if (originalRequest.data) {
                let data = originalRequest.data;
                
                // If the data is a stringified JSON, parse it first
                if (typeof originalRequest.data === "string") {
                    data = JSON.parse(originalRequest.data);
                }
                
                // Update the accessToken
                data.accessToken = newAccessToken;

                // If the original data was a string, stringify the updated data
                if (typeof originalRequest.data === "string") {
                    originalRequest.data = JSON.stringify(data);
                } else {
                    originalRequest.data = data;
                }
            }

            return unauthAxiosApiInstance(originalRequest);
        }
    }

    return Promise.reject(error);
});

const refreshAccessToken = async () => {
    const refreshToken = localStorage.getItem('refresh_token')
    try {
        const tokens = await axios.post(COGNITO_MAP["refresh"], {
            refresh_token: refreshToken
        })
        const access_token = tokens.data.access_token
        localStorage.setItem('access_token', access_token)
        console.log('==========================')
        console.log('Refreshed access token')
        console.log('==========================')
        return access_token
    } catch(e) {
        //if refresh token is expired, redirect to cognito logout url, then to our client
        console.log(e);
        handleLogoutUser();
        return null
    }
}

const handleLogoutUser = () => {
    if(localStorage.getItem("expired_tokens") === null) {
        localStorage.setItem("expired_tokens", JSON.stringify(true));
        window.location.href = "/";
    }
}

export default unauthAxiosApiInstance;
