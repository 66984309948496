import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import { selectInsuranceOptions, selectSpecialtyOptions } from '../../../redux/store';

import { Autocomplete, Grid, TextField, Box, Typography, useMediaQuery } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import { resetDoctorDistance } from '../../../redux/actions/doctors.actions';
import parse from 'autosuggest-highlight/parse';
import { org } from '../../../redux/constants/shared.constants';

const NavbarAutoComplete = ({variant, type, preferedInsurance, setInsurancePreference, preferedSpecialty, setSpecialtyPreference, location, setLocation}) => {
    const dispatch = useDispatch();
    const onMobile = !useMediaQuery('(min-width:600px)');
    
    const insuranceOptions = useSelector(selectInsuranceOptions);
    const specialtyOptions = useSelector(selectSpecialtyOptions);

    const [googleAutocompleteService, setGoogleAutocompleteService] = useState(null);

    const [locationValue, setLocationValue] = useState('');
    const [locationOptions, setLocationOptions] = useState([]);
    const [focus, setFocus] = useState("");

    const onQuiz = variant === "quiz";


    useEffect(() => {
      const asyncGoogleLoad = async () => {
        if(window.google && !googleAutocompleteService)
          setGoogleAutocompleteService(new window.google.maps.places.AutocompleteService());
        if(googleAutocompleteService)
          await fetchOptions({ input: locationValue }, (results) => {
            let newOptions = [];
            if(results)
              newOptions = [...results];
            setLocationOptions(newOptions);
          });
      }
      asyncGoogleLoad();
    }, [locationValue])

    const fetchOptions = async (request, callback) => {
      await googleAutocompleteService.getPlacePredictions(request, callback);
    }

    const handleChangeLocation = async (e, location) => {
      e.preventDefault();
      const newLocation = location?.description;
      setLocationOptions(location ? [location, ...locationOptions] : locationOptions);
      setLocationValue(newLocation);
      setLocation(newLocation);
      await dispatch(resetDoctorDistance())
    }

    const styles = {
        autocomplete: {
          display: "flex",
          maxHeight: "3rem",
          border: "none",
          minWidth: !onMobile ? "18vw" : "75vw",
          maxWidth: !onMobile ? "20rem" : "",
          paddingTop: "0.25rem",
          overflowY: "hidden",
          '& fieldset': {
            border: "none",
            maxHeight: "3rem",
          },
          center: {
            marginTop: "0.2rem",
            overflowY: "none",
          },
          right: {
            marginTop: "0.2rem",
            overflowY: "none",
          },
          locationOptions: {
            marginTop: "20rem",
          },
          left: {
            marginTop: "0.25rem",
            overflowY: "none",
          },
          '& .MuiAutocomplete-endAdornment': {
            display: "none !important",
          },
          '& .MuiInputBase-input': {
            fontSize: "1.1em !important",
          },
          '& .MuiInputBase-root': {
            width: !onMobile ? "calc(100% + 3.5rem)" : "100%",
            scrollbarWidth: "none",
          },
        },
        textField: {
          fontSize: "0.5em",
          '& .MuiInputBase-root': {
            maxHeight: "2.5rem", 
            overflow: "scroll",
          },
          '& .MuiFormLabel-root': {
            marginTop: "-0.1rem",
          },
          '& .MuiAutocomplete-input': {
            fontSize: "1.1em !important",
          },
        },
        quiz: {
          width: "28vw",
          minWidth: "20rem",
          maxWidth: "30rem",
          margin: "0.5rem",
          '& .MuiAutocomplete-endAdornment': {
            display: "none !important",
          },
          '& .MuiInputBase-input': {
            fontSize: "1.1em !important",
          },
          '& .MuiInputBase-root': {
            scrollbarWidth: "none",
        },
      }
    };      
    
    /***
     * Change the insurance preference for patient
     * 
     * @param {Event} e
     * @param {Array} insurances
     */
    const handleChangeInsurance = async (e, insurances) => {
        e.preventDefault();

        ReactGA.event({
            category: 'Onboarding',
            action: 'Click',
            label: `Choose Insurances: ${insurances}`,
        });
        const newInsurances = insurances ? insurances : [];
        setInsurancePreference(newInsurances);
    }

    /***
     * Change the specialty preference for patient
     * 
     * @param {Event} e
     * @param {Array} specialties
     */
    const handleChangeSpecialty = async (e, specialties) => {
        e.preventDefault();

        ReactGA.event({
            category: 'Onboarding',
            action: 'Click',
            label: `Choose Specialties: ${specialties}`,
        });
        const newSpecialties = specialties ? specialties : [];
        setSpecialtyPreference(newSpecialties);
    }

    /***
     * Detect which autocomplete is focused, use this to have different styles depending on focus
     * 
     * @param {Event} e
     */
    const handleFocus = async (e) => {
        e.preventDefault();
        setFocus(type);
    }

    /***
     * Set focus to empty string when autocomplete is blurred
     * 
     * @param {Event} e
     */
    const handleBlur = async (e) => {
        e.preventDefault();
        setFocus("");
    }

    //Return the correct autocomplete based on type prop
    if(type === "insurance" && org === "wustl")
        return (
            <Autocomplete
                disabled
                options={[]}
                id="insurance-autocomplete"
                className="autocomplete right"
                sx={onQuiz ? styles.quiz : styles.autocomplete}
                renderInput={(params) => <TextField 
                  disabled
                    {...params} sx={styles.textField} 
                />}
                size="small"
                defaultValue={"Insurance Covered Through WashU"}
            />
        );
    if(type === "insurance")
        return (
          <Autocomplete
              multiple
              id="insurance-autocomplete"
              className="autocomplete right"
              sx={onQuiz ? styles.quiz : styles.autocomplete}
              options={insuranceOptions ? insuranceOptions : []}
              value={preferedInsurance ? preferedInsurance : []}
              // value={["test 1", "test 2"]}
              renderInput={(params) => <TextField 
                  {...params} sx={styles.textField} 
                  label="Insurance"
                  rows={focus === "specialty" ? 3 : 1}
                  onFocus={async (e) => await handleFocus(e)}
                  onBlur={async (e) => await handleBlur(e)}
              />}
              size="small"
              onChange={async (e, newInsurance) => await handleChangeInsurance(e, newInsurance)}
          />
        );
    if(type === "specialty" && org === "wustl")
        return (
          <Autocomplete
              disabled
              id="specialty-autocomplete"
              className="autocomplete center"
              sx={onQuiz ? styles.quiz : styles.autocomplete}
              options={[]}
              renderInput={(params) => <TextField 
                disabled
                  {...params} sx={styles.textField} 
              />}
              size="small"
              value={"Therapy"}
          />
        );
    else if(type === "specialty")
        return (
            <Autocomplete
                multiple
                id="specialty-autocomplete"
                className="autocomplete center"
                sx={onQuiz ? styles.quiz : styles.autocomplete}
                options={specialtyOptions ? specialtyOptions : []}
                value={preferedSpecialty ? preferedSpecialty : []}
                renderInput={(params) => <TextField 
                  {...params} sx={styles.textField} 
                  rows={focus === "specialty" ? 3 : 1}
                  onFocus={async (e) => await handleFocus(e)}
                  onBlur={async (e) => await handleBlur(e)}
                  label="Specialty" 
                />}
                size="small"
                onChange={async (e, newSpecialty) => await handleChangeSpecialty(e, newSpecialty)}
            />
        );
    else if(type === "location")
        return (
          <Autocomplete
            id="google-map-demo"
            className="autocomplete left" 
            size="small"
            //
            getOptionLabel={(option) =>
              typeof option === 'string' ? option : option.description
            }
            options={locationOptions ? locationOptions : []}
            noOptionsText="No locations"
            includeInputInList
            filterSelectedOptions
            defaultValue={location ? location : locationValue}
            onChange={async (e, newValue) => await handleChangeLocation(e, newValue)}
            onInputChange={(e, newInputValue) => {setLocationValue(newInputValue)}}
            value={location ? location : ""}
            sx={{
              minWidth: "31%"
            }}
            renderInput={(params) => (
              <TextField 
                {...params} 
                label="Add a location" 
                fullWidth 
                sx={onQuiz ? styles.quiz : styles.autocomplete}
              />
            )}
            renderOption={(props, option) => {
              const matches = option.structured_formatting.main_text_matched_substrings || [];

              const parts = parse(
                option.structured_formatting.main_text, 
                matches.map((match) => [match.offset, match.offset + match.length]),
              );
              return (
                <li {...props}>
                  <Grid container alignItems="center">
                    <Grid item sx={{ display: 'flex', width: 44 }}>
                      <LocationOnIcon sx={{ color: 'text.secondary' }} />
                    </Grid>
                    <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                      {parts.map((part, index) => (
                        <Box
                          key={index}
                          component="span"
                          sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                        >
                          {part.text}
                        </Box>
                      ))}

                      <Typography variant="body2" color="text.secondary">
                        {option.structured_formatting.secondary_text}
                      </Typography>
                    </Grid>
                  </Grid>
                </li>
              );
            }}
          />
        )
}

export default NavbarAutoComplete;