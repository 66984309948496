import React from "react";
import logo from '../../assets/caralyst_logo_multi_1.svg'
import { Box, Typography } from "@mui/material";

const LoadingIcon = (props) => {

  const textInfo = props.textInfo;

  return (
    <Box className="caralyst-loading-logo-wrapper" 
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box 
        style={{color: 'black !important'}}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "2vh",
          maxWidth: "8vw",
          maxHeight: "8vh",
          minWidth: "5rem",
          "@keyframes logo-fade": {
            "0%": {
                opacity: "1"
            },
            "50%": {
                opacity: "0.4"
            },
            "100%": {
                opacity: "1"
            },
          },
          animation: "logo-fade 1.2s infinite",
        }}
      >
        <img src={logo} alt="Caralyst Logo" className="caralyst-loading-logo" style={{maxWidth: "12rem"}}/>
        {textInfo &&
          <Typography className="caralyst-loading-icon-text"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              color: "black",
              width: "16rem",
              whiteSpace: "pre-line",
              marginTop: "1rem",
              fontSize: "1rem",
            }}
          >
            {textInfo}
          </Typography>}
      </Box>
    </Box>);
};

export default LoadingIcon;