import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectDoctorsLoadingError,
    selectExistsMatchingProviders,
    selectFilteredDocs,
    selectLoadingMetatdata,
    selectDoctorsLoadingStatus,
    selectLoggedIn,
    selectLoggingIn,
    selectLoggingOut,
    selectPatientId,
    selectPreferredInsurance,
    selectMapLoading,
} from "../../redux/store";
import { loginPatient, setPreferredInsurance } from '../../redux/actions/user.actions';
import { org } from '../../redux/constants/shared.constants';

import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import NoResultsImage from '../../assets/no-result.svg';
import BrokenHeartImage from '../../assets/broken-heart.svg';
import NotFound from '../../assets/not-found.png';

import MiniPersonCard from './MiniPersonCard';
import SkeletonScreen from './SkeletonScreen';

const MiniPersonGrid = ({ numCols, filterByFavorites, setFilterByFavorites }) => {
    const onMobile = !useMediaQuery('(min-width:600px)');
    const dispatch = useDispatch();
    const theme = useTheme();

    const docsLoading = useSelector(selectDoctorsLoadingStatus);
    const loggedIn = useSelector(selectLoggedIn);
    const filteredDocs = useSelector(selectFilteredDocs);
    const existsMatchingProviders = useSelector(selectExistsMatchingProviders);
    const loggingOut = useSelector(selectLoggingOut);
    const loggingIn = useSelector(selectLoggingIn);
    const mapLoading = useSelector(selectMapLoading);
    const doctorsLoadingError = useSelector(selectDoctorsLoadingError);
    const insurancePreference = useSelector(selectPreferredInsurance);
    const patientId = useSelector(selectPatientId);
    const loadingMetadata = useSelector(selectLoadingMetatdata);

    //When filtered docs changes, we need to update these values
    useEffect(() => {
    }, [dispatch, loggingOut, loggingIn, docsLoading, loadingMetadata, mapLoading]);

    useEffect(() => {
    }, [dispatch])


    //Login patient
    const handleLogin = async (e) => {
        e.preventDefault();
        await dispatch(loginPatient());
    }
    
    //Show out of network physicians (remove insurance filter)
    const handleShowOutOfNetworkPhysicians = async (e) => {
        e.preventDefault();
        await dispatch(setPreferredInsurance([], org, loggedIn, patientId));
    }

    //Remove favorites filter
    const handleRemoveFavoritesFilter = (e) => {
        e.preventDefault();
        setFilterByFavorites(false);
    }

    return (
        <>
            {/* If the page is loading, show the skeleton screen */}
            {(docsLoading || loggingOut || loggingIn || loadingMetadata || mapLoading) ?
                <SkeletonScreen />
                :
                // Otherwise, if filteredDocs is not empty, show the grid
                filteredDocs && filteredDocs.length > 0 ?
                    <>
                        {existsMatchingProviders &&
                            <Box sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                width: "100%",

                            }}>
                                <Typography sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: "1rem",
                                    marginBottom: "0rem",
                                    fontWeight: "bold",
                                }}>
                                    Most Compatible Physicians
                                </Typography>
                            </Box>
                        }
                        <Box sx={!onMobile ? {
                            } : {
                                marginTop: "5.5rem",
                                position: "fixed",
                                overflowY: "auto",
                                left: "2%",
                                maxHeight: "calc(100% - 5rem)",
                                paddingBottom: "30vh",
                                width: "96%",
                        }}>
                            <Grid
                                container
                                spacing={{ xs: 1, md: 1 }}
                                columns={{ xs: 6, sm: 12, md: 12 }}
                                sx={{
                                    paddingTop: "1rem",
                                    backgrounColor: "white",
                                    maxWidth: numCols === 1 ? "16.5rem" : "32rem",
                                    marginBottom: "0.5rem",
                                    marginTop: "0.5rem",
                            }}>
                                {filteredDocs.map((doc, index) => {
                                    if (index < 4)
                                        return (
                                            <Box key={index}>
                                                <Grid item xs={numCols === 2 ? 6 : 12} sm={numCols === 2 ? 6 : 12} md={numCols === 2 ? 6 : 12}
                                                    sx={{
                                                        minWidth: !onMobile ? "15rem" : "94vw",
                                                        marginLeft: !onMobile ? "0.5rem" : "3vw",
                                                        marginTop: !onMobile ? "0.5rem" : "",
                                                        marginBottom: !onMobile ? "" : "0.5rem",
                                                    }}>
                                                    <MiniPersonCard
                                                        personInfo={doc}
                                                        index={index}
                                                    />
                                                </Grid>
                                            </Box>

                                        )

                                })}
                            </Grid>
                            {filteredDocs.length > 4 &&
                                <>
                                    {!loggedIn && existsMatchingProviders &&
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                maxWidth: "100%",
                                                marginTop: "0.5rem",
                                                marginBottom: "1rem",
                                        }}>
                                            <Typography
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    flexWrap: "wrap",
                                                    maxWidth: "80%",
                                                    marginBottom: "1rem",
                                                    fontWeight: "bold",
                                                    textAlign: "center",
                                            }}>
                                                To see the rest of your matches, please&nbsp;
                                                <Box
                                                    onClick={async (e) => await handleLogin(e)}
                                                    sx={{
                                                        textDecoration: "underline",
                                                        cursor: "pointer",
                                                        whiteSpace: "nowrap",
                                                    }}>
                                                    log in
                                                </Box>
                                                &nbsp;or&nbsp;
                                                <Box
                                                    onClick={async (e) => await handleLogin(e)}
                                                    sx={{
                                                        textDecoration: "underline",
                                                        cursor: "pointer",
                                                        whiteSpace: "nowrap",
                                                    }}>
                                                    create a free account
                                                </Box>.
                                            </Typography>
                                        </Box>
                                    }
                                    <Grid
                                        container
                                        spacing={{ xs: 1, md: 1 }}
                                        columns={{ xs: 6, sm: 12, md: 12 }}
                                        sx={{
                                            backgrounColor: "white",
                                            maxWidth: numCols === 1 ? "16.5rem" : "32rem",
                                            marginBottom: "0.5rem",
                                    }}>
                                        {filteredDocs.map((doc, index) => {
                                            if (index >= 4 && index < 50)
                                                return (
                                                    <Box key={index}>
                                                        <Grid item xs={numCols === 2 ? 6 : 12} sm={numCols === 2 ? 6 : 12} md={numCols === 2 ? 6 : 12}
                                                            sx={{
                                                                minWidth: !onMobile ? "15rem" : "94vw",
                                                                marginLeft: !onMobile ? "0.5rem" : "3vw",
                                                                marginTop: !onMobile ? "0.5rem" : "",
                                                                marginBottom: !onMobile ? "" : "0.5rem",
                                                            }}>
                                                            <MiniPersonCard
                                                                personInfo={doc}
                                                                index={index}
                                                            />
                                                        </Grid>
                                                    </Box>

                                                )

                                        })}
                                    </Grid>
                                </>
                            }
                            {filteredDocs.length > 50 &&
                                <>
                                    {loggedIn && existsMatchingProviders &&
                                        <Box sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            width: "100%",

                                        }}>
                                            <Typography sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                marginTop: "1rem",
                                                marginBottom: "1rem",
                                                fontWeight: "bold",
                                            }}>
                                                Other physicians that may be a good fit
                                            </Typography>
                                        </Box>
                                    }
                                    <Grid
                                        container
                                        spacing={{ xs: 1, md: 1 }}
                                        columns={{ xs: 6, sm: 12, md: 12 }}
                                        sx={{
                                            backgrounColor: "white",
                                            maxWidth: numCols === 1 ? "16.5rem" : "32rem",
                                            marginBottom: "1rem",
                                    }}>
                                        {filteredDocs.map((doc, index) => {
                                            if (index >= 50)
                                                return (
                                                    <Box key={index}>
                                                        <Grid item xs={numCols === 2 ? 6 : 12} sm={numCols === 2 ? 6 : 12} md={numCols === 2 ? 6 : 12}
                                                            sx={{
                                                                minWidth: !onMobile ? "15rem" : "94vw",
                                                                marginLeft: !onMobile ? "0.5rem" : "3vw",
                                                                marginTop: !onMobile ? "0.5rem" : "",
                                                                marginBottom: !onMobile ? "" : "0.5rem",
                                                            }}>
                                                            <MiniPersonCard
                                                                personInfo={doc}
                                                                index={index}
                                                            />
                                                        </Grid>
                                                    </Box>
                                                )
                                        })}
                                    </Grid>
                                </>
                            }
                        </Box>
                    </>
                    :
                    // If user is filtering by favorites, but there are none, show error
                    filteredDocs?.length === 0 && filterByFavorites ?
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            textAlign: "center",
                            marginTop: !onMobile ? "50%" : "80%",
                            height: onMobile ? "100%" : "",
                            color: "black",
                        }}>
                            <Box sx={{
                                maxWidth: "10%",
                            }}>
                                <img src={BrokenHeartImage} style={{maxWidth: "100%"}}/>
                            </Box>
                            <Typography 
                                variant='h6'
                                sx={{
                                    textAlign: "center",
                                    margin: "0.5rem"
                            }}>
                                You don't have any favorites!
                            </Typography>
                            <Button
                                variant='contained'
                                onClick={handleRemoveFavoritesFilter}
                                sx={{
                                    textTransform:'none',
                                    backgroundColor: theme.palette.caralystGreen.main,
                                    borderRadius: "1.5rem",
                                    '&:hover': {
                                        backgroundColor: theme.palette.caralystGreen.main,
                                        filter: 'brightness(95%)',
                                    },
                            }}>
                                Find Your Perfect Match
                            </Button>
                        </Box>
                        :
                        // If there was an error loading docs, way to fix
                        doctorsLoadingError ?
                            <Box sx={{
                                borderRadius: 4,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                textAlign: "center",
                                marginTop: !onMobile ? "50%" : "8%",
                            }}>
                                <Box sx={{
                                    width: "10%",
                                    margin: "0.5rem",
                                    marginTop: !onMobile ? "" : "80%"
                                }}>
                                    <img src={NoResultsImage} />
                                </Box>
                                <Typography
                                    variant='h6'
                                    sx={{
                                        textAlign: "center",
                                        margin: "0.5rem",
                                        color: "black",
                                }}>
                                    Sorry, we weren't able to find any physicians that match your preferences.
                                </Typography>
                                {insurancePreference?.length > 0 &&
                                    <Button
                                        variant='contained'
                                        onClick={async (e) => await handleShowOutOfNetworkPhysicians(e)}
                                        sx={{
                                            textTransform:'none',
                                            backgroundColor: theme.palette.caralystGreen.main,
                                            borderRadius: "1.5rem",
                                            '&:hover': {
                                                backgroundColor: theme.palette.caralystGreen.main,
                                                filter: 'brightness(95%)',
                                            },
                                    }}>
                                        See out of network physicians
                                    </Button>
                                    }
                            </Box>
                            :
                            // If some other error occurs, show error
                            <Typography
                                variant='h6'
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    textAlign: "center",
                                    margin: "0.5rem",
                                    color: "black",
                                    marginTop: onMobile ? "80%" : "50%"
                            }}>
                                <Box sx={{
                                    maxWidth: "15%",
                                    marginBottom: "1.5rem",
                                    marginTop: "-1.5rem"
                                }}>
                                    <img src={NotFound} style={{maxWidth: "100%"}}/>
                                </Box>
                                Sorry, we weren't able to find any physicians with your preferences.
                            </Typography>
            }
        </>
    )
};

export default MiniPersonGrid;