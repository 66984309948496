import React, { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom";

import { BottomNavigation, BottomNavigationAction, Box, Chip, useMediaQuery, useTheme } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import FavoriteIcon from '@mui/icons-material/Favorite';
import MapIcon from '@mui/icons-material/Map';

import { MobileNavbar } from "./Navbar/MobileNavbar";
import MiniPersonGrid from "./MiniPersonGrid";
import Maps from "./Maps";
import { useDispatch, useSelector } from "react-redux";
import { selectDoctors, selectFavoriteDoctorIds, selectFilteredDocs, selectDoctorsLoadingStatus, selectLoggedIn, selectPreferredSpecialty, selectPreferredInsurance, selectTempDocId, selectModalityPreference, selectPopupSurveyStatus, selectPopupSurveyTimeoutSet } from "../../redux/store";
import { setPersonCardOpen } from "../../redux/actions/user.actions";
import { loadClinics, loadDoctors, setFilteredDocs } from "../../redux/actions/doctors.actions";
import { selectLeftRightBounds, selectPatientId, selectTopBottomBounds } from "../../redux/store";
import { openPersonCardFromUrl } from "../../redux/actions/shared.actions";

const MobileHome = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();

    const doctors = useSelector(selectDoctors);
    const favoriteDoctorIds = useSelector(selectFavoriteDoctorIds);
    const loggedIn = useSelector(selectLoggedIn);
    const filteredDocs = useSelector(selectFilteredDocs);
    const bottomNavColors = theme.palette.caralystNavy.main;
    const patientId = useSelector(selectPatientId);
    const leftRightBounds = useSelector(selectLeftRightBounds);
    const topBottomBounds = useSelector(selectTopBottomBounds);
    const loadingDocs = useSelector(selectDoctorsLoadingStatus);
    const specialtyPreference = useSelector(selectPreferredSpecialty);
    const insurancePreference = useSelector(selectPreferredInsurance);
    const tempDocId = useSelector(selectTempDocId);
    const modalityPreference = useSelector(selectModalityPreference);
    const popupSurveyStatus = useSelector(selectPopupSurveyStatus);
    const popupSurveyTimeoutSet = useSelector(selectPopupSurveyTimeoutSet);

    const loadingDocsRef = useRef(false);
    const [value, setValue] = useState(0);
    const [filterByFavorites, setFilterByFavorites] = useState(false);
    const [onMaps, setOnMaps] = useState(false);

    const bottomNavActionStyle = {
        color: bottomNavColors,
        ".Mui-selected ": {
            color: bottomNavColors,
        }
    };

    const bottomNavIconStyle = {color: bottomNavColors};

    const isSmallScreen = useMediaQuery('(max-width:410px)');

    useEffect(() => {
        const bootstrapLoadDocs = async () => {
            //check if filtereddocs contains doc with tempDocId
            if(!loadingDocs && !loadingDocsRef.current && leftRightBounds && topBottomBounds && patientId){
                loadingDocsRef.current = true;
                const curDocs = await dispatch(loadDoctors(patientId, leftRightBounds, topBottomBounds, specialtyPreference, insurancePreference, true, tempDocId, modalityPreference));
                loadingDocsRef.current = false;
                await dispatch(openPersonCardFromUrl(curDocs, popupSurveyStatus, popupSurveyTimeoutSet));
            }
        }
        bootstrapLoadDocs();
    }, [dispatch, leftRightBounds, tempDocId]);

    useEffect(() => {
        const asyncInitMobileHome = async () => {
            //Make sure when the screen loads, person card is not open
            await dispatch(setPersonCardOpen(false));

            if(patientId) {
                await dispatch(loadClinics(leftRightBounds, topBottomBounds));
            }
        }
        asyncInitMobileHome();
    }, [dispatch, filteredDocs]);

    useEffect(() => {
        const asyncUpdateFilteredDocs = async () => {
            //The only reason to update filtered docs is if the user is logged in and can have favorites
            if(!loggedIn) return;
            await updateFilteredDocs();
        }
        asyncUpdateFilteredDocs();
    }, [filterByFavorites])

    /***
     * Navigate to home page
     * 
     * @param {Event} e
     */
    const handleClickGoHome = (e) => {
        e.preventDefault();
        navigate("/");
    }

    /***
     * Navigate to questionnaire
     * 
     * @param {Event} e
     */
    const handleClickGoToSurvey = (e) => {
        e.preventDefault();
        navigate("/onboard/questionnaire/0")
    }

    /***
     * Go to favorites page
     * 
     * @param {Event} e
     */
    const handleFilterByFavorites = (e) => {
        e.preventDefault();
        setOnMaps(false);
        setFilterByFavorites(true);
    }

    /***
     * Check if a doctor is a favorite
     * 
     * @param {Object} personInfo - doctor info
     * @param {Array} favDocIds - array of favorite doctor ids
     */
    const isFavorite = (personInfo, favDocIds) => {
        return favDocIds.includes(personInfo.id);
    }

    /***
     * Update filtered docs based on favorites
     */
    const updateFilteredDocs = async () => {
        if(!filterByFavorites)
            await dispatch(setFilteredDocs(doctors));
        else {
            const newFilteredDocs = await filteredDocs.filter(doc => isFavorite(doc, favoriteDoctorIds, filterByFavorites));
            await dispatch(setFilteredDocs((newFilteredDocs)));
        }
    }

    /***
     * Toggle between maps and list view
     * 
     * @param {Event} e
     */
    const handleToggleMaps = (e) => {
        e.preventDefault();
        setOnMaps(!onMaps);
    }


    return (
        <Box
            className="mobile-home-wrapper"
            sx={{   
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "-webkit-fill-available",
        }}>
            <Box 
                className="nav-bar-wrapper"
                sx={{
                    position: "absolute",
                    top: 0,
                    height: "5rem",
            }}>
                <MobileNavbar />
            </Box>
            <Box 
                className="mobile-home-content-wrapper"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    flexGrow: 1,
                    overflowY: "hidden",
                    height: "100vh",
            }}>
                {onMaps &&
                    <Maps />
                }
                {!onMaps &&
                    <>
                        <MiniPersonGrid 
                            numCols={1} 
                            filterByFavorites={filterByFavorites}
                            setFilterByFavorites={setFilterByFavorites}
                        />
                        <Chip
                            avatar={ <MapIcon style={{color:"white", marginLeft: "0.5rem"}}/> }
                            label={ "Map" }
                            variant="filled"
                            sx={{
                                position: "absolute",
                                backgroundColor: "black",
                                color: "white",
                                fontSize: "0.9em",
                                fontWeight: "bold",
                                bottom: "4rem",
                                right: "50%", // Adjust as needed
                                transform: "translateX(50%)", // Center the button horizontally
                                width: "auto", // Adjust width based on onMaps
                                zIndex: 1200,
                                //When the user presses on the chip, brighten the color
                                "&:active": {
                                    backgroundColor: "#2c2c2c",
                                }
                            }}
                            onClick={handleToggleMaps}
                        />
                    </>
                }
            </Box>
            <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
                sx={{
                    width: "100%",
                    position: "fixed",
                    bottom: 0,
                    height: "3.5rem",
            }}>
                <BottomNavigationAction label={"Physicians"} icon={<SearchIcon sx={bottomNavIconStyle}/>} sx={bottomNavActionStyle} onClick={handleClickGoHome} />
                {loggedIn && <BottomNavigationAction label="Favorites" icon={<FavoriteIcon sx={bottomNavIconStyle}/>} sx={bottomNavActionStyle} onClick={handleFilterByFavorites}/>}
                <BottomNavigationAction label={isSmallScreen ? "Personalize" : "Personalize Matches"} icon={<EditIcon sx={bottomNavIconStyle}/>} sx={bottomNavActionStyle} onClick={handleClickGoToSurvey}/>
            </BottomNavigation>
        </Box>
            
    )
}

export default MobileHome;