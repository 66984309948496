import React from 'react';

import { Box, Button, Dialog, DialogContent, Typography, useTheme } from "@mui/material";
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/actions/user.actions';
import { Outlet } from 'react-router-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const ErrorDialog = ({ }) => {
    const dispatch = useDispatch();
    const theme = useTheme();

    //We set this to true when we get a 403 from the server when trying to refresh
    const tokenExpired = JSON.parse(localStorage.getItem("expired_tokens") || "false");
    
    /***
     * Remove expired tokens from local storage and log out
     * 
     * @param {Event} e
     */
    const handleClose = async (e) => {
        e.preventDefault();
        localStorage.removeItem("expired_tokens");
        await dispatch(logout())
    }

    return (
        tokenExpired ?
            <Dialog
                open={tokenExpired}
                onClose={async (e) => await handleClose(e)}
            >
                <DialogContent sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: "2rem",
                
                }}>
                    <ErrorOutlineIcon sx={{
                        color: theme.palette.caralystRed.main,
                        marginBottom: "0.5rem",  
                        fontSize: "2rem"
                    }}/>
                    <Typography variant="h6" component="div" sx={{ 
                        display: 'flex', 
                        flexDirection: "row",
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                    }}>
                        You have been logged out due to inactivity. <br />
                        Please log in again.
                    </Typography>
                </DialogContent>
                <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: "0.5rem"}}>
                    <Button 
                        variant="raised" 
                        onClick={handleClose}
                        sx={{
                            color: "white",
                            backgroundColor: theme.palette.caralystGreen.main,
                            cursor: "pointer",
                            '&:hover': {
                                backgroundColor: theme.palette.caralystGreen.main,
                                filter: "brightness(0.9)",
                            }
                        }}
                    >
                        Ok!
                    </Button>
                </Box>
            </Dialog>
            :
            <Outlet />
    )
}

export default ErrorDialog;