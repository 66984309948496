import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { loginPatient, logout, setReportingABug } from '../../../redux/actions/user.actions';
import { selectLoggedIn } from '../../../redux/store';
import { useNavigate, useParams } from 'react-router-dom';

import { 
    Box, 
    Tooltip, 
    Menu, 
    MenuItem, 
    styled,
    Button,
    Drawer,
    useMediaQuery,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HomeIcon from '@mui/icons-material/Home';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SparkleIcon from '../../../assets/sparkle-icon.svg';
import Footer from '../../Misc/Footer';

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(grey[500]),
    border: `0.5px solid ${grey[500]}`,
    backgroundColor: grey[509],
    maxWidth: "1rem",
    '&:hover': {
        backgroundColor: grey[200],
        borderColor: grey[500],
        color: grey[900]
    },
}));

export const ProfileMenuButton = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const onMobile = !useMediaQuery('(min-width:600px)');
    const urlParams = window.location.pathname;

    const loggedIn = useSelector(selectLoggedIn);

    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [termsAndConditionsOpen, setTermsAndConditionsOpen] = useState(false);

    const onQuestionnaire = urlParams?.includes("onboard/");

    /***
     * Close the menu
     * 
     * @param {Event} e
     */
    const handleClose = (e) => {
        e.preventDefault();
        //When the menu is closed, remove anchor element, close menu, and close terms and conditions
        setAnchorEl(null);
        setOpen(false);
        setTermsAndConditionsOpen(false);
    }

    /***
     * Toggle the menu and terms and conditions state
     * 
     * @param {Event} e
     */
    const handleClick = (e) => {
        e.preventDefault();
        setOpen(!open);
        setTermsAndConditionsOpen(!termsAndConditionsOpen);
        setAnchorEl(e.currentTarget);
    }

    /***
     * Navigate to home page
     * 
     * @param {Event} e
     */
    const onClickGoHome = (e) => {
        e.preventDefault();
        handleClose(e);
        window.location.href = "/";
    }

    /***
     * Open report a bug dialog
     * 
     * @param {Event} e
     */
    const onClickReportABug = async (e) => {
        e.preventDefault();
        handleClose(e);
        await dispatch(setReportingABug(true));
    }

    /***
     * Navigate to questionnaire
     * 
     * @param {Event} e
     */
    const onClickGoToSurvey = (e) => {
        e.preventDefault();
        handleClose(e);
        navigate("/onboard/questionnaire/0")
    }

    /***
     * Login or logout depending on which the patient is on currently
     * 
     * @param {Event} e
     */
    const onClickLoginLogout = async (e) => {
        e.preventDefault();
        handleClose(e);
        loggedIn ? 
            await dispatch(logout()) 
            : 
            await dispatch(loginPatient());
      }

    return (
        <>
            <Box className='tour-step-2'>
                <Tooltip title="Account menu">
                    <ColorButton 
                        variant="outlined" 
                        onClick={(e) => handleClick(e)} 
                        sx={onMobile ? {
                            maxWidth: '3rem', 
                            maxHeight: '3rem', 
                            minWidth: '3rem', 
                            minHeight: '3rem',
                            borderRadius: "50%"
                        } : {}}
                    >
                        <PersonOutlineIcon sx={onMobile ? {height: "175%", width: "175%"} : {}}/>
                    </ColorButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                sx={{
                    borderRadius: "0.25rem",
                    minHeight: "10rem",
                    width: "20rem",
                    '& .MuiBackdrop-root': {height: "calc(100% - 8rem)", width: "100%"},
            }}>
                <MenuItem onClick={async (e) => await onClickLoginLogout(e)}>
                    <AccountCircleIcon sx={{ maxWidth: "1.5rem", maxHeight: "1.5rem", marginRight: "1.5rem"}}/> {loggedIn ? "Logout" : "Login"}
                </MenuItem>
                {onQuestionnaire ?
                    <MenuItem onClick={onClickGoHome}>
                        <HomeIcon sx={{ maxWidth: "1.5rem", maxHeight: "1.5rem", marginRight: "1.5rem"}} /> Home
                    </MenuItem>
                :
                    <MenuItem onClick={onClickGoToSurvey}>
                        <img src={SparkleIcon} alt="Sparkle Icon" style={{maxHeight: "3rem", margin: "-0.5rem 0.5rem -0.5rem -0.5rem" }}/>
                        Personalize Matches
                    </MenuItem>
                }
                {loggedIn && !onQuestionnaire &&
                    <MenuItem onClick={async (e) => await onClickReportABug(e)}>
                        <ErrorOutlineIcon sx={{ maxWidth: "1.5rem", maxHeight: "1.5rem", marginRight: "1.5rem"}}/> Report a Bug
                    </MenuItem>
                } 
            </Menu>
            <Drawer open={termsAndConditionsOpen} anchor="bottom" sx={{position: "absolute", '& .MuiBackdrop-root': {display: "none !important"}}}>
                <Footer />
            </Drawer>
        </>
    )
}

export default ProfileMenuButton;