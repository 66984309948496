import React, { useEffect, useState } from 'react';
import { Dialog, Box, Button, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemText, TextField, Typography, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { reportABug, setReportingABug } from '../../redux/actions/user.actions';
import { org } from '../../redux/constants/shared.constants.js';
import { selectReportingABug } from '../../redux/store';
import { setSnackbarMessage, setSnackbarOpen } from '../../redux/actions/shared.actions';

const ReportABug = () => {
    const theme = useTheme();
    const dispatch = useDispatch();

    const reportingABug = useSelector(selectReportingABug)

    const [error, setError] = useState("");

    useEffect(() => {}, [dispatch, reportingABug])

    /***
     * Close the report a bug dialog
     * 
     * @param {Event} e
     */
    const handleReportABugClose = async (e) => {
        e.preventDefault();
        await dispatch(setReportingABug(false));
    }

    /***
     * Update the bug summary
     * 
     * @param {Event} e
     */
    const handleUpdateBugSummary = (e) => {
        e.preventDefault();
        const content = e.target.value;
        sessionStorage.setItem("bugSummary", JSON.stringify(content));
    }

    /***
     * Update the bug description
     * 
     * @param {Event} e
     */
    const handleUpdateBugDescription = (e) => {
        e.preventDefault();
        const content = e.target.value;
        sessionStorage.setItem("bugDescription", JSON.stringify(content));
    }

    /***
     * Update the bug images
     * 
     * @param {Event} e
     */
    const handleUpdateBugImages = (e) => {
        e.preventDefault();
        // Check if no files were uploaded
        if(e.target.files.length === 0) return;
        // Check if more than 5 files were uploaded
        if(e.target.files.length > 5) {
            setError("You can only upload up to 5 images!");
            return;
        }
        // Add images to sessionStorage and create image urls
        const images = [...e.target.files];
        sessionStorage.setItem("bugImages", JSON.stringify(images));
        const newImageUrls = [];
        images.forEach(img => { newImageUrls.push(URL.createObjectURL(img)) })
        sessionStorage.setItem("bugImageUrls", JSON.stringify(newImageUrls));
    }

    /***
     * Delete a bug image
     * 
     * @param {Event} e
     * @param {Number} index
     */
    const handleDeleteBugImage = (e, index) => {
        e.preventDefault();
        //remove images and urls from sessionStorage
        const newImageUrls = JSON.parse(sessionStorage.getItem("bugImagesUrls"))?.filter((img, i) => i !== index);
        const newImages = JSON.parse(sessionStorage.getItem("bugImages"))?.filter((img, i) => i !== index);
        sessionStorage.setItem("bugImages", JSON.stringify(newImages));
        sessionStorage.setItem("bugImageUrls", JSON.stringify(newImageUrls));
    }

    /***
     * Submit the bug peport
     * 
     * @param {Event} e
     */
    const submitBugReport = async (e) => {
        e.preventDefault();
        setError("");
        await dispatch(setSnackbarMessage("Submitting bug report..."));
        await dispatch(setSnackbarOpen(true));
        //Get summary, description, and images from sessionStorage
        const bugSummary = JSON.parse(sessionStorage.getItem("bugSummary"));
        const bugDescription = JSON.parse(sessionStorage.getItem("bugDescription"));
        const bugImages = JSON.parse(sessionStorage.getItem("bugImages"));
        //Don't submit if summary is empty
        if(bugSummary === "") {
            setError("Please put input a summary for your bug!");
            return
        }
        if(error) return;
        // update redux store and send bug report to Jira
        const res = await dispatch(reportABug(bugSummary, bugDescription, bugImages, org));
        if(res) {
            //if successful, remove bug report from sessionStorage and let the user know
            await dispatch(setSnackbarMessage("Sumbitted! Thank you for helping improve Caralyst!"));
            sessionStorage.removeItem("bugSummary");
            sessionStorage.removeItem("bugDescription");
            sessionStorage.removeItem("bugImages");
            sessionStorage.removeItem("bugImageUrls");
        }
        //otherwise, let the user know something went wrong
        else await dispatch(setSnackbarMessage("Hm... something went wrong. If this issue keeps happening, please send us an email at eng@caralyst.io"));
        await dispatch(setSnackbarOpen(true));
        await handleReportABugClose(e);
    }

    return (
        <>
            <Dialog
                open={reportingABug}
                onClose={async (e) => await handleReportABugClose(e)}
            >
                <DialogTitle>
                    <Typography
                        variant='h5'
                        fontWeight={500}
                    >
                        Thank you!
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        Thank you for reporting an issue with our platform. We will do our best to address this as soon as possible
                    </Typography>
                    <Box sx={{minHeight: "1rem"}}/>
                    <Box>
                        To report a bug please:
                        <List>
                            <ListItem>
                                <ListItemText primary="1. Provide us with a brief (20 words max) summary of the bug"/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="2. Provide a longer description to explain what steps we can take to replicate this bug/issue and any other information you think is important."/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="3. If applicable, attach any images you think would help us find/resolve the issue."/>
                            </ListItem>
                        </List>
                    </Box>
                    <Box
                        aria-label="report-bug-form"
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Typography sx={{color: "red", fontWeight:"500", marginBottom: "0.5rem"}}>{error}</Typography>
                        <TextField 
                            label="Summary" 
                            size='small'
                            onChange={handleUpdateBugSummary}
                            defaultValue={JSON.parse(sessionStorage.getItem("bugSummary"))}
                            sx={{
                                width: "100%",
                            }}
                        />
                        <TextField 
                            label="Description" 
                            multiline
                            rows={4}
                            sx={{ 
                                marginTop: "0.5rem",
                                width: "100%",
                            }}
                            onChange={handleUpdateBugDescription}
                            defaultValue={JSON.parse(sessionStorage.getItem("bugDescription"))}
                        />
                        <Button
                            variant="contained"
                            component="label"
                            sx={{
                                backgroundColor: theme.palette.caralystGreen.main,
                                marginBottom: "0.5rem",
                                '&:hover': {
                                    backgroundColor: theme.palette.caralystGreen.dark,
                                },
                            }}
                        >
                            Upload Images
                            <input 
                                type="file" 
                                multiple 
                                accept="image/*" 
                                hidden
                                onChange={handleUpdateBugImages}
                                key={JSON.parse(sessionStorage.getItem("bugImages"))?.length}
                            />
                        </Button>
                        {
                            JSON.parse(sessionStorage.getItem("bugImageUrls"))?.length > 0 &&
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    minHeight: "5rem",
                                    minWidth: "100%",
                                    border: "1px solid rgba(0, 0, 0, 0.23)",
                                    borderRadius: "0.25rem",
                                    marginBottom: "4rem",
                                    overflowY: "scroll",
                                }}
                            >
                                {JSON.parse(sessionStorage.getItem("bugImageUrls"))?.map((image, index) => (
                                    <Box
                                        key={index}
                                        sx={{
                                            position: "relative",
                                            marginTop: "1rem",
                                            maxWidth: "7.5rem",
                                            maxHeight: "8rem",
                                            marginBottom: "0.5rem",
                                            marginLeft: "0.25rem",
                                            marginRight: "0.25rem",
                                        }}
                                    >
                                        
                                        <img src={image} alt="bugImage" width="100%" height="100%"/>
                                        <IconButton
                                            onClick={(e) => handleDeleteBugImage(e, index)}
                                            sx={{
                                                position: "absolute",
                                                right: "-0.25rem",
                                                top: "-0.25rem",
                                            }}
                                        >
                                            <CloseIcon 
                                                sx={{
                                                    fontSize: "1rem",
                                                    backgroundColor: "white",
                                                    color: theme.palette.caralystRed.main,
                                                    borderRadius: "50%",
                                                    '&:hover': {
                                                        color: theme.palette.caralystRed.dark,
                                                    },  
                                                }}
                                            />
                                        </IconButton>
                                    
                                    </Box>
                                ))}
                            </Box>
                        }
                        <Button 
                            variant="raised"
                            onClick={async (e) => await submitBugReport(e)}
                            sx={{
                                position: "relative",
                                marginTop: "1rem",
                                maxWidth: "4rem",
                        }}>
                            Submit
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default ReportABug;