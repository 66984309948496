import React, {useEffect, useState } from "react"

import { useDispatch, useSelector } from "react-redux";
import { selectLeftRightBounds, selectLocation, selectLoggedIn, selectModalityPreference, selectPatientId, selectPreferredInsurance, selectPreferredSpecialty, selectTempDocId, selectTopBottomBounds } from "../../../redux/store";
import { getInsuranceOptions, getSpecialtyOptions, updateUserLatLong, setPreferredInsurance, setPreferredSpecialty, setUserLocation } from "../../../redux/actions/user.actions";
import { org } from '../../../redux/constants/shared.constants.js';

import { AppBar, Box, Button, Divider, Modal, Typography } from "@mui/material"
import SearchIcon from '@mui/icons-material/Search';
import Slide from '@mui/material/Slide';
import { useTheme } from "@mui/material/styles";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';

import NavbarAutoComplete from "./NavbarAutoComplete";
import caralyst_logo from '../../../assets/caralyst_logo_multi_1.svg';
import ProfileMenuButton from "./ProfileMenuButton";
import { setSnackbarMessage, setSnackbarOpen } from "../../../redux/actions/shared.actions";
import { loadDoctors } from "../../../redux/actions/doctors.actions.js";

export const MobileNavbar = () => {
    const dispatch = useDispatch();

    const loggedIn = useSelector(selectLoggedIn);
    const insurancePreference = useSelector(selectPreferredInsurance);
    const specialtyPreference = useSelector(selectPreferredSpecialty);
    const userLocation = useSelector(selectLocation);
    const patientId = useSelector(selectPatientId);
    const leftRightBounds = useSelector(selectLeftRightBounds);
    const topBottomBounds = useSelector(selectTopBottomBounds);
    const tempDocId = useSelector(selectTempDocId);
    const modalityPreference = useSelector(selectModalityPreference);
    
    const [location, setLocation] = useState(userLocation);
    const [preferredSpecialty, setSpecialtyPreference] = useState(specialtyPreference);
    const [preferredInsurance, setInsurnacePreference] = useState(insurancePreference);

    const navTextStyle = {
        fontSize: "0.65em",
    }
    const theme = useTheme();
    const [navStatus, setNavStatus] = useState(false);

    useEffect(() => {
        setSpecialtyPreference(specialtyPreference);
        setInsurnacePreference(insurancePreference);
        setLocation(userLocation);
    }, [userLocation, insurancePreference, specialtyPreference]);
    /***
     * Function to open navbar modal
     * 
     * @param {Event} e
     */
    const handleOpen = (e) => {
        e.preventDefault();
        setNavStatus(true);
    }

    /***
     * Function to close navbar modal
     * 
     * @param {Event} e
     */
    const handleClose = (e) => {
        e.preventDefault();
        setNavStatus(false);
    }
    
    /***
     * Function to update user preferences
     * 
     * @param {Event} e
     */
    const submitSearch = async (e) => {
        e.preventDefault();
        await dispatch(setSnackbarMessage("Updating your preferences..."));
        await dispatch(setSnackbarOpen(true));
        //If the user has changed a value, it should be updated

        if(location !== userLocation) {
            await dispatch(setUserLocation(location));
            await dispatch(updateUserLatLong(e, location, patientId));
        }

        if(preferredSpecialty !== specialtyPreference) await dispatch(setPreferredSpecialty(preferredSpecialty, org, loggedIn, patientId));
        if(preferredInsurance !== insurancePreference) await dispatch(setPreferredInsurance(preferredInsurance, org, loggedIn, patientId));

        await dispatch(loadDoctors(patientId, leftRightBounds, topBottomBounds, preferredSpecialty, preferredInsurance, true, tempDocId, modalityPreference))
        await dispatch(setSnackbarMessage("Preferences updated!"));
        await dispatch(setSnackbarOpen(true));
    }
    return (
        <AppBar sx={{
            position: "fixed",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-around",
            width: "100%",
            height: "5rem",
            backgroundColor: "white",
            zIndex: (theme) => theme.zIndex.drawer + 2 ,
        }}>
            <Box
                onClick={handleOpen}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "80vw",
                    height: "3rem",
                    backgroundColor: '#f2f0f0',
                    borderRadius: "1.25rem",
                    border: '1px solid lightgrey',
                    overflow: "hidden",
            }}>
                <SearchIcon sx={{
                    color: 'darkgrey',
                    marginLeft: "0.5rem",
                    marginRight: "-0.5rem",
                    fontSize: "1.5em",
                    width: "7%",
                }}/>
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: "5%",
                    width: "90%",
                    flexWrap: "wrap",
                    color: "black",
                    fontWeight: "500",
                    fontSize: "1.25em",
                    lineHeight: "1em"
                }}>
                    
                    <Typography sx={navTextStyle}>
                        {specialtyPreference && specialtyPreference.length > 0 ? 
                            specialtyPreference.length === 1 ? 
                                "1 Specialty" 
                                :
                                `${specialtyPreference.length} Specialties` : "Specialty"}
                    </Typography>
                    &nbsp;&#183;&nbsp;
                    <Typography sx={navTextStyle}>
                        {insurancePreference && insurancePreference.length > 0 ?
                            insurancePreference.length === 1 ?
                                "1 Insurance"
                                :
                                `${insurancePreference.length} Insurances` : "Insurance"}
                    </Typography>
                    &nbsp;&#183;&nbsp;
                    <Typography sx={{...navTextStyle, overflow: "hidden", textOverflow: "ellipsis", maxWidth: "30%", whiteSpace: "nowrap"}}>
                        {userLocation ? userLocation : "Location"}
                    </Typography>
                </Box>
            </Box>
           
            <Box sx={{
                marginRight: "2vw",
            }}>
                <ProfileMenuButton />
            </Box>
            <Modal
                open={navStatus}
                onClose={handleClose}
                closeAfterTransition
            >
                <Slide
                    direction="down"
                    in={navStatus}
                    mountOnEnter
                    unmountOnExit
                >
                    <Box sx={{ 
                        display: "flex", 
                        flexDirection: "column",
                        backgroundColor: "white",
                        width: "100%",
                    }}>
                        <Box aria-label="top-of-nav"
                            sx={{
                                display: "flex",
                                flexDirection:"row",
                                justifyContent: "space-between"
                        }}>
                            <Box sx={{
                                paddingLeft: "0.5rem",
                                marginTop: "0.5rem"
                            }}>
                                <img src={caralyst_logo} style={{maxHeight: "2rem"}}/>
                            </Box>
                        </Box>
                        
                        <Box aria-label="mobile-nav-body"
                            sx={{
                                margin: "1rem",
                                backgroundColor: '#f2f0f0',
                                borderRadius: "1rem",
                                border: '1px solid lightgrey'
                        }}>
                            <Box sx={{
                                marginTop: "1rem",
                            }}>
                                <Box sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        marginLeft: "1rem",
                                        marginTop: "0.25rem",
                                        color: "black"
                                }}>
                                    <SearchIcon />
                                    <NavbarAutoComplete type="specialty" mobile={true} preferedSpecialty={preferredSpecialty} setSpecialtyPreference={setSpecialtyPreference}/>
                                </Box>
                                <Divider 
                                    variant="horizontal"
                                    sx={{
                                        maxWidth: "90%",
                                        marginLeft: "5%",
                                }}/>
                                <Box sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        marginLeft: "1rem",
                                        marginTop: "0.25rem",
                                        color: "black"
                                }}>
                                    <MedicalInformationIcon />
                                    <NavbarAutoComplete type="insurance" mobile={true} preferedInsurance={preferredInsurance} setInsurancePreference={setInsurnacePreference}/>
                                </Box>
                                <Divider 
                                    variant="horizontal"
                                    sx={{
                                        maxWidth: "90%",
                                        marginLeft: "5%",
                                }}/>
                                <Box sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        marginLeft: "1rem",
                                        marginTop: "0.25rem",
                                        color: "black"
                                }}>
                                    <LocationOnIcon />
                                    <NavbarAutoComplete type="location" mobile={true} location={location} setLocation={setLocation} />
                                </Box>
                                <Divider 
                                    variant="horizontal"
                                    sx={{
                                        maxWidth: "90%",
                                        marginLeft: "5%",
                                }}/>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        minHeight: "3rem"
                                }}>
                                    <Button 
                                        onClick={async (e) => await submitSearch(e)}
                                        sx={{
                                            backgroundColor: theme.palette.caralystRed.main,
                                            color: "white",
                                            borderRadius: "1rem",
                                            minWidth: "8rem",
                                            maxHeight: "2rem",
                                            boxShadow: "2 2 2 2 rgba(0,0,0,0.5)"
                                    }}>
                                        <Typography sx={{
                                            color: "white", 
                                            textTransform: "none", 
                                            fontWeight: "600"
                                        }}>
                                            Find Care
                                        </Typography>
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Slide>
            </Modal>
        </AppBar>
    )
}