import React, { useEffect, useState } from 'react';

import { FormControl, ToggleButton } from '@mui/material';
import { useTheme } from "@mui/material/styles";

const RadioButtonQuestion = ({index, answer, changeSelectedAnswer, updateAndUploadMapping }) => {
    const theme = useTheme();

    const [selected, setSelected] = useState(answer.selected);

    useEffect(() => {
        setSelected(answer.selected);
    }, [answer.selected])

    //Handle the radio button change
    const onRadioChanged = async (e) =>{
        e.preventDefault();
        //If user just clicked the selected radio button, unselect it
        changeSelectedAnswer(answer.id, answer.value);
        await updateAndUploadMapping();
    }

    return (
        <FormControl
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                maxWidth: "40rem",
                width: "100%",
                minWidth: "20rem"
            }}
        >
            <ToggleButton
                key={index}
                selected={selected}
                onClick={async (e) => await onRadioChanged(e, answer.id)}
                onTouchEnd={async (e) => await onRadioChanged(e, answer.id)}
                sx={{
                    margin: "0.5rem",
                    backgroundColor: theme.palette.caralystRed.dark,
                    width: '40vw',
                    minWidth: "18rem",
                    maxWidth: "35rem",
                    color: "white",
                    transition: "background-color 0.15s ease-in-out",
                    textTransform: "none",
                    fontSize: "1.2em",
                    '&:hover': {
                        backgroundColor: theme.palette.caralystRed.main,
                        color: "white",
                    },
                    '&.Mui-selected': {
                        backgroundColor: theme.palette.caralystRed.main,
                        color: "white",
                    },
                    '&.Mui-selected:hover': {
                        backgroundColor: theme.palette.caralystRed.main,
                        color: "white",
                    }
                }}
            >
                {answer.text}
            </ToggleButton>
        </FormControl>
    );
}

export default RadioButtonQuestion;