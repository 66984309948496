

import React from 'react';
import '../../css/Footer.css';
import CaralystLogo from '../../assets/caralyst_logo.svg';
import { Box } from '@mui/material';
const Footer = () => {
    const logoLink = () => {
    }

    const onClickLink = (e, link) => {
        e.preventDefault();
        window.open(link)
    }

    return (
        <Box className="footer" sx={{
            padding: "2rem 0 1rem 0",
            display: "flex",
            flexFlow: "column nowrap",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            backgroundColor: "#FFFFFF",
            position: "sticky",
            gap: "1.25rem",
            zIndex: (theme) => theme.zIndex.modal + 1,
        }}>
            <img onClick={logoLink} src={CaralystLogo} width="95"/>
            <div className="links">
                <a href="https://caralyst.io">ABOUT US</a>
                <a href="#" onClick={e => onClickLink(e, "https://documents.caralyst.io/Online+Privacy+Policy.docx.pdf")}>PRIVACY POLICY</a>
                <a href="#" onClick={e => onClickLink(e, "https://documents.caralyst.io/Website+Terms+of+Use.docx.pdf")}>TERMS OF SERVICE</a>
                <a href="https://forms.gle/VcaSfnVHZsfPYytR7">FEEDBACK</a>
            </div>
            <span className="arr">All rights reserved ©</span>
        </Box>
    );
}

export default Footer;
