export const SET_LOCATION = "SET_LOCATION";
export const SET_INDEX = "SET_INDEX";
export const LOGOUT = 'logout';
export const SET_PREFERRED_SPECIALTY = "SET_PREFERRED_SPECIALTY";
export const SET_PREFERRED_INSURANCE = "SET_PREFERRED_INSURANCE";
export const SET_LOADING = "SET_LOADING";
export const SET_LOGGED_IN = "SET_LOGGED_IN";
export const SET_ORG = "SET_ORG";
export const SET_LAT_LONG = "SET_LAT_LONG";
export const SET_REPORTING_A_BUG = "SET_REPORTING_A_BUG";
export const SET_INSURANCE_OPTIONS = "SET_INSURANCE_OPTIONS";
export const SET_SPECIALTY_OPTIONS = "SET_SPECIALTY_OPTIONS";
export const SET_PERSON_CARD_OPEN = "SET_PERSON_CARD_OPEN";
export const SET_TERMS_AND_CONDITIONS_OPEN = "SET_TERMS_AND_CONDITIONS_OPEN";
export const SET_PATIENT_ID = "SET_PATIENT_ID";
export const SET_LOGGING_OUT = "SET_LOGGING_OUT";
export const SET_LOGGING_IN = "SET_LOGGING_IN";
export const SET_MAP_BOUNDS = "SET_MAP_BOUNDS"; 
export const SET_SKELETON_SCREEN_OPEN = "SET_SKELETON_SCREEN_OPEN";
export const SET_POPUP_SURVEY_STATUS = "SET_POPUP_SURVEY_STATUS";
export const SET_LAST_SEEN = "SET_LAST_SEEN";
export const SET_ON_FIRST_TUTORIAL = "SET_ON_FIRST_TUTORIAL";
export const SET_ON_SECOND_TUTORIAL = "SET_ON_SECOND_TUTORIAL";
export const SET_MAP_CENTER = "SET_MAP_CENTER";
export const SET_MODALITY_PREFERENCE = "SET_MODALITY_PREFERENCE";
export const SET_DISTANCE_PREFERENCE = "SET_DISTANCE_PREFERENCE";