import React, { useState, useEffect } from 'react';

import { 
    Typography, 
    Slider, 
    Accordion, 
    AccordionSummary, 
    AccordionDetails,
    Box,
    useMediaQuery,
} from '@mui/material';

import { useTheme } from "@mui/material/styles";

const SliderQuestion = ({index, answer, format, changeSelectedAnswer, updateAndUploadMapping }) => {
    const theme = useTheme();
    const onMobile = !useMediaQuery('(min-width:600px)');

    const expandingMode = (format === "EXPANDING_SLIDER");

    const [isFocused, setIsFocused] = useState("");
    const [defaultValue, setDefaultValue] = useState(answer.selected);

    useEffect(() => {
        setDefaultValue(answer.selected);
    }, [answer.selected])
    
    const valueLabelFormat = (defaultValue) => {
        const blocks = answer.render;
        return blocks[defaultValue];
    }

    const handleSliderChange = async (e, value) => {
        e.preventDefault();
        changeSelectedAnswer(answer.id, value);
        await updateAndUploadMapping();
    }

    const handleAccordionChange = async (event, id, expanded, value) => {
        if (!expanded) value = 0
            await handleSliderChange(event, value);
    }

    const handleFocus = (e, id) => {
        e.preventDefault();
        setIsFocused(id);
      };
    
      const handleBlur = () => {
        setIsFocused("");
      };

    const getStep = () => {
        if (!(answer?.render && typeof answer.render === 'object')) return 0.25;
        if(Object.keys(answer.render).length === 3) return 0.5;
        else return 0.25;
    }


    const TraitSlider = (
        <Slider
            aria-label="Always visible"
            className="answer-card-slider"
            key={answer.id}
            defaultValue={answer.selected}
            step={getStep()}
            min={0}
            max={1}
            valueLabelDisplay={!expandingMode || isFocused === answer.id ? "on" : "off"}
            valueLabelFormat={valueLabelFormat}
            onFocus={e => handleFocus(e, answer.id)}
            onBlur={handleBlur}
            onChange={async (e) => await handleSliderChange(e, e.target.value) }
            sx={{
                marginTop: "2.5rem",
                color: "white",
                '& .MuiSlider-valueLabel': {
                    position: onMobile && (defaultValue === 0 || defaultValue === 1) ? "relative !important" : "fixed !important",
                    zIndex: "1000 !important",
                    whiteSpace: "normal !important",
                    width: onMobile ? "15rem !important" : "25rem !important",
                    
                },
                '& .MuiSlider-valueLabelOpen': {
                    position: "fixed !important",
                    left: onMobile && (defaultValue === 0) ? "0% !important" : "none",
                    right: onMobile && (defaultValue === 1) ? "0% !important" : "none",
                },
                '& .MuiSlider-valueLabelOpen:before': {
                    left: onMobile && (defaultValue === 0) ? 
                        "5% !important" 
                        :
                        onMobile && (defaultValue === 1) ?
                            "95% !important" 
                            :
                            "50% !important",
                },
            }}
        />
    )

    return (
        <Box className='accordion-choices'
            sx={{
                display: "flex",
                height: "100%",
                width: '40vw',
                minWidth: "18rem",
                maxWidth: "25rem",
                flexWrap: "wrap",
                flexDirection: "row",
                alignItems: "center",
                gap: "20px",
                justifyContent: "center",
            }}
        >
            {
                !expandingMode ?
                    // sliders only
                    <Accordion
                        onChange={async (event, expanded) => await handleAccordionChange(event, answer.id, expanded, defaultValue)}
                        defaultExpanded={defaultValue > 0}
                        disableGutters
                        sx={{
                            color: "white",
                            backgroundColor: theme.palette.caralystRed.main,
                            borderRadius: "0.25rem",
                            width: "100%",
                            overflow: "visible",
                        }}
                    >
                        <AccordionDetails>
                            {TraitSlider}
                        </AccordionDetails>
                    </Accordion>
                :
                    <Accordion
                        onChange={async (event, expanded) => await handleAccordionChange(event, answer.id, expanded, defaultValue)}
                        defaultExpanded={defaultValue > 0}
                        disableGutters
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            color: "white",
                            backgroundColor: theme.palette.caralystRed.main,
                            width: "100%",
                            minHeight: "4rem",
                            filter: "brightness(75%)",
                            borderRadius: "0.25rem",
                            transition: "all 0.1s ease-in-out",
                            zIndex: answer.id === isFocused ? "1000" : "0",
                            '&.Mui-expanded': {
                                maxHeight: !onMobile ? "10rem" : "15rem",
                                backgroundColor: theme.palette.caralystRed.main,
                                filter: "brightness(100%)",
                            }
                        }}
                    >
                        <AccordionSummary aria-controls="panel1a-content" key={`panel${index}`}>
                            <Typography 
                                className='answer-card-title' 
                                color="white" 
                                fontWeight={500}
                                fontSize="1.25rem"
                            >
                                {"Former answer for patient"}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{marginTop: "-1rem", marginBottom: "-0.5rem"}}>
                            <Typography variant="h7">{"How important is this to your care?"}</Typography>
                            {TraitSlider}
                        </AccordionDetails>
                    </Accordion>
            }
        </Box>)
}

export default SliderQuestion;