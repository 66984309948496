import React, { useEffect, useState } from "react";
import { Checkbox, Typography, Box, FormControlLabel, FormGroup, TextField, Button, Rating, useTheme, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from "react-redux";
import { setPopupSurveyOpen, setPopupSurveyStatus, setSnackbarMessage, setSnackbarOpen } from "../../redux/actions/shared.actions";
import { selectLoggedIn, selectPopupSurveyOpen, selectPopupSurveyStatus } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import { savePopupSurveyResponses } from "../../redux/actions/user.actions";
import { org } from "../../redux/constants/shared.constants";

const PopupSurvey = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const navigate = useNavigate();

    const popupSurveyOpen = useSelector(selectPopupSurveyOpen);
    const popupSurveyStatus = useSelector(selectPopupSurveyStatus);
    const loggedIn = useSelector(selectLoggedIn);

    const [previousTherapyAttendance, setPreviousTherapyAttendance] = useState(null);
    const [previousTherapySource, setPreviousTherapySource] = useState("");
    const [caralystEasierThanPast, setCaralystEasierThanPast] = useState(null);
    const [whyNoTherapy, setWhyNoTherapy] = useState([]);
    const [moreDetails, setMoreDetails] = useState("");
    const [recommendCaralyst, setRecommendCaralyst] = useState(null);
    const [caralystEasierThanOtherwise, setCaralystEasierThanOtherwise] = useState(null);
    const [recommendCaralystFeedback, setRecommendCaralystFeedback] = useState("");
    const [caralystEasierThanOtherwiseFeedback, setCaralystEasierThanOtherwiseFeedback] = useState("");
    const [caralystExperienceRating, setCaralystExperienceRating] = useState(null);
    const [caralystExperienceRatingFeedback, setCaralystExperienceRatingFeedback] = useState("");
    const [caralystEasyToUse, setCaralystEasyToUse] = useState(null);
    const [caralystEasyToUseFeedback, setCaralystEasyToUseFeedback] = useState("");

    const [surveyAnsweredDialogOpen, setSurveyAnsweredDialogOpen] = useState(false);

    useEffect(() => {
        const asyncInitPopupSurvey = async () => {
            //user should not be able to access popup survey if not wustl org or logged in
            if(org !== "wustl" || !loggedIn) 
                await onClosePopupSurvey(new Event("click"));
            //If navigated here from survey_link, remove the link to avoid infinite loop
            if(localStorage.getItem("survey_link"))
                localStorage.removeItem("survey_link");
        }
        asyncInitPopupSurvey();
    }, [dispatch, popupSurveyStatus])

    useEffect(() => {
        //If the user has submitted the survey, or if the user has skipped the survey and the survey is open, let them know
        if(popupSurveyStatus === "submitted" || (popupSurveyOpen && popupSurveyStatus === "skipped")) 
            openSurveyAnsweredDialog();
    }, [])

    /***
     * Function to handle opening of dialog to let user know they have already answered the survey
     */
    const openSurveyAnsweredDialog = () => {
        setSurveyAnsweredDialogOpen(true);
    }

    /***
     * Function to handle closing of dialog to let user know they have already answered the survey
     * 
     * @param {Event} e
     */
    const closeSurveyAnsweredDialog = async (e) => {
        e.preventDefault();
        setSurveyAnsweredDialogOpen(false);
        await dispatch(setPopupSurveyOpen(false));
        if(!popupSurveyOpen)
            navigate("/home");
    }

    /***
     * Function to update the state of previous therapy source question
     * 
     * @param {Event} e
     * @param {String} val
     */
    const updatePreviousTherapyAttendance = (e, val) => {
        e.preventDefault();
        if(val) {
            setPreviousTherapySource("");
            setCaralystEasierThanPast(null);
            setMoreDetails("");
        } else if(!val) {
            setWhyNoTherapy([]);
        }
        setPreviousTherapyAttendance(val);
    }

    /***
     * Function to update the state of previous therapy source question
     * 
     * @param {Event} e
     * @param {String} val
     */
    const updatePreviousTherapySource = (e, val) => {
        e.preventDefault();
        setPreviousTherapySource(val);
    }

    /***
     * Function to update the state of caralyst easier than past processes question
     * 
     * @param {Event} e
     * @param {String} val
     */
    const updateCaralystEasierThanPast = (e, val) => {
        e.preventDefault();
        setCaralystEasierThanPast(val);
    }

    /***
     * Function to update the state of more details question
     * 
     * @param {Event} e
     */
    const updateMoreDetails = (e) => {
        e.preventDefault();
        setMoreDetails(e.target.value);
    }

    /***
     * Function to update the state of why no previous therapy question
     * 
     * @param {Event} e
     * @param {String} val
     */
    const updateWhyNoTherapy = (e, val) => {
        e.preventDefault();
        setWhyNoTherapy(val);
    }

    /***
     * Function to update the state of would the user recommend caralyst question
     * 
     * @param {Event} e
     * @param {String} val
     */
    const updateRecommendCaralyst = (e, val) => {
        e.preventDefault();
        if(val === true) setRecommendCaralystFeedback("");
        setRecommendCaralyst(val);
    }

    /***
     * Function to update the state of would the user recommend caralyst feedback question
     * 
     * @param {Event} e
     */
    const updateRecommendCaralystFeedback = (e) => {
        e.preventDefault();
        setRecommendCaralystFeedback(e.target.value);
    }

    /***
     * Function to update the state of caralyst easier than otherwise question
     * 
     * @param {Event} e
     * @param {String} val
     */
    const updateCaralystEasierThanOtherwise = (e, val) => {
        e.preventDefault();
        if(val === true) setCaralystEasierThanOtherwiseFeedback("");
        setCaralystEasierThanOtherwise(val);
    }

    /***
     * Function to update the state of caralyst easier than otherwise feedback question
     * 
     * @param {Event} e
     */
    const updateCaralystEasierThanOtherwiseFeedback = (e) => {
        e.preventDefault();
        setCaralystEasierThanOtherwiseFeedback(e.target.value);
    }

    /***
     * Function to update the state of caralyst experience rating question
     * 
     * @param {Event} e
     */
    const updateCaralystExperienceRating = (e) => {
        e.preventDefault();
        if(e.target.value > 2) setCaralystExperienceRatingFeedback("");
        setCaralystExperienceRating(e.target.value);
    }

    /***
     * Function to update the state of caralyst experience rating feedback question
     * 
     * @param {Event} e
     */
    const updateCaralystExperienceRatingFeedback = (e) => {
        e.preventDefault();
        setCaralystExperienceRatingFeedback(e.target.value);
    }

    /***
     * Function to update the state of caralyst easy to use question
     * 
     * @param {Event} e
     */
    const updateCaralystEasyToUse = (e) => {
        e.preventDefault();
        if(e.target.value > 2) setCaralystEasyToUseFeedback("");
        setCaralystEasyToUse(e.target.value);
    }

    /***
     * Function to update the state of caralyst easy to use feedback question
     * 
     * @param {Event} e
     */
    const updateCaralystEasyToUseFeedback = (e) => {
        e.preventDefault();
        setCaralystEasyToUseFeedback(e.target.value);
    }

    /***
     * Function to submit the survey
     * 
     * @param {Event} e
     */
    const submitSurvey = async (e) => {
        e.preventDefault();

        const questionsAndAnswers = {
            previousTherapyAttendance: previousTherapyAttendance,
            previousTherapySource: previousTherapySource,
            caralystEasierThanPast: caralystEasierThanPast,
            whyNoTherapy: whyNoTherapy,
            moreDetails: moreDetails,
            recommendCaralyst: recommendCaralyst,
            recommendCaralystFeedback: recommendCaralystFeedback,
            caralystEasierThanOtherwise: caralystEasierThanOtherwise,
            caralystEasierThanOtherwiseFeedback: caralystEasierThanOtherwiseFeedback,
            caralystExperienceRating: caralystExperienceRating,
            caralystExperienceRatingFeedback: caralystExperienceRatingFeedback,
            caralystEasyToUse: caralystEasyToUse,
            caralystEasyToUseFeedback: caralystEasyToUseFeedback,
        };
        const res = await dispatch(savePopupSurveyResponses(questionsAndAnswers, org));

        if(!res) {
            await dispatch(setSnackbarMessage("Sorry! Your survey couldn't be submitted. Please try again later."));
            await dispatch(setSnackbarOpen(true));
            return;
        } else {
            await dispatch(setSnackbarMessage("Thank you for your feedback!"));
            await dispatch(setPopupSurveyStatus("submitted"));
            await dispatch(setSnackbarOpen(true));
        }
        setTimeout(async () => {
            if(popupSurveyOpen)
                await onClosePopupSurvey(e);
            else 
                navigate("/home")
        }, [])
    }

    /***
     * Function to close the survey
     * 
     * @param {Event} e
     */
    const onClosePopupSurvey = async (e) => {
        e.preventDefault();
        await dispatch(setPopupSurveyOpen(false));
    }

    /***
     * Function to close the survey when the user has clicked the close button
     * If they intentionally close the survey without submitting, this does not count as answering the survey
     * 
     * @param {Event} e
     */
    const onClickClosePopupSurvey = async (e) => {
        e.preventDefault();
        await dispatch(savePopupSurveyResponses(null, org));
        await dispatch(setPopupSurveyStatus("skipped"));
        if(popupSurveyOpen)
            await onClosePopupSurvey(e);
        else 
            navigate("/home")
    }

    return (
        <>
            <Box sx={{
                position: 'absolute',
                top: 'calc(50vh + 2rem)',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                display: 'flex',
                flexDirection: 'column',
                width: "90vw",
                maxWidth: '58rem',
                maxHeight: '70vh',
                bgcolor: 'white',
                borderRadius: '0.5rem',
                boxShadow: 24,
                padding: "1rem",
                pt: 2,
                px: 4,
                pb: 3,
                overflowY: 'auto',
            }}>
                {popupSurveyOpen && 
                <IconButton sx={{
                    position: 'fixed',
                    top: '1rem',
                    right: '1rem',
                }} >
                    <CloseIcon onClick={async (e) => await onClickClosePopupSurvey(e)} />
                </IconButton>}
                <Box id="title" sx={{
                    margin: "0.5rem"
                }}>
                    <Typography variant="h4" component="div">
                        Welcome!
                    </Typography>
                    <Box sx={{minHeight: "0.5rem"}}/>
                    <Typography variant="string" component="div">
                        Thank you for using Caralyst to find a therapist. 
                        We would like to ask you a few questions to help us improve our service.
                    </Typography>
                    <Box sx={{minHeight: "1rem"}}/>
                </Box>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                }}>
                    <Box sx={{
                        margin: "0.5rem"
                    }}>
                        <Typography variant="h6" component="div">
                            Have you attended therapy before?
                        </Typography>
                        <FormGroup
                            sx={{
                                marginLeft: "1rem"
                            }}
                        >
                            <FormControlLabel 
                                control={<Checkbox checked={previousTherapyAttendance === true} onClick={e => updatePreviousTherapyAttendance(e, true)} color="caralystGreen"/>} 
                                label="Yes" 
                            />
                            <FormControlLabel 
                                control={<Checkbox checked={previousTherapyAttendance === false} onClick={e => updatePreviousTherapyAttendance(e, false)} color="caralystGreen"/>} 
                                label="No" 
                            />
                        </FormGroup>
                    </Box>
                    {previousTherapyAttendance === true &&
                        <Box>
                            <Box sx={{
                                margin: "0.5rem"
                            }}>
                                <Typography variant="h6" component="div">
                                    How did you find your therapist in the past? (Select all that apply)
                                </Typography>
                                <FormGroup
                                    sx={{
                                        marginLeft: "1rem"
                                    }}
                                >
                                    <FormControlLabel 
                                        control={
                                            <Checkbox 
                                                checked={previousTherapySource === "Word of mouth or referral from a friend or family member"}
                                                onClick={e => updatePreviousTherapySource(e, "Word of mouth or referral from a friend or family member")}
                                                color="caralystGreen"
                                            />
                                        } 
                                        label="Word of mouth or referral from a friend or family member" 
                                    />
                                    <FormControlLabel 
                                        control={
                                            <Checkbox 
                                                checked={previousTherapySource === "Internet search"}
                                                onClick={e => updatePreviousTherapySource(e, "Internet search")}
                                                color="caralystGreen"
                                            />
                                        } 
                                        label="Internet search" 
                                    />
                                    <FormControlLabel 
                                        control={
                                            <Checkbox 
                                                checked={previousTherapySource === "Existing database of therapists such as a school provided list or platform like TimelyCare."}
                                                onClick={e => updatePreviousTherapySource(e, "Existing database of therapists such as a school provided list or platform like TimelyCare.")}
                                                color="caralystGreen"
                                            />
                                        } 
                                        label="Existing database of therapists such as a school provided list or platform like TimelyCare."
                                    />
                                    <TextField 
                                        sx={{
                                            width: "15rem",
                                            maxHeight: "4rem"
                                        }}
                                        size="small"
                                        label="Other"
                                        onChange={e => updatePreviousTherapySource(e, e.target.value)}
                                    />
                                    
                                </FormGroup>
                            </Box>
                            <Box sx={{
                                margin: "0.5rem"
                            }}>
                                <Typography variant="h6" component="div">
                                    Did Caralyst make the process of finding a therapist easier than it had been in the past?
                                </Typography>
                                <FormGroup
                                    sx={{
                                        marginLeft: "1rem"
                                    }}
                                >
                                    <FormControlLabel 
                                        control={
                                            <Checkbox 
                                                checked={caralystEasierThanPast === true}
                                                onClick={e => updateCaralystEasierThanPast(e, true)}
                                                color="caralystGreen"
                                            />
                                        } 
                                        label="Yes" 
                                    />
                                    <FormControlLabel 
                                        control={
                                            <Checkbox 
                                                checked={caralystEasierThanPast === false}
                                                onClick={e => updateCaralystEasierThanPast(e, false)}
                                                color="caralystGreen"
                                            />
                                        } 
                                        label="No" 
                                    />
                                </FormGroup>
                            </Box>
                            <Box sx={{
                                margin: "0.5rem"
                            }}>
                                <Typography variant="h6" component="div">
                                (Optional) Please provide more detail about what your experience was like in the past, and how it compares to your experience with Caralyst.
                                </Typography>

                                <TextField 
                                    size="small"
                                    multiline
                                    rows={3}
                                    sx={{
                                        width: "45%",
                                        minWidth: "15rem",
                                        marginTop: "1rem",
                                        marginLeft: "1rem"
                                    }}
                                    onChange={updateMoreDetails}
                                />
                            </Box>
                        </Box>
                    }
                    {previousTherapyAttendance === false &&
                        <Box sx={{
                            margin: "0.5rem"
                        }}>
                            <Typography variant="h6" component="div">
                                If no, which of the following explain why? (Select all that apply)
                            </Typography>
                            <FormGroup
                                sx={{
                                    marginLeft: "1rem"
                                }}
                            >
                                <FormControlLabel 
                                    control={
                                        <Checkbox 
                                            checked={whyNoTherapy === "Did not need therapy before"}
                                            onClick={e => updateWhyNoTherapy(e, "Did not need therapy before")}
                                            color="caralystGreen"
                                        />
                                    } 
                                    label="Did not need therapy before" 
                                />
                                <FormControlLabel 
                                    control={
                                        <Checkbox 
                                            checked={whyNoTherapy === "Too busy to attend sessions"}
                                            onClick={e => updateWhyNoTherapy(e, "Too busy to attend sessions")}
                                            color="caralystGreen"
                                        />
                                    } 
                                    label="Too busy to attend sessions" 
                                />
                                <FormControlLabel 
                                    control={
                                        <Checkbox 
                                            checked={whyNoTherapy === "Too busy to find a therapist"}
                                            onClick={e => updateWhyNoTherapy(e, "Too busy to find a therapist")}
                                            color="caralystGreen"
                                        />
                                    } 
                                    label="Too busy to find a therapist" 
                                />
                                <FormControlLabel 
                                    control={
                                        <Checkbox 
                                            checked={whyNoTherapy === "Considered going but was hesitant about starting"}
                                            onClick={e => updateWhyNoTherapy(e, "Considered going but was hesitant about starting")}
                                            color="caralystGreen"
                                        />
                                    } 
                                    label="Considered going but was hesitant about starting"
                                />
                                <FormControlLabel 
                                    control={
                                        <Checkbox 
                                            checked={whyNoTherapy === "Too expensive"}
                                            onClick={e => updateWhyNoTherapy(e, "Too expensive")}
                                            color="caralystGreen"
                                        />
                                    } 
                                    label="Too expensive"
                                />
                                <TextField 
                                    sx={{
                                        width: "15rem",
                                        maxHeight: "4rem"
                                    }}
                                    size="small"
                                    label="Other"
                                    onChange={e => updateWhyNoTherapy(e, e.target.value)}
                                />
                                
                            </FormGroup>
                        </Box>
                    }
                    <Box sx={{
                        margin: "0.5rem"
                    }}>
                        <Typography variant="h6" component="div">
                            Would you recommend Caralyst to a friend or family member?
                        </Typography>
                        <FormGroup
                            sx={{
                                marginLeft: "1rem"
                            }}
                        >
                            <FormControlLabel 
                                control={
                                    <Checkbox 
                                        checked={recommendCaralyst === true}
                                        onClick={e => updateRecommendCaralyst(e, true)}
                                        color="caralystGreen"
                                    />
                                } 
                                label="Yes" 
                            />
                            <FormControlLabel 
                                control={
                                    <Checkbox 
                                        checked={recommendCaralyst === false}
                                        onClick={e => updateRecommendCaralyst(e, false)}
                                        color="caralystGreen"
                                    />
                                } 
                                label="No" 
                            />
                        </FormGroup>
                    </Box>
                    {recommendCaralyst === false &&
                        <Box sx={{
                            margin: "0.5rem"
                        }}>
                            <Typography variant="h6" component="div">
                            (Optional) Please provide us feedback on how we can improve our service.
                            </Typography>
                            <TextField 
                                size="small"
                                multiline
                                rows={3}
                                sx={{
                                    width: "45%",
                                    minWidth: "15rem",
                                    marginTop: "1rem",
                                    marginLeft: "1rem"
                                }}
                                onChange={updateRecommendCaralystFeedback} 
                            />
                        </Box>
                    }
                    <Box sx={{
                        margin: "0.5rem"
                    }}>
                        <Typography variant="h6" component="div">
                            Did Caralyst make it easier for you to seek therapy when you would not have otherwise? 
                        </Typography>
                        <FormGroup
                            sx={{
                                marginLeft: "1rem"
                            }}
                        >
                            <FormControlLabel 
                                control={
                                    <Checkbox 
                                        checked={caralystEasierThanOtherwise === true}
                                        onClick={e => updateCaralystEasierThanOtherwise(e, true)}
                                        color="caralystGreen"
                                    />
                                } 
                                label="Yes" 
                            />
                            <FormControlLabel 
                                control={
                                    <Checkbox 
                                        checked={caralystEasierThanOtherwise === false}
                                        onClick={e => updateCaralystEasierThanOtherwise(e, false)}
                                        color="caralystGreen"
                                    />
                                } 
                                label="No" 
                            />
                        </FormGroup>
                    </Box>
                    {caralystEasierThanOtherwise === false &&
                        <Box sx={{
                            margin: "0.5rem"
                        }}>
                            <Typography variant="h6" component="div">
                            (Optional) Please provide us feedback on how we can improve our service.
                            </Typography>
                            <TextField 
                                size="small"
                                multiline
                                rows={3}
                                sx={{
                                    width: "45%",
                                    minWidth: "15rem",
                                    marginTop: "1rem",
                                    marginLeft: "1rem"
                                }}
                                onChange={updateCaralystEasierThanOtherwiseFeedback}
                            />
                        </Box>
                    }
                    <Box sx={{
                        margin: "0.5rem"
                    }}>
                        <Typography variant="h6" component="div">
                            How would you rate your experience using Caralyst? 
                        </Typography>
                        <Rating size="large" onChange={updateCaralystExperienceRating} />
                    </Box>
                    {caralystExperienceRating !== null && caralystExperienceRating < 3 &&
                        <Box sx={{
                            margin: "0.5rem"
                        }}>
                            <Typography variant="h6" component="div">
                                We're sorry to hear you did not enjoy your experience using Caralyst. What can we do to improve?
                            </Typography>
                            <TextField 
                                size="small"
                                multiline
                                rows={3}
                                sx={{
                                    width: "45%",
                                    minWidth: "15rem",
                                    marginTop: "1rem",
                                    marginLeft: "1rem"
                                }}
                                onChange={updateCaralystExperienceRatingFeedback} 
                            />
                        </Box>
                    }
                    <Box sx={{
                        margin: "0.5rem"
                    }}>
                        <Typography variant="h6" component="div">
                            Did you find Caralyst's platform easy to use?
                        </Typography>
                        <Rating size="large" onChange={updateCaralystEasyToUse} />
                    </Box>
                    {caralystEasyToUse !== null && caralystEasyToUse < 3 &&
                        <Box sx={{
                            margin: "0.5rem"
                        }}>
                            <Typography variant="h6" component="div">
                                We're sorry to hear you did not enjoy your experience using Caralyst. What can we do to improve?
                            </Typography>
                            <TextField 
                                size="small"
                                multiline
                                rows={3}
                                sx={{
                                    width: "45%",
                                    minWidth: "15rem",
                                    marginTop: "1rem",
                                    marginLeft: "1rem"
                                }}
                                onChange={updateCaralystEasyToUseFeedback} 
                            />
                        </Box>
                    }
                    <Button 
                        variant="contained"
                        onClick={async (e) => await submitSurvey(e)} 
                        sx={{
                            marginTop: "1rem",
                            backgroundColor: theme.palette.caralystGreen.main,
                            "&:hover": {
                                backgroundColor: theme.palette.caralystGreen.main,
                                filter: "brightness(0.9)"
                            }
                    }}>
                        Submit
                    </Button>
                </Box>
                
            </Box>
            <Dialog
                open={surveyAnsweredDialogOpen}
                onClose={async (e) => await closeSurveyAnsweredDialog(e)}
                aria-labelledby="alert-dialog-title"
            >
                <DialogTitle sx={{
                    fontSize: "2rem"
                }}>
                        Oops!
                </DialogTitle>
                <DialogContent>
                    <DialogContentText 
                        id="alert-dialog-description" 
                        sx={{ fontSize: "1rem"
                    }}>
                        It seems like you already filled out this survey. Thank you for your feedback!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={async (e) => await closeSurveyAnsweredDialog(e)} 
                        autoFocus 
                        color="caralystGreen" 
                        variant="contained" 
                        sx={{
                            color: "white",
                            "&:hover": {
                                backgroundColor: theme.palette.caralystGreen.main,
                                filter: "brightness(90%)"
                            }
                    }}>
                        Return to Home
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}


export default PopupSurvey;