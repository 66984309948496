import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { submitReview } from '../../redux/actions/user.actions';
import { findPreviousDocReview, loadDoctors } from '../../redux/actions/doctors.actions';
import { selectDoctors, selectLeftRightBounds, selectDoctorsLoadingStatus, selectPatientId, selectPreferredInsurance, selectPreferredSpecialty, selectPreviousDocReview, selectPreviousDocReviewSize, selectPreviousTraitRatings, selectTopBottomBounds, selectTempDocId, selectModalityPreference } from '../../redux/store';

import { TextField, Divider, Button, Typography, Box, useMediaQuery } from '@mui/material';
import Rating from '@mui/material/Rating'
import { useTheme } from '@mui/material/styles';

import LoadingIcon from '../Misc/LoadingIcon';
import '../../css/ReviewCard.css'
import { org } from '../../redux/constants/shared.constants.js';
import { setSnackbarMessage, setSnackbarOpen } from '../../redux/actions/shared.actions';

const ReviewCard = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const theme = useTheme();
    const onMobile = !useMediaQuery('(min-width:600px)');

    const docsLoading = useSelector(selectDoctorsLoadingStatus)
    const unfilteredDocs = useSelector(selectDoctors);
    const docs = unfilteredDocs;

    const previousDocReview = useSelector(selectPreviousDocReview);
    const previousTraitRatings = useSelector(selectPreviousTraitRatings);
    const previousDocReviewSize = useSelector(selectPreviousDocReviewSize);
    const patientId = useSelector(selectPatientId);
    const leftRightBounds = useSelector(selectLeftRightBounds);
    const topBottomBounds = useSelector(selectTopBottomBounds);
    const specialtyPreference = useSelector(selectPreferredSpecialty);
    const insurancePreference = useSelector(selectPreferredInsurance);
    const preferredModality = useSelector(selectModalityPreference);

    const [traitMatches, setTraitMatches] = useState([]);
    const [appointment, setAppointment] = useState({});
    const [traitRatings, setTraitRatings] = useState({});
    const [showTraits, setShowTraits] = useState(false);
    const [modifyingTraitRatings, setModifyingTraitRatings] = useState(false);
    const docId = params.docId;
    const tempDocId = useSelector(selectTempDocId);
    
    useEffect(() => {
        // TODO: Add error for if things don't load correctly
        //order matters
        const asyncInitReviewCard = async () => {
            await dispatch(loadDoctors(patientId, leftRightBounds, topBottomBounds, specialtyPreference, insurancePreference, onMobile, tempDocId, preferredModality));
            await dispatch(findPreviousDocReview(org))
            findDocById();
        }
        asyncInitReviewCard();

        localStorage.removeItem("review_link")
        
        return () => {
            setShowTraits(false);
            setModifyingTraitRatings(false);
        }
    }, [dispatch])
    
    useEffect(() => {
        initReview(org, docId);

        return () => {
            setTraitRatings({});
            setAppointment({});
        }
    }, [traitMatches, previousTraitRatings])
 
    const initReview = (org, id) => {
        const reviewResults = {};
        if(previousTraitRatings && previousTraitRatings.length !== 0)
            previousTraitRatings.forEach((trait, ind) => {
                const tempReviewResults = {
                    org: org,
                    rating: trait.rating,
                    trait_id: trait.trait_id,
                    question_for_patient: trait.MatchingTrait?.question_for_patient
                };
                reviewResults[ind] = tempReviewResults;
            })
        else if(traitMatches)
            traitMatches.forEach((trait, ind) => {
                const tempReviewResults = {
                    org: org,
                    rating: null,
                    trait_id: trait.trait_id,
                    question_for_patient: trait.question_for_patient
                };
                reviewResults[ind] = tempReviewResults;
            })
        setTraitRatings(reviewResults);
        setAppointment({
            doctor_id: id,
            net_promoter_score: null,
            satisfaction_score: null,
            free_text_review: '',
        })
    }

    const findDocById = () => {
        for(let doc in docs) {
            if(docs[doc].id === params.docId){
                const curDoc = docs[doc];
                setTraitMatches(curDoc.strongestTraitMatches);
            }
        }
    }
    const updateReviewFloat = (e, ind, val) => {
        e.preventDefault();
        const traitRatingCopy = {...traitRatings}
        traitRatingCopy[ind].rating = val;
        setModifyingTraitRatings(true);
        setTraitRatings(traitRatingCopy);
    }

    const updateNPS = (e, val) => {
        e.preventDefault();
        const appointmentCopy = {...appointment}
        appointmentCopy.net_promoter_score = val;
        setAppointment(appointmentCopy);
    }

    const updateSatisfaction = (e, val) => {
        e.preventDefault();
        const appointmentCopy = {...appointment}
        appointmentCopy.satisfaction_score = val;
        setAppointment(appointmentCopy);
    }

    const updateFinalText = (e) => {
        e.preventDefault();
        const appointmentCopy = {...appointment}
        appointmentCopy.free_text_review = e.target.value;
        setAppointment(appointmentCopy);
    }

    const toggleShowTraits = (e) => {
        e.preventDefault();
        setShowTraits(!showTraits);
    }
    
    const traitReviewsBody = () => {
        if(docsLoading)
            return <LoadingIcon textInfo="Loading Survey..."/>
        else
            return (
                <Box 
                    aria-label="review-question-container"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',

                }}>
                    {previousTraitRatings?.length > 0 ?
                        <>
                            <Button 
                                onClick={toggleShowTraits}
                                sx={{
                                    maxWidth: "15rem",
                                    marginLeft: "calc(50% - 7.5rem)",
                                    backgroundColor: theme.palette.caralystGreen.main,
                                    marginBottom: "1rem",
                                    transition: 'opacity ease-in-out 0.15s',
                                    '&:hover': {
                                        backgroundColor: theme.palette.caralystGreen.dark,
                                        opacity: 0.7,
                                    }
                                }}
                            >
                                <Typography
                                    aria-label="review-title"
                                    sx={{
                                        fontSize: '1.25em',
                                        marginTop: "0.5rem",
                                        marginBottom: "0.5rem",
                                        textTransform: 'none',
                                        color: "white",
                                }}>
                                    {showTraits ? "Hide Previous Trait Ratings" : "Change Trait Ratings"}
                                </Typography>
                            </Button>
                            {previousDocReview && showTraits ?
                                traitRatings ? Object.entries(traitRatings).map((question, index) => {
                                    question = question[1]
                                    return (
                                        <Box aria-label='trait-question-container'
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                marginTop: "0.5rem",
                                                marginBottom: "0.5rem"
                                            }}
                                        >
                                            <Typography
                                                aria-label="review-title"
                                                sx={{
                                                    fontSize: '1.1em',
                                                    marginTop: "0.5rem",
                                                    marginBottom: "0.5rem"
                                                }}
                                            
                                            >
                                                {question.question_for_patient}
                                            </Typography>
                                            <Box aria-label="review-slider-container"  id={question.trait_id ? question.trait_id : index}>
                                                <Rating
                                                    id={question.trait_id ? question.trait_id : index}
                                                    name="simple-controlled"
                                                    size="large"
                                                    defaultValue={question.rating}
                                                    onChange={(e, val) => {updateReviewFloat(e, index, val)}}
                                                />
                                            </Box>
                                        </Box>
                                    )
                                }) : null 
                                : null
                            }
                            </>
                        :
                        traitMatches?.length > 0 ?
                                traitRatings ? Object.entries(traitRatings).map((question, index) => {
                                    question = question[1]
                                    return (
                                        <Box aria-label='trait-question-container'
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                marginTop: "0.5rem",
                                                marginBottom: "0.5rem"
                                        }}>
                                            <Typography
                                                aria-label="review-title"
                                                sx={{
                                                    fontSize: '1.1em',
                                                    marginTop: "0.5rem",
                                                    marginBottom: "0.5rem"
                                            }}>
                                                {question.question_for_patient}
                                            </Typography>
                                            <Box aria-label="review-slider-container"  id={question.trait_id ? question.trait_id : index}>
                                                <Rating
                                                    id={question.trait_id ? question.trait_id : index}
                                                    name="simple-controlled"
                                                    size="large"
                                                    defaultValue={question.rating}
                                                    onChange={(e, val) => {updateReviewFloat(e, index, val)}}
                                                />
                                            </Box>
                                        </Box>
                                    )
                                }) : null
                                : null
                    }
                    {Object.entries(traitRatings).length > 0 ? <Divider/> : null}
                    <Box className='question-spacer' />
                    <Typography
                        aria-label="review-title"
                        sx={{
                            fontSize: '1.1em',
                            marginTop: "0.5rem",
                            marginBottom: "0.5rem"
                    }}>
                        On a scale of 1-10, how likely would you recommend this physician to someone you know?
                    </Typography>
                    <Box aria-label='sliders-wrapper'>
                        <Rating
                            name="simple-controlled"
                            size="large"
                            defaultValue={appointment.net_promoter_score}
                            onChange={(e, val) => {updateNPS(e, val)}}
                        />
                    <Typography
                        aria-label="review-title"
                        sx={{
                            fontSize: '1.1em',
                            marginTop: "0.5rem",
                            marginBottom: "0.5rem"
                    }}>
                        On a scale of 1-10, how would you rate this physician as a match for you?
                    </Typography>
                        <Rating
                            name="simple-controlled"
                            size="large"
                            defaultValue={appointment.satisfaction_score}
                            onChange={(e, val) => {updateSatisfaction(e, val)}}
                        />
                    </Box>
                    <Typography
                        aria-label="review-title"
                        sx={{
                            fontSize: '1.1em',
                            marginTop: "0.5rem",
                            marginBottom: "0.5rem"
                    }}>
                        Anything else you would like to share?
                    </Typography>
                        <TextField 
                            className='text-field'
                            multiline
                            minRows={3}
                            maxRows={4}
                            defaultValue={appointment.free_text_review}
                            onChange={e => {updateFinalText(e)}}
                            sx={{ maxWidth: "30rem", marginTop: "0.5rem"}}
                        />
                        <Button 
                            onClick={async (e) => await submit(e)}
                            sx={{
                                maxWidth: "10rem",
                                backgroundColor: theme.palette.caralystGreen.main,
                                color: "white",
                                marginBottom: "1rem",
                                transition: 'opacity ease-in-out 0.15s',
                                '&:hover': {
                                    backgroundColor: theme.palette.caralystGreen.dark,
                                    opacity: 0.7,
                                }
                        }}>
                            Submit
                        </Button>
                    </Box>
            )
    }

    const submit = async (e) => {
        e.preventDefault();
        const res = await dispatch(submitReview(traitRatings, org, appointment, modifyingTraitRatings, previousDocReviewSize))
        if(res.data.message){
            await dispatch(setSnackbarMessage(res.data.message))
        }
        else if(res.status === 200){
            await dispatch(setSnackbarMessage("Thank you for your feedback! \n Your review has been submitted!"));
            setTimeout(() => navigate('/home'), 1500)
        } else {
            await dispatch(setSnackbarMessage("Sorry, something went wrong. Please try again soon!"));
        }
        await dispatch(setSnackbarOpen(true));
    }

    return (
        <Box 
            aria-label="review-container"
            sx={{
                display: 'flex',    
                alignItems: 'center',
                marginTop: '5rem',
                maxWidth: '75vw',
            }}
        >
            <div className="profile-container">
                {traitReviewsBody()}
            </div>
        </Box>
    );
};

export default ReviewCard;