import React, { useEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga4';

import { Card, Typography, Modal, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import ShieldIcon from '@mui/icons-material/Shield';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import { useDispatch, useSelector } from 'react-redux';
import { selectCardIndex, selectPreferredInsurance, selectPersonCardOpen, selectFilteredDocs, selectLoggedIn, selectExistsMatchingProviders, selectFavoriteDoctorIds, selectPopupSurveyStatus, selectPopupSurveyTimeoutSet, selectLatLong } from '../../redux/store';
import { setCardIndex, setPersonCardOpen } from '../../redux/actions/user.actions';
import { findDoctorDistace, getPfp, setTempDocId, setTempDocLoc, setTempDocReasonsForMatching, updateFavoriteDocs} from '../../redux/actions/doctors.actions';

import PersonCard from './PersonCard';
import { useNavigate } from 'react-router-dom';
import { org } from '../../redux/constants/shared.constants.js';
import { handleOpen, setSnackbarMessage, setSnackbarOpen } from '../../redux/actions/shared.actions';

const BulletPoint = ({ size, weight, marginLeftRight, marginTopBottom, onMobile }) => {
    const multiplier = !onMobile ? 1 : 1.35;
    return (
        <Typography
            className="spacer"
            sx={{
                fontSize: `${size}rem`,
                fontWeight: weight,
                margin: `${marginTopBottom * multiplier}rem ${marginLeftRight * multiplier}rem`,
            }}
        >
            &nbsp;
            &#x2022;
            &nbsp;
        </Typography>
    )
}

const MiniPersonCard = ({ personInfo, index, maps, currentLocation }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const onMobile = !useMediaQuery('(min-width:600px)');
    const navigate = useNavigate();
    const cardRef = useRef(null);
    const insideRef = useRef(null);

    const cardIndex = useSelector(selectCardIndex);
    const patientInsurance = useSelector(selectPreferredInsurance);
    const personCardOpen = useSelector(selectPersonCardOpen);
    const filteredDocs = useSelector(selectFilteredDocs);
    const loggedIn = useSelector(selectLoggedIn);
    const existsMatchingProviders = useSelector(selectExistsMatchingProviders);
    const favoriteDoctorIds = useSelector(selectFavoriteDoctorIds);
    const popupSurveyStatus = useSelector(selectPopupSurveyStatus);
    const popupSurveyTimeoutSet = useSelector(selectPopupSurveyTimeoutSet);
    const latLong = useSelector(selectLatLong);
    const [docLatLong, setDocLatLong] = useState(null);

    // const doctorDistance = useSelector(selectDoctorDistance);
    let docLocation = currentLocation ? currentLocation : 
        personInfo?.Locations ? personInfo?.Locations[0]?.address : null;

    const [overflowing, setOverflowing] = useState(false);
    const [isFavorite, setIsFavorite] = useState(favoriteDoctorIds?.includes(personInfo?.id));
    const [distance, setDistance] = useState(null);

    useEffect(() => {
        //If the url has a doctor id and the card is not already open, open the person card
        const asyncInitMiniCard = async () => {
            checkCardHeight();
            setIsFavorite(favoriteDoctorIds?.includes(personInfo?.id));
            if(org === "wustl")
                getDocDistance();
        }
        asyncInitMiniCard();
    }, [dispatch, existsMatchingProviders, favoriteDoctorIds, popupSurveyTimeoutSet, personCardOpen, filteredDocs]);

    //Close the person card
    const handleClose = async (e) => {
        e.preventDefault();
        await dispatch(setTempDocId(null));
        await dispatch(setTempDocLoc(null));
        await dispatch(setTempDocReasonsForMatching(null));
        await dispatch(setPersonCardOpen(false));
        await dispatch(setCardIndex(null));
        navigate("/home");
    };

    //check if two lat lng objects are equal
    const latLongEqual = (latLong1, latLong2) => {
        return latLong1?.lat === latLong2?.lat && latLong1?.lng === latLong2?.lng;
    }

    //get the doc distance
    const getDocDistance = async () => {
        const tempDocLatLong = {
            lat: personInfo?.Locations[0]?.latitude,
            lng: personInfo?.Locations[0]?.longitude,
        }
        if((!distance || !latLongEqual(tempDocLatLong, docLatLong)) && 
            latLong && 
            tempDocLatLong.lat &&
            tempDocLatLong.lng &&
            Object.keys(latLong).length === 2
        ) {
            const distance = await dispatch(findDoctorDistace(latLong, tempDocLatLong, cardIndex, true));
            console.log("distance", distance)
            setDocLatLong(tempDocLatLong);
            if(distance) {
                setDistance(distance);
            }
        } 
    }

    //Make sure content on the card minicard is not overflowing
    const checkCardHeight = () => {
        if(cardRef.current && insideRef.current.clientHeight > cardRef.current.clientHeight)
            setOverflowing(true);
    }

    /***
     * Add or remove a doctor from the user's favorites
     * 
     * @param {event} e
     * @param {string} docId - id of the doctor
     */
    const handleToggleInFavorites = async (e, docId) => {
        e.preventDefault();
        //Don't open the person card if adding to favorites
        e.stopPropagation();
        //if the doc is already in favorites, remove it. Otherwise add it.
        if(isFavorite) {
            await onRemoveFromFavorites(e, docId);
        } else {
            await onAddToFavorites(e, docId);
        }
    }

    /***
     * Add a doctor to the user's favorites
     * 
     * @param {event} e
     * @param {string} docId - id of the doctor
     * @returns {boolean} - true if the doctor was added to favorites, false otherwise
     */
    const onAddToFavorites = async (e, docId) => {
        e.preventDefault();
        //Don't open the person card if adding to favorites
        e.stopPropagation();

        ReactGA.event({
            category: 'Button',
            action: 'Click',
            label: `Save Physician`,
        });
        //Add the doctor to the user's favorites
        const newFavDocIds = favoriteDoctorIds.push(docId); 
        setIsFavorite(true);

        //Update the redux store and the database
        const res = await dispatch(updateFavoriteDocs(docId, org, newFavDocIds, "add"))
        if(res && !personCardOpen) {
            //If the doctor was added successfully, show success
            await dispatch(setSnackbarMessage("Added to Favorites"));
            await dispatch(setSnackbarOpen(true));
        } else if(!personCardOpen) {
            //If the doctor was not added successfully, show error
            await dispatch(setSnackbarMessage("Error adding to Favorites"));
            await dispatch(setSnackbarOpen(true));
        }
        return res;
    }

    /***
     * Remove a doctor from the user's favorites
     * 
     * @param {event} e
     * @param {string} docId - id of the doctor
     * @returns {boolean} - true if the doctor was removed from favorites, false otherwise
     */
    const onRemoveFromFavorites = async (e, docId) => {
        e.preventDefault();
        //Don't open the person card if adding to favorites
        e.stopPropagation();
        //Remove the doctor from the user's favorites
        setIsFavorite(false);
        const newFavDocIds = favoriteDoctorIds.splice(favoriteDoctorIds.indexOf(docId), 1);        
        //Update the redux store and the database
        const res = await dispatch(updateFavoriteDocs(docId, org, newFavDocIds, "remove")); 
        if(res && !personCardOpen) {
            //If the doctor was removed successfully, show success
            await dispatch(setSnackbarMessage("Removed from Favorites"));
            await dispatch(setSnackbarOpen(true));
        } else if(!personCardOpen) {
            //If the doctor was not removed successfully, show error
            await dispatch(setSnackbarMessage("Error removing from Favorites"));
            await dispatch(setSnackbarOpen(true));
        }
        return res;
    }

    /***
     * For as many insurances the user has selected, return a string of the insurances overlapping with the doctor's insurances
     * 
     * @returns {string} - the string of overlapping insurances
     * @example returns "Aetna, Blue Cross Blue Shield"
     * 
     * TODO: find a way to use overlappingInsurance() instead of looping through the insurances again
     */
    const insuranceOverlapString = () => {
        const insurances = overlappingInsurance();
        let insuranceString = "";
        for(let i = 0; i < insurances.length; i++) {
            const ins = insurances[i];
            if(ins.name === "Any Insurance") continue;
            insuranceString += ins.name;
            if(i !== insurances.length - 1) insuranceString += ", ";
        }
        return insuranceString;
    }

    /***
     * Find overlapping insurances between the user and the doctor
     * 
     * @returns {array} - the array of overlapping insurances
     */
    const overlappingInsurance = () => {
        return personInfo.Insurances.filter(ins => patientInsurance?.includes(ins.name));
    }

    //If the user is a guest, only show the top 4s doctors
    const guestUserTopDocPreview = !loggedIn && index > 3 && existsMatchingProviders;
    
    const onClickMiniCard = async (e) => {
        e.preventDefault();
        if(!personCardOpen) {
            await dispatch(handleOpen(e, index, popupSurveyStatus, popupSurveyTimeoutSet, filteredDocs));
        }
    }

    return (
        <>
            {personInfo && 
                <>
                <Card className={`"mini-doctor-card-wrapper ${index === 0 ? 'component-to-click-on' : ''}`}
                        ref={cardRef}
                        onClick={async (e) => onClickMiniCard(e)}
                        sx={!onMobile && !maps ? {
                            display: "flex",
                            position: "relative",
                            flexDirection: "column",
                            alignItems: "center",
                            width: "100%",
                            maxWidth: "15rem",
                            minWidth: "12rem",
                            minHeight: "22rem",
                            maxHeight: "22rem",
                            border: "0.125px solid lightgrey",
                            borderRadius: "0.25rem",
                            marginLeft: "0.5rem",
                            cursor: "pointer",
                            boxShadow: "0px 0px 1px 0px rgba(0, 0, 0, 0.2), 0px 0px 1px 0px rgba(0, 0, 0, 0.14), 0px 0px 1px -1px rgba(0, 0, 0, 0.12)",
                            transition: 'box-shadow 0.25s',
                            '&:hover': {
                                boxShadow: '0px 5px 12px rgba(0, 0, 0, 0.3)',
                            },
                            filter: guestUserTopDocPreview ? "blur(5px)" : "none",
                            userSelect: guestUserTopDocPreview ? "none" : "",
                            pointerEvents: guestUserTopDocPreview ? "none" : "",
                        } : {
                            maxHeight: "10rem",
                            maxWidth: onMobile ? "100%" : "20rem",
                            position: "relative",
                            filter: guestUserTopDocPreview ? "blur(5px)" : "none",
                            userSelect: guestUserTopDocPreview ? "none" : "",
                            pointerEvents: guestUserTopDocPreview ? "none" : "",
                    }}>
                        {
                            personInfo.recentlyViewed && 
                            <Box
                                className="recently-viewed-card"
                                sx={{
                                    position: "absolute",
                                    top: "0.25rem",
                                    left: "0.25rem",
                                    zIndex: 1000,
                            }}>   
                                <Card 
                                    size='small'
                                    sx={{
                                        backgroundColor: theme.palette.caralystGreen.main,
                                        color: "white",
                                        fontWeight: "600",
                                        fontSize: "0.75rem",
                                        padding: "0.125rem 0.25rem",
                                }}>
                                    Recently Viewed
                                </Card>

                            </Box>
                        }
                        {!maps &&
                            <Box 
                                className="index-and-favorite-icons-wrapper"
                                sx={{
                                    zIndex: 1000,
                            }}> 
                                {loggedIn &&
                                    <Box sx={{ cursor: "pointer", position: "absolute", bottom: "1.75rem", right: "1.75rem", zIndex: 9999999}}>
                                        <Box sx={{position: "relative", cursor: "pointer"}}>
                                            <FavoriteIcon sx={{ color: isFavorite ? theme.palette.caralystRed.main : "rgba(255,255,255,1)", position: "absolute"}} 
                                                onClick={async (e) => await handleToggleInFavorites(e, personInfo?.id)}
                                            />
                                            <FavoriteBorderIcon 
                                                onClick={async (e) => await handleToggleInFavorites(e, personInfo?.id)}
                                                sx={{ 
                                                    color: theme.palette.caralystRed.main, 
                                                    position: "absolute",
                                                    "@keyframes icon-pop": {
                                                        "0%": {
                                                            transform: "scale(1)"
                                                        },
                                                        "50%": {
                                                            transform: "scale(1.15)"
                                                        },
                                                        "100%": {
                                                            transform: "scale(1)"
                                                        },
                                                    },
                                                    "&:hover": {
                                                        opacity: 0.6,
                                                        animation: isFavorite ? "none" : "icon-pop 0.5s ease-in-out",
                                                    },
                                            }}/>
                                        </Box>
                                    </Box>
                                }
                            </Box>
                        }
                        <Box
                            ref={insideRef}
                            sx={!onMobile && !maps ? {
                                maxWidth: "93%",
                                minWidth: "90%",
                            } : {
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "flex-start",
                        }}>
                            <Box 
                                aria-label="doctor-profile-picture"
                                sx={!onMobile && !maps ? {
                                    width: "100%",
                                    minWidth: "100%",
                                    minHeight: "10rem",
                                    maxHeight: "12rem",
                                    overflow: "hidden",
                                    borderRadius: "0.5rem",
                                    border: "0.25px solid lightgrey",        
                                    marginTop: "4%",
                                    filter: guestUserTopDocPreview ? "blur(20px)" : "none",
                                } : {
                                    filter: guestUserTopDocPreview ? "blur(20px)" : "none",
                                    maxWidth: "30%",
                                    padding: "0.5rem",
                                    maxHeight: "8.5rem",
                                    overflow: "hidden",
                            }}>
                                {dispatch(getPfp(personInfo, "minicard"))}
                            </Box>
                            <Box className="info-wrapper"
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    marginTop: "0.5rem",
                                    marginBottom: "1rem",
                                    maxHeight: "8rem",
                                    overflowY: "visible",
                            }}>
                                <Typography
                                    color={theme.palette.caralystNavy.main}
                                    fontWeight={900}
                                    sx={{
                                        flexDirection: 'row',
                                        fontSize: "1.1em",
                                        textAlign: 'left',
                                        lineHeight: "1.25em"
                                }}>
                                    {(personInfo.first_name + " " + personInfo.last_name)}{!personInfo.user_qualification ? "" : ", " + personInfo.user_qualification}
                                </Typography>
                                <Typography 
                                    fontWeight={600}
                                    sx={{
                                        flexDirection: 'row',
                                        fontSize: "1em",
                                        textAlign: 'left',
                                        lineHeight: "1.25em"
                                }}>
                                    {personInfo.user_specialty}
                                </Typography>
                                <Box aria-label="basic-info-wrapper"
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        marginTop: "0.25rem"
                                }}>
                                    {docLocation &&
                                        <Box aria-label="location-wrapper" sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "flex-start",
                                        }}>
                                            <LocationOnIcon sx={{
                                                fontSize: '1em',
                                            }}/>
                                            &nbsp;
                                            <Typography aria-label="distance"
                                                sx={{ 
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    fontSize: "0.75rem",
                                                    width: !onMobile ? "12rem" : "100%",
                                                    whiteSpace: "nowrap",
                                            }}>
                                                {Object.keys(latLong).length === 2 && distance && org === "wustl" && 
                                                    <>
                                                        {distance}
                                                        <BulletPoint size={1} weight={400} marginLeftRight={-0.3} marginTopBottom={-0.15} onMobile={onMobile} />
                                                    </>
                                                }
                                                <Typography sx={{
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    fontSize: "0.75rem",
                                                }}>
                                                    {docLocation.split(",")[0]}
                                                </Typography>
                                            </Typography>
                                            
                                        </Box>
                                    }
                                    {//If we are in general caralyst and we have provider insurance info
                                        org === "general" && personInfo.Insurances.length > 0 ? 
                                            //If the patient has only selected "Any Insurance"
                                            (patientInsurance?.includes("Any Insurance") && patientInsurance.length === 1) || overlappingInsurance().length === 0 ? 
                                                <Box sx={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "flex-start",
                                                }}>
                                                        <ShieldIcon sx={{ 
                                                            fontSize: '1em',
                                                            color: theme.palette.burntOrange.main
                                                        }}/> 
                                                        &nbsp; 
                                                        <Typography     
                                                            className='accepted-insurance'
                                                            sx={{
                                                                fontSize: '0.75em',
                                                        }}>
                                                            See if they're in your network
                                                        </Typography> 
                                                </Box>
                                                :
                                                //If the patient has selected specific insurance
                                                <Box sx={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "flex-start",
                                                }}>
                                                    <ShieldIcon sx={{ 
                                                        fontSize: '1em', 
                                                        color: "green"
                                                    }}/> 
                                                    &nbsp; 
                                                    <Typography 
                                                        className='accepted-insurance'
                                                        sx={{
                                                            fontSize: '0.75em',
                                                    }}>
                                                        In network &#183; {insuranceOverlapString()}
                                                    </Typography> 
                                                </Box>
                                            //If we don't have insurance info on the provider
                                            :
                                            org === "wustl" &&
                                                <Box sx={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "flex-start",
                                                }}>
                                                    <ShieldIcon sx={{ 
                                                        fontSize: '1em', 
                                                        color: "green"
                                                    }}/> 
                                                    &nbsp; 
                                                    <Typography 
                                                        className='accepted-insurance'
                                                        sx={{
                                                            fontSize: '0.75em',
                                                    }}>
                                                        In network &#183; WashU Student Health
                                                    </Typography> 
                                                </Box>
                                        }
                                        {personInfo?.strongestTraitMatches?.length > 0 ?
                                                <Box sx={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "flex-start",
                                                }}>
                                                    <i className="fas fa-check-circle fa-2x" style={{color: theme.palette.caralystGreen.main, fontSize: "1em"}}/>
                                                    &nbsp;
                                                    <Typography sx={{
                                                        fontSize: "0.75em",
                                                    }}>
                                                        You matched on <strong>{personInfo?.strongestTraitMatches?.length}</strong> 
                                                        {personInfo?.strongestTraitMatches?.length === 1 ?
                                                            " dimension"
                                                            :
                                                            " dimensions"
                                                        }
                                                    </Typography>
                                                </Box>
                                                :
                                                null
                                        }
                                    </Box>
                            </Box>
                            {(overflowing && !onMobile && !maps) &&
                                <Box 
                                    aria-label="new-box"
                                    sx={{
                                        position: "absolute",
                                        bottom: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "3rem",
                                        zIndex: 1,
                                        background: `linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(245, 245, 245, 1)) `,
                                        overlap: "auto",
                                }}/>
                            }
                        </Box>
                    </Card>
                    <Modal
                        open={personCardOpen && cardIndex === index}
                        onClose={async (e) => await handleClose(e)}
                        aria-labelledby="parent-modal-title"
                        aria-describedby="parent-modal-description"
                    >
                        <PersonCard 
                            personInfo={filteredDocs[cardIndex]} 
                            onAddToFavorites={onAddToFavorites}
                            onRemoveFromFavorites={onRemoveFromFavorites}
                            docLocation={docLocation}
                        />
                    </Modal>
                </>
            }
          
        </>
    )
};

export default MiniPersonCard;