import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setDistancePreference, setModalityPreference, setPersonCardOpen } from "../../redux/actions/user.actions";

import { Box, Button, Drawer, Menu, MenuItem, Toolbar, Tooltip, Typography } from "@mui/material";
import FavoriteIcon from '@mui/icons-material/Favorite';
import MenuIcon from '@mui/icons-material/Menu';

import MiniPersonGrid from "./MiniPersonGrid";
import SparkleIcon from '../../assets/sparkle-icon.svg'; 
import { selectDistancePreference, selectLoggedIn, selectModalityPreference } from "../../redux/store";

const CustomDrawer = ({ filterByFavorites, setFilterByFavorites }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loggedIn = useSelector(selectLoggedIn);
  const modalityPreference = useSelector(selectModalityPreference);
  const distancePreference = useSelector(selectDistancePreference);

  const [filterOpen, setFilterOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [distanceButtonElt, setDistanceButtonElt] = useState(null);
  const [modalityButtonElt, setModalityButtonElt] = useState(null);

  const filterButtonStyles = {
    justifyContent: "flex-start",
    maxWidth: "8rem",
    color: "rgba(0,0,0,0.6)",
    border: "0.5px solid rgba(0,0,0,0.6)",
    textTransform: "none",
    fontWeight: "bold",
    marginRight: "0.5rem",
    color: "black",
    lineHeight: "0.9rem",
    '&:hover': {
      backgroundColor: "rgba(0,0,0,0.08)",
      borderColor: "rgba(0,0,0,0.6)",
      color: "rgba(0,0,0,0.6)"
    },
  }

  useEffect(() => {
    const asyncCustomDrawerInit = async () => {
      await dispatch(setPersonCardOpen(false))
    }
    asyncCustomDrawerInit();
  }, [dispatch])

  /***
   * Toggle filter menu state
   */
  const toggleFilterMenu = (e) => {
    if(filterOpen) {
      // if filter menu is open, close it
      handleCloseFilter();
    } else {
      // otherwise, open it
      handleOpenFilter(e);
    }
  }

  // close filter menu
  const handleCloseFilter = () => {
    // remove anchor element from filter menu
    setAnchorEl(null);
    setDistanceButtonElt(null);
    setModalityButtonElt(null);
    // set filter menu to closed
    setFilterOpen(false);
  }

  // open filter menu
  const handleOpenFilter = (e) => {
    // set filter menu to open
    setFilterOpen(true);
    // set anchor element to filter menu
    setAnchorEl(e.currentTarget);
  }

  // navigate to questionnaire
  const handlePersonalizeMatches = () => {
    navigate("/onboard/questionnaire/0")
  }

  // toggle filter by favorites
  const handleFilterByFavorites = () => {
    setFilterByFavorites(!filterByFavorites);
  }

  const handleClickDistance = (e) => {
    e.preventDefault();
    setDistanceButtonElt(e.currentTarget);
  }

  const handleClickModality = (e) => {
    e.preventDefault();
    setModalityButtonElt(e.currentTarget);
  }

  const handleChangeModalityPreference = async (e, newval) => {
    e.preventDefault();
    await dispatch(setModalityPreference(newval));
    handleCloseFilter();
  }

  const handleChangeDistancePreference = async (e, newval) => {
    e.preventDefault();
    await dispatch(setDistancePreference(newval));
    handleCloseFilter();
  }

  return (<>
    <Box className="tour-step-1">
          <Drawer
            variant="permanent"
            anchor="left"
            PaperProps={{ 
                style: { 
                width: 515,
                transition: "width 0.2s ease",
                overflowX: "hidden",
                scrollbarWidth: "none"
              } 
          }}>
            <Toolbar sx={{
              marginTop: "2rem",
            }}/>
            <Box sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}>
              <Box sx={{
                marginTop: "-1.5rem",
                marginLeft: "0.5rem",
              }}>
                <Box aria-label="top-of-grid-buttons" 
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                }}>
                  {loggedIn && 
                    <Tooltip title="Filter By Favorites">
                        <Button 
                            variant="outlined" 
                            onClick={toggleFilterMenu} 
                            sx={{
                              color: "black",
                              border: "0.5px solid rgba(0,0,0,0.6)",
                              marginRight: "0.5rem",
                              width: "1.5rem",
                              '&:hover': {
                                  backgroundColor: "rgba(0,0,0,0.08)",
                                  borderColor: "rgba(0,0,0,0.6)",
                                  color: "rgba(0,0,0,0.6)"
                              },
                        }}>
                          <MenuIcon sx={{
                            fontSize: "2rem", 
                            marginTop: "-0.25rem", 
                            marginBottom: "-0.25rem"
                          }}/> 
                        </Button>
                    </Tooltip>
                  }
                  <Tooltip title="Personalize Matches">
                    <Button
                      variant="outlined"
                      className="tour-step-3"
                      onClick={handlePersonalizeMatches}
                      sx={{
                        color: "rgba(0,0,0,0.6)",
                        border: "0.5px solid rgba(0,0,0,0.6)",
                        textTransform: "none",
                        fontWeight: "bold",
                        color: "black",
                        marginRight: "0.5rem",
                        '&:hover': {
                          backgroundColor: "rgba(0,0,0,0.08)",
                          borderColor: "rgba(0,0,0,0.6)",
                          color: "rgba(0,0,0,0.6)"
                        },
                      }}>
                        <img
                          src={SparkleIcon}
                          alt="Sparkle Icon"
                          style={{
                            maxHeight: "3rem",
                            margin: "-1rem -0.5rem -1rem -1rem"
                        }} />
                      Personalize
                    </Button>
                  </Tooltip>
                  <Tooltip title="Filter By Distance">
                    <Button
                      variant="outlined"
                      onClick={handleClickDistance}
                      sx={filterButtonStyles}>
                      { distancePreference ? distancePreference : "Distance" }
                    </Button>
                  </Tooltip>
                  <Menu
                    anchorEl={distanceButtonElt}
                    onClose={handleCloseFilter}
                    open={distanceButtonElt !== null}
                  >
                    <MenuItem
                      onClick={async (e) => await handleChangeDistancePreference(e, "")}
                    >
                      No Preference
                    </MenuItem>
                    <MenuItem
                      onClick={async (e) => await handleChangeDistancePreference(e, "Within 5 Miles")}
                    >
                      Within 5 Miles
                    </MenuItem>
                    <MenuItem
                      onClick={async (e) => await handleChangeDistancePreference(e, "Within 10 Miles")}
                    >
                      Within 10 Miles
                    </MenuItem>
                    <MenuItem
                      onClick={async (e) => await handleChangeDistancePreference(e, "Within 25 Miles")}
                    >
                      Within 25 Miles
                    </MenuItem>
                  </Menu>
                  <Tooltip title="Filter By Modality">
                    <Button
                      variant="outlined"
                      onClick={handleClickModality}
                      sx={filterButtonStyles}
                    >
                      { modalityPreference ? modalityPreference : "Modality" }
                    </Button>
                  </Tooltip>
                  <Menu
                    anchorEl={modalityButtonElt}
                    onClose={handleCloseFilter}
                    open={modalityButtonElt !== null}
                  >
                    <MenuItem
                      onClick={async (e) => await handleChangeModalityPreference(e, "")}
                    >
                      No Preference
                    </MenuItem>
                    <MenuItem 
                      onClick={async (e) => await handleChangeModalityPreference(e, "In Person")}
                    >
                      In-person Appointments
                    </MenuItem>
                    <MenuItem 
                      onClick={async (e) => await handleChangeModalityPreference(e, "Virtual")}
                    >
                      Virtual Appointments
                    </MenuItem>
                    <MenuItem 
                      onClick={async (e) => await handleChangeModalityPreference(e, "In Person and Virtual")}
                    >
                      In-person and Virtual Appointments
                    </MenuItem>
                  </Menu>
                </Box>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={filterOpen}
                  onClose={handleCloseFilter}
                  onClick={handleCloseFilter}
                  sx={{
                    borderRadius: "0.25rem",
                    minHeight: "2rem"
                }}>
                  <Typography sx={{
                    marginLeft: "1rem",
                  }}>
                    Filter by:
                  </Typography>
                  <MenuItem onClick={handleFilterByFavorites}>
                    <FavoriteIcon 
                      stroke="black"
                      strokeWidth="0.125"
                      sx={{
                        color: "red",
                    }}/> 
                      &nbsp; {filterByFavorites ? "Remove filter" : "Favorites"}
                  </MenuItem>
                </Menu>
              </Box>
            </Box> 
            <MiniPersonGrid 
              numCols={2} 
              filterByFavorites={filterByFavorites}
              setFilterByFavorites={setFilterByFavorites}
            />
        </Drawer>
    </Box>
  </>
    
   
  );
}

export default CustomDrawer;