const keys = {
  "INTRO_HEADER": "INTRO_HEADER",
  "INTRO_RIGHT_LINE1": "INTRO_RIGHT_LINE1",
  "INTRO_RIGHT_LINE2": "INTRO_RIGHT_LINE2",
  "INTRO_RIGHT_LINE3": "INTRO_RIGHT_LINE3",
  "COMPANY_NAME": "COMPANY_NAME",
  "NAVBAR_LOGIN": "NAVBAR_LOGIN",
  "NAVBAR_SUPPORT": "NAVBAR_SUPPORT",
  "NAVBAR_ABOUT": "NAVBAR_ABOUT",
  "ONBOARD_GREETING_TEXT_PRECEDING_NAME": "ONBOARD_GREETING_TEXT_PRECEDING_NAME",
  "ONE_RIGHT_ARROW": "ONE_RIGHT_ARROW",
  "ONBOARD_IDENTITY_CHOOSE_PROMPTS": "ONBOARD_IDENTITY_CHOOSE_PROMPTS",
  "ONBOARD_NEXT_BUTTON": "ONBOARD_NEXT_BUTTON",
  "RIGHT_ARROW_ONLY": "RIGHT_ARROW_ONLY",
  "REGISTRATION_LABEL_FIRST_NAME": "REGISTRATION_LABEL_FIRST_NAME",
  "REGISTRATION_LABEL_LAST_NAME": "REGISTRATION_LABEL_LAST_NAME",
  "REGISTRATION_LABEL_USERNAME": "REGISTRATION_LABEL_USERNAME",
  "REGISTRATION_LABEL_EMAIL": "REGISTRATION_LABEL_EMAIL",
  "REGISTRATION_LABEL_PASSWORD": "REGISTRATION_LABEL_PASSWORD",
  "REGISTRATION_BUTTON": "REGISTRATION_BUTTON",
  "LOGIN_BUTTON": "LOGIN_BUTTON"
}

export default keys