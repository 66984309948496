import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import "bootstrap/dist/css/bootstrap.min.css";
import store from './redux/store.js'
import theme from './css/Theme.js';
import { ThemeProvider } from '@mui/material/styles';
import { Box, Modal, Typography, Button } from '@mui/material';

import AppContainer from './AppContainer';
import TopLevelErrorBoundary from './TopLevelErrorBoundary.jsx';

const App = () => {
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const onMobile = window.innerWidth < 600;

  useEffect(() => {
    // Add an error handler to catch errors during rendering
    const errorHandler = (e) => {
      handleErrorModalOpen(e);
    };
    window.addEventListener('error', errorHandler);
    return () => window.removeEventListener('error', errorHandler);
  }, [])

  const handleErrorModalOpen = (e) => {
    e.preventDefault();
    localStorage.removeItem("state");
    setErrorModalOpen(true);
  };

  const handleErrorModalClose = (e) => {
    e.preventDefault();
    setErrorModalOpen(false);
    window.location.reload();
  };

  return (
    <>
      <TopLevelErrorBoundary>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <AppContainer />
          </Provider>
          <Modal
            open={errorModalOpen}
            onClose={e => handleErrorModalClose(e)}
          >
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              borderRadius: '0.75rem',
              boxShadow: 24,
              p: 4,
              width: onMobile ? '50%' : '90%',
              maxWidth: '500px',
              textAlign: 'center'

            }}>
              <Typography variant="h5" component="h2" id="error-modal-title">
                Sorry, something went wrong on our end.
              </Typography>
              <Typography variant="body1" component="p" id="error-modal-description">
                We're going to reload the page. If this issue keeps happening, please let us know at eng@caralyst.io!
              </Typography>
              <Button variant="outlined" sx={{ marginTop: "1rem", marginBottom: "-1rem" }} onClick={handleErrorModalClose}>
                Ok
              </Button>
            </Box>
          </Modal>
        </ThemeProvider>
      </TopLevelErrorBoundary>
    </>
  );
};

export default App;