import {
    SET_LOCATION,
    SET_INDEX,
    LOGOUT,
    SET_PREFERRED_SPECIALTY,
    SET_PREFERRED_INSURANCE,
    SET_LOGGED_IN,
    SET_ORG,
    SET_LAT_LONG,
    SET_REPORTING_A_BUG,
    SET_INSURANCE_OPTIONS,
    SET_SPECIALTY_OPTIONS,
    SET_PERSON_CARD_OPEN,
    SET_TERMS_AND_CONDITIONS_OPEN,
    SET_PATIENT_ID,
    SET_LOGGING_OUT,
    SET_MAP_BOUNDS,
    SET_SKELETON_SCREEN_OPEN,
    SET_POPUP_SURVEY_STATUS,
    SET_LOGGING_IN,
    SET_ON_FIRST_TUTORIAL,
    SET_ON_SECOND_TUTORIAL,
    SET_MAP_CENTER,
    SET_MODALITY_PREFERENCE,
    SET_DISTANCE_PREFERENCE,
} from '../constants/user.constants';

import { COGNITO_MAP, ROOT, org } from '../constants/shared.constants';
import axiosApiInstance from '../../utils/axiosConfig';
import { loadFavoriteDoctorIds } from './doctors.actions';
import unauthAxiosApiInstance from '../../utils/unauthAxiosConfig';
import { setMapLoading, setSnackbarMessage, setSnackbarOpen } from './shared.actions';
import { getTraitType, loadQuestionnaire, uploadTraitStrengthMapping } from './onboarding.actions';

/**
 * Set the user/patient's location in redux
 * @param {Object} location - The lat/long of the patient's location
 * @returns 
 */
export const setUserLocation = (location) => ({
    type: SET_LOCATION,
    payload: location,
})

/**
 * Set the current doctor card index in redux
 * @param {Number} index 
 * @returns 
 */
export const setCardIndex = (index) => ({
    type: SET_INDEX,
    payload: index,
})

/**
 * Set whether or not the patient is logged in
 * @param {Boolean} loggedIn 
 * @returns 
 */
export const setLoggedIn = (loggedIn) => ({
    type: SET_LOGGED_IN,
    payload: loggedIn
})

/**
 * Set the org in redux state
 * @param {String} org 
 * @returns 
 */
export const setOrg = (org) => ({
    type: SET_ORG,
    payload: org
})

/**
 * set the patient's lat/long in redux
 * @param {Object} latLong 
 * @returns 
 */
export const setLatLong = (latLong) => ({
    type: SET_LAT_LONG,
    payload: latLong,
});

export const setMapCenter = (mapCenter) => ({
    type: SET_MAP_CENTER,
    payload: mapCenter,
})

/**
 * Set whether or not the person card is open
 * @param {Boolean} personCardOpen 
 * @returns 
 */
export const setPersonCardOpen = (personCardOpen) => ({
    type: SET_PERSON_CARD_OPEN,
    payload: personCardOpen,
})

/**
 * Set whether or not the patient is reporting a bug
 * @param {Boolean} reportingABug
 * @returns
 * 
 */
export const setReportingABug = (reportingABug) => ({
    type: SET_REPORTING_A_BUG,
    payload: reportingABug,
})

/**
 * Set whether or not the terms and conditions are open
 * @param {Boolean} termsAndConditionsOpen 
 * @returns 
 */
export const setTermsAndConditionsOpen = (termsAndConditionsOpen) => ({
    type: SET_TERMS_AND_CONDITIONS_OPEN,
    payload: termsAndConditionsOpen,
})

/**
 * Set the patient's id in redux
 * @param {String} patientId - The patient's id
 * @returns 
 */
export const setPatientId = (patientId) => ({
    type: SET_PATIENT_ID,
    payload: patientId,
})

/**
 * Set whether or not the patient is logging out
 * @param {Boolean} loggingOut 
 * @returns 
 */
export const setLoggingOut = (loggingOut) => ({
    type: SET_LOGGING_OUT,
    payload: loggingOut,
})

/**
 * Set if the user is logging in
 * @param {boolean} loggingIn 
 * @returns 
 */
export const setLoggingIn = (loggingIn) => ({
    type: SET_LOGGING_IN,
    payload: loggingIn,
})

/**
 * Set the insurance options in redux
 * @param {Array} insuranceOptions - The insurance options for use in navbar
 * @returns 
 */
export const setInsuranceOptions = (insuranceOptions) => ({
    type: SET_INSURANCE_OPTIONS,
    payload: insuranceOptions,
})

/**
 * Set the specialty options in redux
 * @param {Array} specialtyOptions 
 * @returns 
 */
export const setSpecialtyOptions = (specialtyOptions) => ({
    type: SET_SPECIALTY_OPTIONS,
    payload: specialtyOptions,
})

/**
 * Set the left, right, top, and bottom bounds in redux
 * @param {Object} leftRightBounds 
 * @param {Object} topBottomBounds 
 * @returns 
 */
export const setMapBounds = (leftRightBounds, topBottomBounds) => ({
    type: SET_MAP_BOUNDS,
    payload: {
        leftRightBounds,
        topBottomBounds,
    },
});

/**
 * Set whether or not the skeleton screen is open
 * @param {Boolean} skeletonScreenOpen 
 * @returns 
 */
export const setSkeletonScreenOpen = (skeletonScreenOpen) => ({
    type: SET_SKELETON_SCREEN_OPEN,
    payload: skeletonScreenOpen,
});

export const setOnFirstTutorial = (onFirstTutorial) => ({
    type: SET_ON_FIRST_TUTORIAL,
    payload: onFirstTutorial,
})

export const setOnSecondTutorial = (onSecondTutorial) => ({
    type: SET_ON_SECOND_TUTORIAL,
    payload: onSecondTutorial,
})

export const setModalityPreference = (modalityPreference) => ({
    type: SET_MODALITY_PREFERENCE,
    payload: modalityPreference,
});

export const setDistancePreference = (distancePreference) => ({
    type: SET_DISTANCE_PREFERENCE,
    payload: distancePreference,
});

/**
 * Set the preferred insurances for the patient in redux and DB
 * @param {Array} insurances - The insurance to set as preferred
 * @param {String} org - The organization for the patient
 * @param {Boolean} loggedIn - Whether or not the patient is logged in
 * @param {String} patientId - The patient's id
 * @returns 
 */
export const setPreferredInsurance = (insurances, org, loggedIn, patientId) => async (dispatch) => {
    await dispatch({
        type: SET_PREFERRED_INSURANCE,
        payload: insurances,
    });

    if(loggedIn)
        try {
            await unauthAxiosApiInstance.put(ROOT + `/insurance-preference?org=${org}&patientId=${patientId}`, {insurances})
        }
        catch (e) {
            console.log("Error saving insurance preference: ");
            console.log(e);
        }
}

/**
 * Set the preferred specialties for the patient in DB and redux
 * @param {Array} specialties - The specialties to set as preferred
 * @param {String} org - The organization for the patient
 * @param {Boolean} loggedIn - Whether or not the patient is logged in
 * @param {String} patientId - The patient's id
 * @returns 
 */
export const setPreferredSpecialty = (specialties, org, loggedIn, patientId) => async (dispatch)  => {
    await dispatch({
        type: SET_PREFERRED_SPECIALTY,
        payload: specialties
    });

    if(loggedIn)
        try {
            await unauthAxiosApiInstance.put(ROOT + `/specialty-preference?org=${org}&patientId=${patientId}`, { specialties })
        } catch (e) {
            console.log("Error saving specialty preference: ");
            console.log(e);
        }
}

/**
 * Get the lat long for a given location input
 * @param {*} e - The event
 * @param {*} location - The location that has been gotten
 * @returns 
 */
export const updateUserLatLong = (e, location, patientId) => async (dispatch) => {
    e.preventDefault();
    console.warn("USING GOOGLE API")
    try {
        if(!location) {
            await setLocationEmpty(patientId, dispatch);
            return;
        }

        const latLong = await getUserLatLong(location);
        const { newLeftRightBounds, newTopBottomBounds } = getMapBounds(latLong);
        if(newLeftRightBounds && newTopBottomBounds && newLeftRightBounds[0] && newLeftRightBounds[1] && newTopBottomBounds[0] && newTopBottomBounds[1]) {
            await dispatch(setMapBounds(newLeftRightBounds, newTopBottomBounds));
        }

        if(!latLong.lat || !latLong.lng)
            throw new Error("Error getting lat long from google api");

        const res = await unauthAxiosApiInstance.put(ROOT + `/user-location`, { patientId, location, latLong});

        if(!(res.status === 200)) 
            throw new Error("Error updating user location");
        
        if(res.status === 200 && location) {
            await dispatch(setLatLong(latLong))
            await dispatch(setMapCenter(latLong))
        } else if(location === null){
            await dispatch(setLatLong({}))
        }
    } catch (e) {
        console.log("Error updating location. Please try again later.");
        console.log(e);
    }
};

const getMapBounds = (mapCenter) => {
    const scaleFactor = Math.pow(2, 11);

    const geographicWidth = window.innerWidth/scaleFactor;
    const geographicHeight = window.innerHeight/scaleFactor;

    const newTopBottomBounds = [mapCenter.lat - geographicWidth/2, mapCenter.lat + geographicWidth/2];
    const newLeftRightBounds = [mapCenter.lng - geographicHeight/2, mapCenter.lng + geographicHeight/2];
    
    return {newLeftRightBounds, newTopBottomBounds};
}

const getUserLatLong = async (location) => {
    const response = await unauthAxiosApiInstance.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${location}&key=AIzaSyB0xLFvDaCgwUZXgAyR2alDcxjHfS68zIo`);
    try {
        const latLong = response?.data?.results[0]?.geometry?.location;
        return latLong;
    } catch(e) {
        console.log("Error getting lat long from google api");
        console.log("location: ")
        console.log(location)
        console.log(e);
    }
}

const setLocationEmpty = async (patientId, dispatch) => {
    const res = await unauthAxiosApiInstance.put(ROOT + `/user-location`, { patientId, location: "", latLong: {}});
    if(!(res.status === 200)) 
        throw new Error("Error updating user location");

    await dispatch(setLatLong({}));
}

/**
 * Get the insurance opetions to populate navbar
 * 
 * @param {String} org - The organization for the patient
 */
export const getInsuranceOptions = (org) => async (dispatch) => {
    try {
        const response = await unauthAxiosApiInstance.get(
            ROOT + `/insurance-options?org=${org}`
        );
        let insuranceOptions = response?.data?.insuranceOptions;
        insuranceOptions = insuranceOptions.map((option) => option.name);
        await dispatch(setInsuranceOptions(insuranceOptions));
    } catch (e) {
        console.log("Error getting insurance options. Please try again later.");
        console.log(e);
    }
};

/**
 * Get the specialty options to populate navbar
 * 
 * @param {String} org - The organization for the patient
 */
export const getSpecialtyOptions = (org) => async (dispatch) => {
    try {
        const response = await unauthAxiosApiInstance.get(
            ROOT + `/specialty-options?org=${org}`
        );
        let specialtyOptions = response?.data?.specialtyOptions;
        specialtyOptions = specialtyOptions.map((option) => option.name);
        await dispatch(setSpecialtyOptions(specialtyOptions));
    } catch (e) {
        console.log("Error getting specialty options. Please try again later.");
        console.log(e);
    }
};

/**
 * Send the user to cognito login page
 */
export const loginPatient = () => async (dispatch) => {
    try {
        const url = await unauthAxiosApiInstance.get(COGNITO_MAP["auth_url"]);
        window.location.href = url.data;
    } catch (e){
        console.log("Error logging in. Please try again later.")
        console.error(e);
    }
}

/**
 * Update when the patient was last seen on our site
 * @param {String} patientId - The patient's id
 * @returns 
 */
export const updateLastSeen = (patientId) => async (dispatch) => {
    try {
        await unauthAxiosApiInstance.put(
            ROOT + `/last-seen?patientId=${patientId}`
        );
    } catch (e) {
        console.log("Error updating last seen. Please try again later.");
        console.log(e);
    }
};

//TODO(sarah): refactor actions files to separate action creators and API calls
/**
 * Get when the patient was last seen on our site
 * @param {String} patientId - The patient's id
 * @returns 
 */
export const getLastSeen = (patientId, loggedIn) => async (dispatch) => {
    try {
        const response = await unauthAxiosApiInstance.get(
            ROOT + `/last-seen?patientId=${patientId}`
        );

        let lastSeen = response?.data?.lastSeen;

        //if last seen is greater than one day ago, log user out or remove state and refresh
        const oneDayAgo = new Date();
        oneDayAgo.setDate(oneDayAgo.getDate() - 1);

        if(lastSeen && loggedIn && new Date(lastSeen) < oneDayAgo) {
            localStorage.setItem("expired_tokens", JSON.stringify(true));
            window.location.href = "/";
            return;
        } else if(lastSeen && new Date(lastSeen) < oneDayAgo) {
            localStorage.removeItem("state");
            window.location.href = "/";
            return;
        }

        if(!lastSeen) 
            throw new Error(`No last seen found for ${patientId}`);

        // if(lastSeen?.lastSeen === null) 
        await dispatch(setOnFirstTutorial(true));

    } catch (e) {
        console.log(e);
        console.log("Error getting last seen. Please try again later.");
    }
};

/**
 * Depending on the patient's auth state, we ned to update the redux store on login/page reload
 * @param {String} org - The organization for the patient
 * @param {String} patientId - The patient's id
 * @param {Object} leftRightBounds - The left and right bounds of the map
 * @param {Object} topBottomBounds - The top and bottom bounds of the map
 * @param {Array} specialtyPreference - The patient's specialty preferences
 * @param {Array} insurancePreference - The patient's insurance preferences
 * @param {Boolean} popupSurveyStatus - Whether or not the popup survey should be opened
 * @returns 
 */
export const getCurrentUserState = (org, patientId, popupSurveyStatus) => async (dispatch) => {
    //get url
    const url = new URL(window.location.href);
    //get params from url like authenticated, access token, refresh token
    const params = new URLSearchParams(url.search);
    const authenticated = params.get("authentication_status");
    const traits = params.get("traits");

    let updatedPatientId = patientId;
    if(authenticated) {
        //User just logged in, authenticated = true and there is no tokens
        // TODO: find out why preferred specialties and insurances are not being set
        updatedPatientId = await dispatch(authFlowLogin(org, patientId, params, popupSurveyStatus));
    } else if(localStorage.getItem("access_token") !== null && localStorage.getItem("refresh_token") !== null && patientId) {
        //User is already logged in
        updatedPatientId = await dispatch(authFlowLoggedIn(org, patientId, popupSurveyStatus));
    } else if(patientId) {
        //User is not logged in but has a session_id
        updatedPatientId = await dispatch(authFlowGuest(patientId, org));
    } else {
        //Brand new user
        updatedPatientId = await dispatch(authFlowBrandNewUser(org));
    }
    
    if(traits && traits.length > 0)
        await dispatch(populateTraitsForPatient(traits, org, updatedPatientId));

    return updatedPatientId;
}

const populateTraitsForPatient = (traits, org, patientId) => async (dispatch) => {
    try {
        const traitStrengths = [];

        let answerIdAndType = await dispatch(getTraitType(traits));
        if(!answerIdAndType || !answerIdAndType?.length)
            throw new Error("Error getting trait type for patient");
        
        let previousAnswers = await dispatch(loadQuestionnaire(patientId));
        console.log(previousAnswers)
        if(previousAnswers?.questionnaire_progress)
            previousAnswers = previousAnswers.questionnaire_progress
        else 
            previousAnswers = {};

        answerIdAndType.forEach((idAndTypeObj, _) => {
            const { trait_id, answer_id, answer_type } = idAndTypeObj;

            traitStrengths.push({
                traitId: trait_id,
                strength: 1,
                status: "CLICKED",
            });

            if(answer_type === "SLIDER") {
                previousAnswers[answer_id] = 1;
            } else if(answer_type === "RADIO") {
                previousAnswers[answer_id] = true;
            } else if (answer_type === "MULTISELECT") {
                previousAnswers[answer_id] = true;
            } else if (answer_type === "EXPANDING_MULTISELECT") {
                previousAnswers[answer_id] = true;
            }
        });

        await dispatch(uploadTraitStrengthMapping(traitStrengths, previousAnswers, patientId));
        window.history.replaceState({}, document.title, "/home");
    } catch(e) {
        console.log("Error populating traits for patient: ");
        console.log(e);
    }
}

/**
 * Auth flow for when the user has just logged in 
 * @param {String} org - The organization for the patient
 * @param {String} patientId - The patient's id
 * @param {Object} params - The params from the url
 * @param {Boolean} popupSurveyStatus - Whether or not the popup survey should be opened
 * @param {import('redux').Dispatch} dispatch 
 * @returns 
 */
const authFlowLogin = (org, patientId, params, popupSurveyStatus) => async (dispatch) => {
    await dispatch(setLoggingIn(true));
    //Set the tokens
    const accessToken = params.get("access_token");
    const refreshToken = params.get("refresh_token");
    //If there is a session_id then remove it
    localStorage.setItem("access_token", accessToken);
    localStorage.setItem("refresh_token", refreshToken);
    //Make sure the user has an associate cognito id, mostly for first time users
    const id = await dispatch(checkCognitoId(accessToken, org, patientId))
    //If they are sent from a review link, redirect them to the review page
    if(localStorage.getItem("review_link")) {
        const link = localStorage.getItem("review_link")
        window.location.href = link;
        return;
    }
    if(localStorage.getItem("survey_link") && !popupSurveyStatus) {
        const link = localStorage.getItem("survey_link")
        window.location.href = link;
        return;
    }
    //Get the patient data
    await dispatch(loadPatientData(org, id)); 
    //If they are sent from a review link, redirect them to the review page
    await dispatch(setLoggedIn(true));
    await dispatch(setLoggingIn(false));
    window.history.replaceState({}, document.title, "/home");
    return id;
}

/**
 * Auth flow for when the user is already logged in
 * 
 * @param {String} org - The organization for the patient
 * @param {String} patientId - The patient's id
 * @param {Object} leftRightBounds - The left and right bounds of the map
 * @param {Object} topBottomBounds - The top and bottom bounds of the map
 * @param {Array} specialtyPreference - The patient's specialty preferences
 * @param {Array} insurancePreference - The patient's insurance preferences
 * @param {Boolean} popupSurveyStatus - Whether or not the popup survey should be opened
 * @param {import('redux').Dispatch} dispatch
 */
const authFlowLoggedIn = (org, patientId, popupSurveyStatus) => async (dispatch) => {
    //If there is a session_id then remove it
    if(localStorage.getItem("review_link")) {
        const link = localStorage.getItem("review_link") 
        window.location.href = link;
        return patientId;
    }
    if(localStorage.getItem("survey_link") && !popupSurveyStatus) {
        const link = localStorage.getItem("survey_link")
        window.location.href = link;
        return patientId;
    }
    if(localStorage.getItem("current_user")) {
        const link = localStorage.getItem("current_user")
        window.location.href = link;
        localStorage.removeItem("current_user")
        return patientId; 
    }
    const id = await dispatch(checkCognitoId(localStorage.getItem("access_token"), org, patientId))
    //Set the user as logged in
    await dispatch(setLoggedIn(true));
    //Get the patient data
    await dispatch(loadPatientData(org, id)); 
    //Load the favorite doctor ids
    await dispatch(loadFavoriteDoctorIds(org, true));
    return id;
}

/**
 * Auth flow for when the user is not logged in/guest
 * 
 * @param {String} org - The organization for the patient
 * @param {String} patientId - The patient's id
 * @param {Object} leftRightBounds - The left and right bounds of the map
 * @param {Object} topBottomBounds - The top and bottom bounds of the map
 * @param {Array} specialtyPreference - The patient's specialty preferences
 * @param {Array} insurancePreference - The patient's insurance preferences
 * @param {import('redux').Dispatch} dispatch
 * @returns
 */
const authFlowGuest = (patientId, org) => async (dispatch) => {
    await dispatch(setLoggedIn(false));
    //load docs
    const id = await dispatch(checkCognitoId(undefined, org, patientId));
    await dispatch(loadPatientData(org, id)); 
    return id;
}

/**
 * Auth flow for when the user is brand new
 * 
 * @param {String} org - The organization for the patient
 * @param {String} patientId - The patient's id
 * @param {Object} leftRightBounds - The left and right bounds of the map
 * @param {Object} topBottomBounds - The top and bottom bounds of the map
 * @param {Array} specialtyPreference - The patient's specialty preferences
 * @param {Array} insurancePreference - The patient's insurance preferences
 * @param {import('redux').Dispatch} dispatch
 */
const authFlowBrandNewUser = (org) => async (dispatch) => {
    const id = await dispatch(checkCognitoId(undefined, org, null))
    if(id) {
        await dispatch(setPatientId(id));
        await dispatch(loadFavoriteDoctorIds(org, false));
        return id;
    }
}

/**
 * Does a lot of fancy server side business. Basically checks if user is new, make new user, if not, set existing id.
 * @param {String} accessToken - The access token from cognito
 * @param {String} org - The organization for the patient
 * @param {String} sessionId - The session id for the patient
 * @returns 
 */
export const checkCognitoId = (accessToken, org, sessionId) => async (dispatch) => {
    try {
        const res = await unauthAxiosApiInstance.post(ROOT + '/check-cognito-id', { accessToken, org, sessionId });
        if(res?.data) {
            const { user }  = res.data;
            await dispatch(setPatientId(user.id));
            return user.id;
        }
    } catch (e) {
        console.log("Error checking cognito id: ");
        console.log(e);
    }
}

/**
 * Load specialty and insrance preferences, and popup survey status as well as any other patient data
 * @param {String} org 
 * @param {String} patientId 
 * @returns 
 */
const loadPatientData = (org, patientId) => async (dispatch) => {
    try {
        if (!patientId) return;

        const specialties = await unauthAxiosApiInstance.get(ROOT + `/specialty-preference?org=${org}&patientId=${patientId}`);
        const insurance = await unauthAxiosApiInstance.get(ROOT + `/insurance-preference?org=${org}&patientId=${patientId}`);
        const location = await unauthAxiosApiInstance.get(ROOT + `/user-location?patientId=${patientId}`);

        const popupSurveyRes = await unauthAxiosApiInstance.get(ROOT + `/popup-survey-answered?org=${org}&patientId=${patientId}`);
        // const firstname = await axiosApiInstance.post(COGNITO_MAP["user_info"], { access_token: localStorage.getItem("access_token") });
        if(specialties?.data)
            await dispatch({
                type: SET_PREFERRED_SPECIALTY,
                payload: specialties.data.specialties
            });
        if(insurance?.data)
            await dispatch({
                type: SET_PREFERRED_INSURANCE,
                payload: insurance.data.insurances
            });
        if(location?.data?.location) {
            await dispatch({
                type: SET_LAT_LONG,
                payload: {lat: location.data.location.latitude, lng: location.data.location.longitude}
            });
            await dispatch({
                type: SET_MAP_CENTER,
                payload: {lat: location.data.location.latitude, lng: location.data.location.longitude}
            })
            await dispatch({
                type: SET_LOCATION,
                payload: location.data.location.address
            });
        }
        if(popupSurveyRes?.data)
            await dispatch({
                type: SET_POPUP_SURVEY_STATUS,
                payload: popupSurveyRes.data.popupSurveyStatus
            });
    } catch (e) {
        console.log("catch load user data error");
        console.log(e);
    }
};

/**
 * Logout the patient
 */
export const logout = () => async (dispatch) => {
    try {
        await revokeRefreshToken();

        localStorage.removeItem('refresh_token');
        localStorage.removeItem('access_token');

        await dispatch({ type: LOGOUT })
        
        const res = await unauthAxiosApiInstance.get(COGNITO_MAP["logout_url"])
        if(res.status === 200) {
            window.location.href = res.data;
        }
    } catch (e) {
        console.log("error for logging out is: ");
        console.log(e);
    }
}

/**
 * Revoke refresh token using cognito
 * @param {import('redux').Dispatch} dispatch
 */
const revokeRefreshToken = async (dispatch) => {
    const refreshToken = localStorage.getItem('refresh_token')
    try {
        await unauthAxiosApiInstance.post(COGNITO_MAP["revoke"], {
            refresh_token: refreshToken
        })
    } catch(e) {
        console.log("Error revoking refresh token: ")
        console.log(e)
    }
}

/**
 * Submit patient review for a doctor
 * 
 * @param {Object} traitRatings - The trait ratings for the doctor
 * @param {String} org - The organization for the patient
 * @param {Object} appointment - The appointment object
 * @param {Boolean} modifyingTraits - Whether or not the patient is modifying traits
 * @param {Number} previousDocReviewSize - The previous size of the doctor review
 * @returns the response from the server
 */
export const submitReview = (traitRatings, org, appointment, modifyingTraits, previousDocReviewSize) => async (dispatch) => {
    try {
        const res = await axiosApiInstance.post(ROOT + `/appointment?org=${org}`, {appointment,  modifyingTraits, previousDocReviewSize})
        if(!res?.data?.appointmentRes || res.status === 101)
            return res;
        const appointmentId = res?.data?.appointmentRes?.id;
        if(modifyingTraits)
            try {
                const res = await axiosApiInstance.post(ROOT + `/trait-ratings?org=${org}`, {traitRatings, appointmentId})
                return res;
            } catch(e) {
                console.log("Error submitting review: ")
                console.log(e)
                return false;
            }
        else {
            return res
        }
    } catch(e) {
        console.log("Error submitting review: ")
        console.log(e);
        return false;
    }
}

/**
 * Get image urls from AWS S3
 * @param {Array} images 
 * @returns 
 */
const getImageUrlsFromS3 = async (images) => {
    const imageUrls = [];
    for(let image of images) {
        const imageType = "image/" + image?.name?.split(".").pop();
        const body = {
            bucket: "caralyst-bug-images",
            key: `directory/${window.performance.now()}`,
            mime: imageType,
        }
        const imageUrl = await axiosApiInstance.post(COGNITO_MAP["s3_upload"], body)
        try {
            const headers = {
                'Content-Type': imageType,
            };
            // Make the PUT request to the pre-signed URL
            const imageUpload = await unauthAxiosApiInstance.put(imageUrl.data, image, { headers });
            if(imageUpload.status === 200) {
                imageUrls.push(imageUrl.data.split("?")[0]);
            }
        } catch(e) {
            console.log("Error uploading image: ")
            console.log(e)
        }
    }
    return imageUrls;
}

/**
 * Submit bug report to Jira
 * @param {String} summary 
 * @param {String} description 
 * @param {Array} images 
 * @param {String} org 
 * @returns 
 */
export const reportABug = (summary, description, images, org) => async (dispatch) => {
    try {
        const imageUrls = await getImageUrlsFromS3(images);
        const body = {
            summary,
            description,
            links: imageUrls,
        }
        const res = await unauthAxiosApiInstance.post(COGNITO_MAP["report_bug"], body);
        if(res.status === 200)
            return true;
        else
            return false;
    } catch(e) {
        console.log("Error submitting bug report.")
        console.log(e);
        return false;
    }
}

/**
 * Save the popup survey responses in DB
 * @param {Object} questionsAndAnswers - The questions and answers for the popup survey
 * @param {String} org - The organization for the patient
 * @returns 
 */
export const savePopupSurveyResponses = (questionsAndAnswers, org) => async (dispatch) => {
    try {
        const res = await axiosApiInstance.post(ROOT + `/popup-survey-responses?org=${org}`, { questionsAndAnswers });
        if(res.status === 200)
            return true;
        else
            return false;
    } catch(e) {
        console.log("Error in savePopupSurveyResponses.")
        console.log(e);
    }
}

export const initBounds = (latLong, location, patientId, oldLeftRightBounds, oldTopBottombounds, secondTry) => async (dispatch) => {
    await dispatch(setMapLoading(true));

    if(!isFalsy(latLong) && !isFalsy(location)) {
        await dispatch(updateUserLatLong({preventDefault: () => {}}, location, patientId));
        await dispatch(setMapBounds(oldLeftRightBounds, oldTopBottombounds));
        await dispatch(setMapLoading(false));
        return;
    }

    //If org is wustl, set location to stl
    if(org === "wustl") {
        await dispatch(setBaseBounds(patientId, false));
        return;
    }

    //If geolocation is not supported by this browser, set location to STL
    if(!navigator.geolocation) {
        console.log("Geolocation is not supported by your browser");
        await dispatch(setBaseBounds(patientId, true));
        return;
    }

    //Try to use navigator to get the user's location
    await navigator.geolocation.getCurrentPosition(async (position) => {
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;
        //get address name based on position
        const geocoder = new window.google.maps.Geocoder();
        await geocoder.geocode({ location: { lat: lat, lng: lng } }, async (results, status) => {
            if (status === "OK") {
                if (results[0]) {
                    const address = results[0].formatted_address;
                    await dispatch(setUserLocation(address));
                    await dispatch(updateUserLatLong({preventDefault: () => {}}, address, patientId));
                } else if(!secondTry) {
                    console.log("No results found");
                    //if this fails, try one more time
                    if(!secondTry) {
                        await dispatch(initBounds(latLong, location, patientId, oldLeftRightBounds, oldTopBottombounds, true));
                    }
                } else {
                    await dispatch(setBaseBounds(patientId, true));
                }
            } else {
                console.log("Geocoder failed due to: " + status);
                //if this fails, try one more time
                if(!secondTry) {
                    console.log("Trying to get location again...")
                    await dispatch(initBounds(latLong, location, patientId, oldLeftRightBounds, oldTopBottombounds, true));
                }
            }
        });
        //use the width and height of the map to calculate the bounds, need to account for drawer width
        const mapWidth = window.innerWidth - 515;
        const mapHeight = window.innerHeight - 64;
        const mapCenter = {lat: lat, lng: lng};
        const mapBounds = {
            north: mapCenter.lat + mapHeight * 0.0001,
            south: mapCenter.lat - mapHeight * 0.0001,
            east: mapCenter.lng + mapWidth * 0.0001,
            west: mapCenter.lng - mapWidth * 0.0001,
        };
        const newLeftRightBounds = [mapBounds.west, mapBounds.east];
        const newTopBottomBounds = [mapBounds.south, mapBounds.north];

        await dispatch(setMapBounds(newLeftRightBounds, newTopBottomBounds));
        await dispatch(setLatLong(mapCenter));
        await dispatch(setMapLoading(false));
    }, async (error) => {
        console.log(error);
        if (error.code === error.PERMISSION_DENIED) {
            // User denied the request for Geolocation
            await dispatch(setBaseBounds(patientId, false));
        } else {
            console.error('Error getting user location:', error);
            //if this fails, try one more time
            if(!secondTry) {
                console.log("Trying to get location again...")
                await dispatch(initBounds(latLong, location, patientId, oldLeftRightBounds, oldTopBottombounds, true));
            }
        }
    });
}

const isFalsy = (value) => {
    if(value === undefined || value === null || (typeof value === "object" && Object.keys(value).length === 0)) {
        return true;
    }
}

const setBaseBounds = (patientId, wasError) => async (dispatch) => {
    const center = org === "wustl" ? {lat: 38.6356, lng: -90.2652} : { lat: 38.6270, lng: -90.1994 };
    const locationName = org === "wustl" ? "Washington University School of Medicine" : "St. Louis, MO";
    //set map bounds based on center and width/height of map
    const mapWidth = window.innerWidth;
    const mapHeight = window.innerHeight;
    const mapCenter = {lat: center.lat, lng: center.lng};
    const mapBounds = {
        north: mapCenter.lat + mapHeight * 0.0001,
        south: mapCenter.lat - mapHeight * 0.0001,
        east: mapCenter.lng + mapWidth * 0.0001,
        west: mapCenter.lng - mapWidth * 0.0001,
    };
    const newLeftRightBounds = [mapBounds.west, mapBounds.east];
    const newTopBottomBounds = [mapBounds.south, mapBounds.north];

    if(wasError)
        await dispatch(setSnackbarMessage("Error getting your location. Setting the default location to St. Louis, MO."));
    else if(org === "wustl")
        await dispatch(setSnackbarMessage("Setting the default location to WUSM."));
    else
        await dispatch(setSnackbarMessage("Setting the default location to St. Louis, MO."));

    await dispatch(setSnackbarOpen(true));
    await dispatch(setMapBounds(newLeftRightBounds, newTopBottomBounds));
    await dispatch(updateUserLatLong({preventDefault: () => {}}, locationName, patientId));
    await dispatch(setLatLong(center));
    await dispatch(setUserLocation(locationName));
    await dispatch(setMapLoading(false));
}

/**
 * Submit analytics event
 * 
 * @param {String} eventType - The type of event being logged (e.g., 'profile_view')
 * @returns
 */
export const submitAnalyticsEvent = (eventType, doctorId) => async (dispatch) => {
    try {
        const res = await unauthAxiosApiInstance.post(ROOT + `/analytics`, { eventType, doctorId });
        if(res.status === 200) {
            console.log("Success submitting analytics event");
            return true;
        } else {
            return false;
        }
    } catch (e) {
        console.log("Error submitting analytics event: ");
        console.log(e);
    }
};