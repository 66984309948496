import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    selectDoctors,
    selectDoctorsLoadingStatus,
    selectFavoriteDoctorIds,
    selectLeftRightBounds,
    selectModalityPreference,
    selectOnFirstTutorial,
    selectOnSecondTutorial,
    selectPatientId,
    selectPersonCardOpen,
    selectPopupSurveyStatus,
    selectPopupSurveyTimeoutSet,
    selectPreferredInsurance,
    selectPreferredSpecialty,
    selectTempDocId,
    selectTopBottomBounds
} from "../../redux/store";
import { loadDoctors, setFilteredDocs } from '../../redux/actions/doctors.actions';

import { Box } from '@mui/material';

import CustomDrawer from './CustomDrawer';
import Maps from './Maps';
import Joyride, { STATUS } from 'react-joyride';
import { setOnFirstTutorial, setOnSecondTutorial } from '../../redux/actions/user.actions';
import { openPersonCardFromUrl } from '../../redux/actions/shared.actions';

const HomePage = () => {
    const dispatch = useDispatch();

    const doctors = useSelector(selectDoctors);
    const favoriteDoctorIds = useSelector(selectFavoriteDoctorIds);
    const personCardOpen = useSelector(selectPersonCardOpen);
    const onFirstTutorial = useSelector(selectOnFirstTutorial);
    const onSecondTutorial = useSelector(selectOnSecondTutorial);
    const patientId = useSelector(selectPatientId);
    const leftRightBounds = useSelector(selectLeftRightBounds);
    const topBottomBounds = useSelector(selectTopBottomBounds);
    const specialtyPreference = useSelector(selectPreferredSpecialty);
    const insurancePreference = useSelector(selectPreferredInsurance);
    const tempDocId = useSelector(selectTempDocId);
    const modalityPreference = useSelector(selectModalityPreference);
    const popupSurveyStatus = useSelector(selectPopupSurveyStatus);
    const popupSurveyTimeoutSet = useSelector(selectPopupSurveyTimeoutSet);
    const loadingDocs = useSelector(selectDoctorsLoadingStatus);
 
    const [filterByFavorites, setFilterByFavorites] = useState(false); 
    const loadingDocsRef = useRef(false);

    useEffect(() => {
        const bootstrapLoadDocs = async () => {
            //check if filtereddocs contains doc with tempDocId
            if(!loadingDocs && !loadingDocsRef.current && leftRightBounds && topBottomBounds && patientId){
                loadingDocsRef.current = true;
                const curDocs = await dispatch(loadDoctors(patientId, leftRightBounds, topBottomBounds, specialtyPreference, insurancePreference, false, tempDocId, modalityPreference));
                loadingDocsRef.current = false;
                await dispatch(openPersonCardFromUrl(curDocs, popupSurveyStatus, popupSurveyTimeoutSet));
            }
        }
        bootstrapLoadDocs();
    }, [dispatch, leftRightBounds, tempDocId]);

    // Update filtered docs when docs are loaded, or when filterByFavorites is toggled
    useEffect(() => {
        const updateFilteredDocs = async () => {
            await dispatch(setFilteredDocs(filterDocs())); 
        }
        updateFilteredDocs();
    }, [dispatch, filterByFavorites, onFirstTutorial, onSecondTutorial])
    
    /*** 
     * Check if a doctor is a favorite
     * 
     * @param {Object} personInfo - doctor object
     * @param {Array} favDocIds - array of favorite doctor ids
     */
    const isFavorite = (personInfo, favDocIds) => {
        return favDocIds.includes(personInfo.id);
    }

    const filterDocs = () => {
        let newFilteredDocs = doctors;
        if(filterByFavorites) {
            newFilteredDocs = newFilteredDocs.filter((personInfo) => isFavorite(personInfo, favoriteDoctorIds));
        }
        return newFilteredDocs;
    }

    // steps for the first joyride tour
    const steps = [
        {
            target: "body",
            content: "Welcome!! Please spare a minute to learn about our page",
            placement: "center"
        },
        {
            target: ".tour-step-1",
            content: "This is the search bar. It filters providers by location, specialty, and insurance."
        },
        {
            target: ".tour-step-2",
            content: "This is your account menu."
        },
        {
            target: ".tour-step-3",
            content: "Click here to take our quick survey to find you a compatible provider."
        },
        {
            target: '.component-to-click-on',
            content: "Click on a doctor's profile to view more information about them and why you matched.",
        }
    ];

    // steps for the second joyride tour
    const steps2 = [
        {
            target: "body",
            content: "Now let's learn more about a doctor through their profile.",
            placement: "center"
        },
        {
            target: ".pfp-wrapper",
            content: "Scroll down to read more about the provider's background"
        }
    ]

    const handleJoyrideCallbackOne = async (data) => {
        const { status, type } = data;
        const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

        if (finishedStatuses.includes(status)) {
            await dispatch(setOnFirstTutorial(false));
            await dispatch(setOnSecondTutorial(true));
        }
    }

    const handleJoyrideCallbackTwo = async (data) => {
        const { status, type } = data;
        const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

        if (finishedStatuses.includes(status)) {
            await dispatch(setOnSecondTutorial(false));
        }
    }

    return (
        <Box
            className="home-main-container"
            sx={{
                color: "#efdab9 !important",
                backgroundColor: "#F6F8FB",
                display: "flex",
                flexDirection: "column",
                fontWeight: "300 !important",
                width: "100%",
                height: "100%",
        }}>
            <Box sx={{
                paddingTop: "4rem",
            }}>
                <Box>   
                    <CustomDrawer
                        filterByFavorites={filterByFavorites}
                        setFilterByFavorites={setFilterByFavorites}
                    />
                </Box>
                <Box>
                    <Maps className="tour-step-2" />
                </Box>
                    {onFirstTutorial && !onSecondTutorial &&
                        <Joyride
                            steps={steps}
                            continuous={true}
                            callback={async(e) => await handleJoyrideCallbackOne(e)}
                            styles={{
                                options: {
                                    arrowColor: "#CC2B61",
                                    backgroundColor: "#CC2B61",
                                    overlayColor: "transparent",
                                    primaryColor: "#CC2B61",
                                    textColor: "#fff",
                                    zIndex: 10000
                                },
                                tooltip: {
                                    fontSize: 18,
                                    fontFamily: "Inter",
                                    fontWeight: 700,
                                },
                                tooltipContent: {
                                    padding: '10px 5px',
                                    lineHeight: 1.5
                                },
                                buttonNext: {
                                    backgroundColor: "#6A1531",
                                    textColor: "#fff"
                                },
                                buttonBack: {
                                    display: 'none'
                                }
                            }}
                            showProgress={true}
                            showSkipButton={true}
                            disableScrolling={true}
                    />}
                    {!onFirstTutorial && onSecondTutorial && personCardOpen &&
                        <Joyride
                            steps={steps2}
                            continuous={true}
                            callback={async (e) => await handleJoyrideCallbackTwo(e)}
                            styles={{
                                options: {
                                    arrowColor: "#CC2B61",
                                    backgroundColor: "#CC2B61",
                                    overlayColor: "transparent",
                                    primaryColor: "#CC2B61",
                                    textColor: "#fff",
                                    zIndex: 10000
                                },
                                tooltip: {
                                    fontSize: 18,
                                    fontFamily: "Inter",
                                    fontWeight: 700,
                                },
                                tooltipContent: {
                                    padding: '10px 5px',
                                    lineHeight: 1.5
                                },
                                buttonNext: {
                                    backgroundColor: "#6A1531",
                                    textColor: "#fff"
                                },
                                buttonBack: {
                                    display: 'none'
                                }
                            }}
                            showProgress={true}
                            showSkipButton={true}
                            disableScrolling={true}
                    />}
            </Box>
        </Box>

    );
}


export default HomePage;
