import React from 'react';
import { ROOT } from '../constants/shared.constants';

import {
    DOCTORS_LOAD_REQUEST,
    DOCTORS_LOAD_SUCCESS,
    DOCTORS_LOAD_FAILURE,
    FAVORITE_DOCTORS_LOAD_SUCCESS,
    FAVORITE_DOCTOR_IDS,
    PREVIOUS_DOC_REVIEW,
    SET_DOCTOR_DISTANCE,
    RESET_DOCTOR_DISTANCE,
    SET_FILTERED_DOCS,
    SET_DOC_MARKER_FROM_PERSON_CARD,
    SET_EXISTS_MATCHING_PROVIDERS,
    FAVORITE_DOCTORS_LOAD_REQUEST,
    FAVORITE_DOCTORS_LOAD_FAILURE,
    SET_CLINICS,
    SET_LOADING_DOCS,
    SET_TEMP_DOC_ID,
    SET_TEMP_DOC_LOC,
    SET_TEMP_DOC_REASONS_FOR_MATCHING
} from '../constants/doctors.constants.js';

import axiosApiInstance from '../../utils/axiosConfig';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
//Required for proper formatting with blur effect
import BlankProfilePicture from '../../assets/blank_profile_picture.png';
import '../../css/PfpStyles.css'
import unauthAxiosApiInstance from '../../utils/unauthAxiosConfig';
import { setSnackbarMessage, setSnackbarOpen } from './shared.actions';
import { org } from '../constants/shared.constants';
/**
 * Set the filtered docs in redux store
 * @param {Array} filteredDocs - The filtered list of doctors
 * @returns 
 */
export const setFilteredDocs = (filteredDocs) => ({
    type: SET_FILTERED_DOCS,
    payload: filteredDocs
});

export const setClinics = (clinics) => ({
    type: SET_CLINICS,
    payload: clinics
});

export const setTempDocId = (tempDocId) => ({
    type: SET_TEMP_DOC_ID,
    payload: tempDocId
});

export const setTempDocLoc = (tempDocLoc) => ({
    type: SET_TEMP_DOC_LOC,
    payload: tempDocLoc
});

export const setTempDocReasonsForMatching = (tempDocReasonsForMatching) => ({
    type: SET_TEMP_DOC_REASONS_FOR_MATCHING,
    payload: tempDocReasonsForMatching
});

export const setFavoriteDocIds = (favoriteDocIds) => ({
    type: FAVORITE_DOCTOR_IDS,
    payload: favoriteDocIds
});

/**
 * Set whether or not the page is in a loading state
 * @param {Boolean} loading 
 * @returns 
 */
export const setLoadingDocs = (loading) => ({
    type: SET_LOADING_DOCS,
    payload: loading
})
/**
 * Resets the distance from the patient to the doctor when the value of patient's location changes
 * @returns 
 */
export const resetDoctorDistance = () => ({
    type: RESET_DOCTOR_DISTANCE
});

/**
 * Open up the map marker for the doctor with this id when clicking on the location icon in the doctor's card
 * @param {string} id - The id of the doctor
 * @returns 
 */
export const setDocMarkerFromPersonCard = (id) => ({
        type: SET_DOC_MARKER_FROM_PERSON_CARD,
        payload: id,
})

const pfpStyles = {
    width: "100%",
    height: "100%"
};

const miniPfpStyles = {
    width: "100%",      
    minWidth: "100%",
    height: "100%",
    borderRadius: "0.5rem",
    cursor: "default",
    borderRadius: "0.5rem",
    border: "0.25px solid lightgrey",  
};

export const getPfp = (doc, location) => {
    if (doc && doc.Files && doc.Files.length && doc.Files[0].url)
        return (
            <LazyLoadImage 
                style={location === "minicard" ? miniPfpStyles : pfpStyles}
                src={doc.Files[0].url} 
                alt="doctor" 
                effect='blur'
            />
        )
    if(doc?.avatar)
        return (
            <LazyLoadImage
                style={location === "minicard" ? miniPfpStyles : pfpStyles}
                src={doc.avatar}
                alt="doctor"
                effect='blur'
            />
        )
    return (
        <LazyLoadImage 
            style={location === "minicard" ? miniPfpStyles : pfpStyles}
            src={BlankProfilePicture}
            alt="blank-pfp" 
            effect='blur'
        />
    )
}

/***
 * Loads the doctors based on insurance, specialty, location, and questionnaire responses
 * 
 * @param {string} patientId - The id of the patient
 * @param {object} leftRightBounds - The left and right bounds of the map
 * @param {object} topBottomBounds - The top and bottom bounds of the map
 * @param {object} specialtyPreference - The specialty preference of the user
 * @param {object} insurancePreference - The insurance preference of the user
 * @returns {null} - dispatches filteredDocs to redux store
 */
export const loadDoctors = (patientId, leftRightBounds, topBottomBounds, specialtyPreference, insurancePreference, onMobile, tempDocId, preferredModality) => async (dispatch) => {
    await dispatch({
        type: DOCTORS_LOAD_REQUEST,
    });
    console.log("loading docs")
    console.log({org, patientId, leftRightBounds, topBottomBounds, specialtyPreference, insurancePreference, onMobile, tempDocId, preferredModality})
    await dispatch(setFilteredDocs([])); 
    try {
        const res = await unauthAxiosApiInstance.post(ROOT + `/match-reasons`, {
            org,
            patientId,
            leftRightBounds,
            topBottomBounds,
            specialtyPreference,
            insurancePreference,
            onMobile,
            tempDocId,
            preferredModality
        });
        
        if(res) {   
            const { finalDoctors, existMatchingProviders, msg } = res.data;
            await dispatch({
                type: SET_EXISTS_MATCHING_PROVIDERS,
                payload: existMatchingProviders
            });

            if(msg) {
                await dispatch(setSnackbarMessage(msg));
                await dispatch(setSnackbarOpen(true));
            }

            await dispatch({
                type: DOCTORS_LOAD_SUCCESS,
                payload: {
                    doctors: finalDoctors,
                },
            });
            return finalDoctors;
        } else {
            await dispatch({
                type: DOCTORS_LOAD_FAILURE,
                error: 'Failed to load doctors.',
            });
        }
    } catch(e) {
        console.log("error loading docs: ")
        console.log(e)
        await dispatch({
            type: DOCTORS_LOAD_FAILURE,
            error: 'Failed to load doctors.',
        });
    }
};

/**
 * Loads the doctor by id when the id is in the url params, but was not loaded initially
 * 
 * @param {string} doctorId - The id of the doctor
 */
export const loadDoctorById = (doctorId) => async () => {
    try {
        const res = await axiosApiInstance.get(ROOT + `/doctor-by-id?id=${doctorId}`);
        const doctor = res?.data?.doc;
        return doctor;
    } catch (e) {
        console.log("error loading doc by id: ")
        console.log(e);
    }
};

/**
 * Loads the ids of the favorite doctors in redux store
 * @param {string} org - The organization of the patient
 * @param {boolean} loggedIn - Whether the user is logged in or not
 * @returns 
 */
export const loadFavoriteDoctorIds = (org, loggedIn) => async (dispatch) => {
    await dispatch({
        type: FAVORITE_DOCTORS_LOAD_REQUEST
    })
    try {
        if(!loggedIn) {
            await dispatch({
                type: FAVORITE_DOCTORS_LOAD_SUCCESS,
                payload: {
                    favoriteDoctorIds: [],
                },
            });
            return;
        }
        const favDocIds = await axiosApiInstance.get(ROOT + `/favorite-ids?org=${org}`);
        const favoriteDoctorIds = favDocIds.data.favorites;
        const filteredFavDocIds = favoriteDoctorIds === undefined || (favoriteDoctorIds !== undefined && favoriteDoctorIds.length === 0) ? [] : favoriteDoctorIds
        await dispatch({
            type: FAVORITE_DOCTORS_LOAD_SUCCESS,
            payload: {
                favoriteDoctorIds: filteredFavDocIds,
            },
        });
    } catch (error) {
        await dispatch({
            type: FAVORITE_DOCTORS_LOAD_FAILURE
        });
        console.log(error);
    }
}

/**
 * Updates the favorite doctors for the patient
 * @param {string} doctorId - The id of the doctor
 * @param {string} org - The organization of the patient
 * @param {Array} newFavDocIds - The new array of favorite doctor ids
 * @param {string} type - add or remove from favorites
 * @returns 
 */
export const updateFavoriteDocs = (doctorId, org, newFavDocIds, type) => async (dispatch) => {
    await dispatch(setFavoriteDocIds(newFavDocIds));
    if(type === "add")
        try {
            const data = {
                doctor_id: doctorId
            }
            const res = await axiosApiInstance.post(ROOT + `/favorites?org=${org}`, data)
            if (res.data["OK"] === true) {
                await dispatch({
                    type: FAVORITE_DOCTOR_IDS,
                    payload: {
                        newFavDocIds
                    },
                })
                return true;
            } else {
                return false;
            }
        } catch (e) {
            console.log("error adding doc to favorites: ")
            console.log(e);
            return false;
        }
    else if(type === "remove")
        try {
            const deleteDocRes = await axiosApiInstance.delete(ROOT + `/favorite?org=${org}&id=${doctorId}`)
            if (deleteDocRes.data["OK"] === true) {
                await dispatch({
                    type: FAVORITE_DOCTOR_IDS,
                    payload: {
                        newFavDocIds
                    },
                });
                return true
            } else {
                return false;
            }
        } catch (e) {
            console.log("error deleting doc from favorites: ")
            console.log(e);
            return false;
        }
}

/**
 * Fings the previous doctor review for the currently logged in patient
 * @param {string} org - The organization of the patient
 * @returns 
 */
export const findPreviousDocReview = (org) => async (dispatch) => {
    try {
        const previousDocReview = await axiosApiInstance.get(ROOT + `/previous-appointment?org=${org}`)

        const previousDocReviewData = previousDocReview.data.previousAppointment
        const previousDocReviewDataCount = previousDocReview.data.size
        const previousTraitRatings = previousDocReview.data.previousTraitRatings

        await dispatch({
            type: PREVIOUS_DOC_REVIEW,
            payload: {
                previousDocReviewData,
                previousDocReviewDataCount,
                previousTraitRatings
            },
        })
    } catch (e) {
        console.log("error finding previous doc review: ")
        console.log(e);
    }
}

/**
 * Find the distance and time between the patient and the doctor for all forms of travel
 * @param {Object} userLatLong - The lat long of the patient
 * @param {Object} docLatLong - The lat long of the doctor
 * @param {Number} cardIndex - The index of the doctor's card
 * @returns 
 */
export const findDoctorDistace = (userLatLong, docLatLong, cardIndex, miniCard) => async (dispatch) => {
    try {
        if(Object.keys(userLatLong)?.length === 0 || 
            Object.keys(docLatLong)?.length === 0
        ) return;
        console.warn("USING GOOGLE MAPS API")
        const service = new window.google.maps.DistanceMatrixService();
        const driving = await service.getDistanceMatrix(
            {
                origins: [userLatLong],
                destinations: [docLatLong],
                travelMode: 'DRIVING',
                unitSystem: window.google.maps.UnitSystem.IMPERIAL,
            }
        )

        if(miniCard) {
            return driving?.rows[0]?.elements[0]?.distance?.text;
        }
        
        const publicTransit = await service.getDistanceMatrix(
            {
                origins: [userLatLong],
                destinations: [docLatLong],
                travelMode: 'TRANSIT',
                unitSystem: window.google.maps.UnitSystem.IMPERIAL,
            }
        )
        const bicycling = await service.getDistanceMatrix(
            {
                origins: [userLatLong],
                destinations: [docLatLong],
                travelMode: 'BICYCLING',
                unitSystem: window.google.maps.UnitSystem.IMPERIAL,
            }
        )

        const drivingDistance = driving?.rows[0]?.elements[0]?.distance?.text;
        const drivingTime = driving?.rows[0]?.elements[0]?.duration?.text;
        const publicTransitDistance = publicTransit?.rows[0]?.elements[0]?.distance?.text;
        const publicTransitTime = publicTransit?.rows[0]?.elements[0]?.duration?.text;
        const bicyclingDistance = bicycling?.rows[0]?.elements[0]?.distance?.text;
        const bicyclingTime = bicycling?.rows[0]?.elements[0]?.duration?.text;

        const payload = {
            distance: drivingDistance,
            drivingDistance,
            drivingTime,
            publicTransitDistance,
            publicTransitTime,
            bicyclingDistance,
            bicyclingTime,
            cardIndex
        }
        await dispatch({
            type: SET_DOCTOR_DISTANCE,
            payload,
        })
        return payload;

    } catch (e) {
        console.log("error finding doctor distance: ")
        console.log(e);
    }
}

export const loadClinics = (leftRightBounds, topBottomBounds) => async (dispatch) => {
    try {
        const res = await unauthAxiosApiInstance.get(ROOT + `/clinics?org=${org}&leftRightBounds=${JSON.stringify(leftRightBounds)}&topBottomBounds=${JSON.stringify(topBottomBounds)}`);
        const clinics = res?.data?.clinics;
        await dispatch(setClinics(clinics));
    } catch (e) {
        console.log("error loading clinics: ")
        console.log(e);
    }
}