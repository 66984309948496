export const API_HOST = process.env.NODE_ENV === "production" ? "https://beta-servers.caralyst.io/patient-server" : "http://localhost:3001/patient-server";
export const SET_POPUP_SURVEY_OPEN = "SET_POPUP_SURVEY_OPEN";
export const SET_POPUP_SURVEY_TIMEOUT_SET = "SET_POPUP_SURVEY_TIMEOUT_SET";
export const SET_LOADING_METADATA = "SET_LOADING_METADATA";
export const SET_MAP_LOADING = "SET_MAP_LOADING";
export const SET_SNACKBAR_OPEN = "SET_SNACKBAR_OPEN";
export const SET_SNACKBAR_MESSAGE = "SET_SNACKBAR_MESSAGE";

const findOrg = () => {
    const url = new URL(window.location.href);
    const subdomain = url.hostname.split('.')[0]; // "my"
    const devOrg = subdomain === "localhost" ? "general" : subdomain === "wustl" ? "wustl" : null;
    const prodOrg = subdomain === "my" ? "general" : subdomain === "wustl" ? "wustl" : null;
    const org = process.env.NODE_ENV === "production" ? prodOrg : devOrg;
    // uncomment next line to test wustl org
    // return "wustl"
    return org;
}
export const org = findOrg();

export const COGNITO_MAP = {
    "auth_url": "https://rest.caralyst.io/cognito-service/auth-url",
    "logout_url": "https://rest.caralyst.io/cognito-service/logout-url",
    "refresh": "https://rest.caralyst.io/cognito-service/refresh",
    "revoke": "https://rest.caralyst.io/cognito-service/revoke",
    "s3_upload": "https://rest.caralyst.io/file-service/generate-presigned-url-patient",
    "report_bug": "https://rest.caralyst.io/report-bug",
    "user_info": `https://rest.caralyst.io/cognito-service/${org}-patient/user-info`,
};

export const API_ROOT = "/api"
export const ROOT = API_HOST + API_ROOT;
