import React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import SearchBar from './SearchBar';
import ProfileMenuButton from './ProfileMenuButton';
import caralyst_logo from '../../../assets/caralyst_logo_multi_1.svg';

import { AppBar, useMediaQuery } from '@mui/material';
import { MobileNavbar } from './MobileNavbar';

const Navbar = () => {
    const navigate = useNavigate();
    const media = useMediaQuery('(min-width:600px)');
    const location = window.location.pathname;

    const handleClickLogo = (e) => {
        e.preventDefault();
        navigate('/home');
    }
    
    return (
        <>
            <Box
                sx={{
                display: 'flex',
                alignItems: 'center',
            }}>
                <AppBar 
                    position='fixed'
                    sx={{ 
                        top: 0,
                        zIndex: (theme) => theme.zIndex.drawer + 1 ,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        backgroundColor: "white",
                        minHeight: "4rem",
                        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2)', // Customize the box shadow here
                }}>
                    <Box
                        onClick={(e) => handleClickLogo(e)}
                        sx={{
                            paddingLeft: "0.5rem",
                    }}>
                        <img src={caralyst_logo} style={{maxHeight: "2.5rem"}}/>
                    </Box>
                    {/* Only want this to show if we are on /home or Find doctor page */}
                    {media ? 
                        location.includes("/home") ?
                            <>
                                <SearchBar />
                                <Box sx={{
                                    paddingRight: "1rem",
                                }}>
                                    <ProfileMenuButton />
                                </Box>
                            </>
                            :
                            <Box sx={{
                                paddingRight: "1rem",
                            }}>
                                <ProfileMenuButton />
                            </Box>
                        :
                        location !== "/home" ? 
                            <>
                                <Box sx={{
                                        paddingRight: "1rem",
                                }}>
                                    <ProfileMenuButton />
                                </Box>
                            </>
                            :   
                            <MobileNavbar />
                    }
                </AppBar>
            </Box>
        </>
    );
}

export default Navbar;